import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

const useScrollTop = () => {
  const disableAllScrollTriggers = () => {
    ScrollTrigger.getAll().forEach((item) => item.disable())
  }
  const enableAllScrollTriggers = () => {
    ScrollTrigger.getAll().forEach((item) => item.enable())
  }

  const scrollToTop = () => {
    disableAllScrollTriggers()
    gsap.to(window, { scrollTo: 0, duration: 1, delay: 0 })
    enableAllScrollTriggers()
  }

  return scrollToTop
}

export default useScrollTop
