import React, { ReactNode, useState } from 'react'
import stage1 from 'assets/filtration/engineering/1.png'
import stage2 from 'assets/filtration/engineering/2.png'
import stage3 from 'assets/filtration/engineering/3.png'
import stage4 from 'assets/filtration/engineering/4.png'
import stage5 from 'assets/filtration/engineering/5.png'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css/navigation'

export default function Engineering() {
  const [current, setCurrent] = useState(0)

  return (
    <section>
      <div className="grid grid-cols-12 blade-top-padding h-full relative bg-gradient-to-r from-white to-transparent">
        <div className="md:col-start-2 px-4 md:px-0 col-start-1 lg:col-end-10 col-end-12 blade-top-padding-sm">
          <h2 className="sg-translate font-light text-black">
            Products manufactured with <br className="md:block hidden" />
            <span className="text-black font-semibold">
              performance-driven
            </span>{' '}
            engineering
          </h2>

          <h4 className="sg-translate max-w-2xl 2xl:text-[22px] lg:mt-5 mt-3">
            We excel in the craft of engineering performance through our
            vertically integrated manufacturing approach. Our state-of-the-art
            facilities seamlessly oversee every stage of production, leaving no
            room for errors.
          </h4>
        </div>
      </div>

      <div className="md:mt-5 lg:mt-0 blade-bottom-padding-xl">
        <Swiper
          centeredSlides
          slidesPerView={1.1}
          loop={false}
          navigation={{
            prevEl: '.life-carousel-prev-btn',
            nextEl: '.life-carousel-next-btn',
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            1024: {
              centeredSlides: false,
              slidesPerView: 2.5,
            },
            1280: {
              slidesPerGroup: 3.8,
              centeredSlides: false,
              slidesPerView: 3,
            },
            1440: {
              centeredSlides: false,
              slidesPerView: 3.8,
            },
          }}
          spaceBetween={4}
          className="cursor-grab md:pl-[8%] pl-[0%]"
          modules={[Navigation]}
        >
          {slides.map((elem, index: number) => {
            const { cover, title, desc } = elem
            const key = `${index}`
            return (
              <SwiperSlide key={key} className="py-4">
                <Slide title={title} desc={desc} cover={cover} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className="w-container hidden md:flex justify-end gap-5 lg:pl-5 md:pl-0 md:mb-6 mt-3">
          <SliderBtn
            slideClass={
              window.innerWidth >= 768 ? 'life-carousel-prev-btn' : ''
            }
            isDisabled={current === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
          <SliderBtn
            isDisabled={current === slides.length - 1}
            slideClass={
              window.innerWidth >= 768 ? 'life-carousel-next-btn' : ''
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
        </div>
        <div className="w-container md:hidden flex justify-end gap-5 lg:pl-5 md:pl-0 md:mb-6 mt-3">
          <SliderBtn
            slideClass="life-carousel-prev-btn"
            isDisabled={current === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
          <SliderBtn
            isDisabled={current === slides.length - 1}
            slideClass="life-carousel-next-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
        </div>
      </div>
    </section>
  )
}

function SliderBtn({
  slideClass,
  isDisabled,
  children,
}: {
  slideClass: string
  isDisabled: boolean
  children: ReactNode
}) {
  return (
    <button
      type="button"
      className={`${slideClass} place-items-center rounded-full grid place-content-center h-10 lg:h-12 aspect-square  
      transition-all disabled:opacity-20 duration-500 ease-in-out bg-blue focus:outline-blueDark outline-1 outline-sollid 
      outline-transparent`}
    >
      <span className="sg-translate">{children}</span>
    </button>
  )
}

function Slide({
  title,
  cover,
  desc,
}: {
  title: string
  desc: string
  cover: string
}) {
  return (
    <article className="group overflow-hidden w-full ">
      <div className="relative">
        <div className="h-24 w-24 mb-">
          <img
            className="h-full w-full object-contain -ml-1.5"
            src={cover}
            alt={title}
          />
        </div>

        <div className="w-full mt-4 md:mt-6 block border-t-[2px] border-dashed border-blue" />

        <div
          className="relative max-w-sm inset-0 flex gap-2 flex-col justify-end pr-10 pt-4 md:pt-6 transition-all 
        duration-500 ease-in-out "
        >
          <h3 className="sg-translate max-sm:text-[1.25rem] 2xl:text-[30px] font-medium">
            {title}
          </h3>
          <h5 className="sg-translate">{desc}</h5>
        </div>
      </div>
    </article>
  )
}
const slides = [
  {
    title: 'Stage 1',
    desc: 'Analyzing the current condition of the bag house and choosing the staple fiber accordingly',
    cover: stage1,
  },
  {
    title: 'Stage 2',
    desc: 'Engineering customised nonwoven based on bag house performance requirements',
    cover: stage2,
  },
  {
    title: 'Stage 3',
    desc: 'High-speed, state-of-the-art productions',
    cover: stage3,
  },
  {
    title: 'Stage 4',
    desc: 'Meticulous filter finishing to achieve desired capabilities',
    cover: stage4,
  },
  {
    title: 'Stage 5',
    desc: 'Filter media testing to meet global quality standards',
    cover: stage5,
  },
]
