import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  useMemo,
} from 'react'

type Language = string // Example: 'en' | 'de' | 'ja' | 'th';

export type PageData = {
  [key: string]: {
    [key: string]: string[] | any[]
  }
}

type initPageDataType = {
  en: string[]
  th: string[]
  ja: string[]
  de: string[]
  fr: string[]
  es: string[]
  'zh-Hans': string[]
}

export type DropDownText = {
  [key: string]: {
    [key: string]: string
  }
}

const intialPageData: initPageDataType = {
  en: [],
  th: [],
  ja: [],
  de: [],
  fr: [],
  es: [],
  'zh-Hans': [],
}

const englishDropdownText = {
  apparel: 'Apparel',
  automotive: 'Automotive',
  filtration: 'Filtration',
  customisedNonwoven: 'Customised Nonwoven',
  whoWeAre: 'Who We Are',
  globalCompetency: 'Global Competency',
}

const initialDropdownData = {
  apparel: '',
  automotive: '',
  filtration: '',
  customisedNonwoven: '',
  whoWeAre: '',
  globalCompetency: '',
}

type LanguageContextType = {
  language: Language
  setLanguage: React.Dispatch<React.SetStateAction<Language>>
  pageData: PageData
  setPageData: React.Dispatch<React.SetStateAction<PageData>>
  dropdownText: DropDownText
  setDropdownText: React.Dispatch<React.SetStateAction<DropDownText>>
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined,
)

export function LanguageProvider({ children }: { children: ReactNode }) {
  const [language, setLanguage] = useState<Language>('en')
  const [pageData, setPageData] = useState<PageData>({
    home: intialPageData,
    apparel: intialPageData,
    careers: intialPageData,
    'who-we-are': intialPageData,
    'global-competency': intialPageData,
    filtration: intialPageData,
    'customised-nonwoven': intialPageData,
    'contact-us': intialPageData,
    innovation: intialPageData,
    'esg-impact': intialPageData,
    // sitemap: intialPageData,
    automotive: intialPageData,
  })
  const [dropdownText, setDropdownText] = useState<DropDownText>({
    en: englishDropdownText,
    th: initialDropdownData,
    ja: initialDropdownData,
    de: initialDropdownData,
    fr: initialDropdownData,
    es: initialDropdownData,
    'zh-Hans': initialDropdownData,
  })

  const value = useMemo(
    () => ({
      language,
      setLanguage,
      pageData,
      setPageData,
      dropdownText,
      setDropdownText,
    }),
    [language, pageData, dropdownText],
  )

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}
