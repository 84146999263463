import React, { useEffect, useState } from 'react'
import gsap, { Power1 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './style.css'
import { CSSTransition } from 'react-transition-group'
import { clientLogos } from 'static/clients-logos'

gsap.registerPlugin(ScrollTrigger)

const width = window.innerWidth

export default function Clients() {
  const [activeIndex, setActiveIndex] = useState(0)
  const len = Object.keys(clientLogos).length

  const handleSectionNavigation = (sectionId: string) => {
    const ele = document.querySelector(`#${sectionId}`)
    ele?.scrollIntoView()
  }

  const handleScroll = () => {
    const sec = document.querySelectorAll('.section-wrapper')

    sec.forEach((item, ind) => {
      const tabWrapper = document.querySelector('.tabs-wrapper')
      const rect = item?.getBoundingClientRect()
      const tabWrapperHeight = tabWrapper?.clientHeight

      if (rect && tabWrapperHeight) {
        const isInView = rect.top >= -1 && rect.top <= window.innerHeight / 5

        if (isInView) {
          setActiveIndex(ind)
        }
      }
    })
  }

  useEffect(() => {
    let interval: any
    if (width >= 768) {
      interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % len)
      }, 5000)
    } else {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
      window.removeEventListener('scroll', handleScroll)
    }
  }, [activeIndex])

  return (
    <section>
      <section className="lg:block hidden client-wrp bg-blueDark h-screen 2xl:min-h-[55rem] relative overflow-hidden">
        <div className="w-container h-full flex flex-col z-10 relative justify-center items-center">
          <h2 className="sg-translate text-white font-light text-center leading-snug">
            We’re <span className="font-bold ">T1-rated</span> suppliers{' '}
            <br className="hidden md:block" />
            across industries
          </h2>

          <div className="flex gap-6 mt-6">
            {clientLogos.map((item, index) => {
              return (
                <button
                  key={item.id}
                  className={`${
                    activeIndex === index ? '' : 'opacity-50 hover:opacity-70'
                  } text-white 2xl:text-xl text-lg transition-all ease-in-out`}
                  type="button"
                  onClick={() => setActiveIndex(index)}
                >
                  <span className="sg-translate">{item.title}</span>
                </button>
              )
            })}
          </div>
        </div>
        <div className="">
          {clientLogos.map((item, index) => {
            const keys = `vals-${index}`
            return (
              <CSSTransition
                in={activeIndex === index}
                timeout={2500}
                unmountOnExit
                onEnter={(node: any) =>
                  gsap.fromTo(
                    node,
                    { scale: 0 },
                    { scale: 1, duration: 1, ease: Power1.easeInOut },
                  )
                }
                onExiting={(node: any) =>
                  gsap.to(node, {
                    scale: 3,
                    duration: 1,
                    ease: Power1.easeInOut,
                  })
                }
                key={keys}
              >
                <div className="clients-icons-wrapper absolute inset-0">
                  {item.icons.map((el) => {
                    return (
                      <div key={el.id} className="text-white text-center">
                        <img
                          src={el.logo}
                          alt={el.alt}
                          className="w-full h-full object-contain"
                        />
                      </div>
                    )
                  })}
                </div>
              </CSSTransition>
            )
          })}
        </div>
      </section>

      {/* Mobile View */}
      <section className="relative lg:hidden block bg-blueDark blade-top-padding-xl blade-bottom-padding-xl px-4">
        <h2 className="sg-translate text-white font-light text-center leading-snug">
          We’re <span className="font-bold ">T1-rated</span> suppliers across
          industries
        </h2>

        {clientLogos.map((item) => (
          <div className="pt-12 section-wrapper" key={item.id} id={item.id}>
            <h4 className="sg-translate text-white text-center text-lg">
              {item.title}
            </h4>
            <div
              className={`grid mt-4 border rounded-md border-white grid-cols-2 sm:grid-cols-5 gap-x-2 gap-y-2 p-2
              `}
            >
              {item.icons.map((icon) => (
                <div
                  key={icon.id}
                  className="text-white flex items-center justify-center rounded-md w-auto h-full sm:w-[120px] bg-white text-center"
                >
                  <img
                    src={icon.logo}
                    alt={icon.alt}
                    className="w-full h-auto"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="mt-3 lg:hidden flex py-3 bg-blueDark px-4 tabs-wrapper justify-center bottom-0 sticky flex-wrap gap-2 gap-x-4 ">
          {clientLogos.map((item, index) => {
            const keyVal = `tabs-${index}`
            return (
              <button
                key={keyVal}
                className={`${
                  activeIndex === index
                    ? 'underline underline-offset-4'
                    : 'opacity-70 hover:opacity-70'
                } text-white text-sm font-semibold`}
                type="button"
                onClick={() => handleSectionNavigation(item.id)}
              >
                <span className="sg-translate">{item.title}</span>
              </button>
            )
          })}
        </div>
      </section>
    </section>
  )
}
