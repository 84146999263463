import React, { useState, useRef, useMemo } from 'react'

import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import './stylesOrganism.css'
import { SliderBtn } from 'screens/filtration/applicationExpertise'

type Props = {
  data: { coverImage: string; title: string; description: string }[]
  height: string
}

export default function Carousal({ data, height }: Props) {
  const [current, setCurrent] = useState(0)
  const slideDesc = useRef<any>()

  const lastSlide = useMemo(() => data.length - 1, [data])

  return (
    <>
      <div className="2xl:pt-10 pt-5 relative">
        <div className="absolute inset-0 max-w-screen-xlg mx-auto w-10/12 md:w-9/12 2xl:w-10/12  flex items-center gap-10 justify-between">
          <button
            disabled={current === 0}
            type="button"
            className="md:block z-10 hidden possibility-prev-btn  opacity-100 disabled:opacity-0 disabled:pointer-events-none aspect-square border-1 border-white border-solid rounded-full hover:bg-white hover:text-black transition-all duration-200 ease-in-out focus-visible:bg-white focus-visible:text-black focus-visible:outline-white outline-offset-2 outline outline-transparent text-white font-semibold text-sm md:h-14 lg:h-16 xl:h-20 2xl:h-24 "
          >
            <span className="sg-translate">Prev</span>
          </button>
          <button
            disabled={current === lastSlide}
            type="button"
            className="md:block z-10 hidden disabled:hidden possibility-next-btn opacity-100  disabled:pointer-events-none aspect-square border-1 border-white border-solid rounded-full hover:bg-white hover:text-black transition-all duration-200 ease-in-out focus-visible:bg-white focus-visible:text-black focus-visible:outline-white outline-offset-2 outline outline-transparent text-white font-semibold 2xl:text-sm md:h-14 lg:h-16 xl:h-20 2xl:h-24 "
          >
            <span className="sg-translate">Next</span>
          </button>
        </div>
        <div className="">
          {/* swiper is not draggable.confirm that whether it is to be draggable or not. */}
          <Swiper
            modules={[Navigation]}
            navigation={{
              prevEl: '.possibility-prev-btn',
              nextEl: '.possibility-next-btn',
            }}
            slidesPerView={1.1}
            centeredSlides
            spaceBetween={10}
            loop={false}
            speed={1000}
            className="bg-white cursor-grab"
            breakpoints={{
              768: {
                spaceBetween: 20,
                slidesPerView: 1.2,
              },
              1024: {
                spaceBetween: 30,
                slidesPerView: 2,
              },
              1280: {
                spaceBetween: 40,
                slidesPerView: 2.1,
              },

              1440: {
                spaceBetween: 50,
                slidesPerView: 1.9,
              },
              1536: {
                spaceBetween: 60,
                slidesPerView: 2,
              },
              1700: {
                slidesPerView: 2.1,
              },
            }}
            onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
          >
            {data.map((elem: any, index: number) => {
              const key = `${index}`
              return (
                <SwiperSlide
                  key={key}
                  className="h-48 md:h-64 lg:h-80 xl:h-[17rem] 2xl:h-[31rem]  rounded-2xl overflow-hidden"
                >
                  <div className="h-full">
                    <img
                      src={elem.coverImage}
                      alt={elem.title}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>

      <div>
        <SwitchTransition>
          <CSSTransition
            key={current}
            nodeRef={slideDesc}
            timeout={650}
            classNames="slideDescWrapper"
          >
            <div
              ref={slideDesc}
              className={`w-full md:w-[83%] lg:w-[50%] xl:w-[46%] 1440:w-[51%] 2xl:w-[46%] mx-auto 2xl:py-5 py-3 ${height}`}
            >
              <h4 className="sg-translate block leading-tight 2xl:leading-tight font-semibold pt-1 pb-3 lg:pb-1 md:px-0 px-[4.5%] lg:px-2 xl:px-0 lg:w-full 2xl:w-[94%] md:ml-0">
                {data[current].title}
              </h4>
              <h5 className="sg-translate block leading-snug 2xl:leading-snug max-w-xl 2xl:max-w-4xl 2xl:min-h-[90px] min-h-[60px] px-[4.5%] lg:px-2 xl:px-0 md:px-0 lg:w-full 2xl:w-[94%] lg:mt-2 md:ml-0">
                {data[current].description}
              </h5>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <div
        className="flex md:hidden z-10 justify-end w-container-sm 
                gap-5 pb-5 pl-5 md:pl-0 md:mb-6"
      >
        <SliderBtn
          disable={current === 0}
          direction="left"
          slideClass="possibility-prev-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
              fill="white"
            />
          </svg>
        </SliderBtn>
        <SliderBtn
          disable={current === lastSlide}
          direction="right"
          slideClass="possibility-next-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
              fill="white"
            />
          </svg>
        </SliderBtn>
      </div>
    </>
  )
}
