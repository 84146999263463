import React from 'react'
import bg from 'assets/sustainability/social-responsibility-bg.jpg'

export function SocialResponsibility() {
  return (
    <div className="relative">
      <div className="w-full h-full">
        <img src={bg} alt="Team" />
      </div>
      <div className="absolute hidden xl:block top-0 w-full h-full bg-black opacity-80" />

      <div className="xl:absolute top-0 flex items-center w-full h-full">
        <div
          className="grid lg:grid-cols-2 w-container pt-6 lg:pt-12 blade-bottom-padding-xl 
        xl:blade-top-padding xl:blade-bottom-padding"
        >
          <div className="">
            <p className="sg-translate font-normal text-green text-lg md:text-xl xl:text-[22px] 2xl:text-2xl">
              Social responsibility
            </p>
            <h2 className="sg-translate font-normal text-black xl:text-white">
              Empowering communities <br className="hidden lg:block" /> and
              workforce
            </h2>
          </div>

          <div className="flex flex-col gap-y-4 xl:gap-y-8 2xl:gap-y-12 pt-6 lg:pt-8">
            <p className="sg-translate font-light text-base md:text-lg lg:text-xl 2xl:text-[22px] max-w-xl  text-black xl:text-white">
              <span className="font-semibold">Inclusion and diversity:</span> We
              are committed to make our workforce mirror the diverse world we
              live in, with policies and programs fostering an inclusive culture
              that celebrates every individual’s unique contribution.
            </p>
            <p className="sg-translate font-light text-base md:text-lg lg:text-xl 2xl:text-[22px] max-w-xl text-black xl:text-white">
              <span className="font-semibold">
                Employee well-being and development:
              </span>{' '}
              Supreme Nonwovens is dedicated to the holistic well-being of our
              team, ensuring safe, healthy working environments alongside
              abundant opportunities for personal and professional growth.
            </p>
            <p className="sg-translate font-light text-base md:text-lg lg:text-xl 2xl:text-[22px] max-w-xl text-black xl:text-white">
              <span className="font-semibold">Community engagement:</span> Our
              roots run deep within local communities, where we strive to make a
              positive impact through education, volunteering, and sustainable
              development projects.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
