import React from 'react'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

const slides = [
  'AI-enabled production',
  'Manufacturing mechanization',
  'Automation ecosystem',
  'Digitalization',
  'Industry 4.0',
  'Sustainability',
  'Material innovation',
]

export default function SubFooterMobile() {
  return (
    <div className="bg-blueShade">
      <div className="ring-wrapper grid place-content-center h-96">
        <h2 className="sg-translate font-semibold text-black  text-center leading-snug">
          We welcome <br />
          <span className="text-blue"> unique minds </span>
          <br />
          like yours
        </h2>
      </div>

      <div className="blade-top-padding-sm blade-bottom-padding">
        <span className="sg-translate block leading-snug text-lg w-10/12 mx-auto text-center max-w-sm font-medium text-black">
          Diversity of perspectives breeds fresh ideas. Be yourself, showcase
          what sets you apart, and join us in our pursuit of innovation
        </span>
      </div>
      <div className="blade-top-padding-sm blade-bottom-padding">
        <Swiper
          centeredSlides
          pagination
          loop
          slidesPerView={2}
          spaceBetween={10}
          autoplay={{
            delay: 2000,
          }}
          speed={1200}
          modules={[Pagination, Autoplay]}
        >
          {slides.map((elem, index: number) => {
            const key = `${index}`
            return (
              <SwiperSlide key={key} className="pt-2 pb-12 ">
                <Slide title={elem} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>

      <div className="blade-bottom-padding">
        <div className="ring-wrapper-linkedin grid place-content-center gap-5 place-items-center h-96">
          <span className="sg-translate text-black font-semibold block  mx-auto text-center">
            Curious to learn more <br /> about life at Supreme?
          </span>
          <a
            href="https://www.linkedin.com/company/supreme-group-company/"
            target="_blank"
            rel="noreferrer noopener"
            className="rounded-full relative border-2 border-solid border-[#00AEEF] py-2 px-4 flex items-center gap-3 justify-center font-semibold text-base"
          >
            <span className="sg-translate">Let&apos;s talk</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={17}
              height={17}
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0943 0.689941H1.95789C1.32955 0.689941 0.795898 1.18689 0.795898 1.79908V14.995C0.795898 15.6079 1.14604 16.1049 1.77438 16.1049H14.9108C15.5399 16.1049 16.2108 15.6079 16.2108 14.995V1.79908C16.2108 1.18689 15.7234 0.689941 15.0943 0.689941ZM6.66825 6.5623H8.7434V7.62005H8.76615C9.08253 7.0497 10.017 6.47054 11.1723 6.47054C13.3899 6.47054 14.0087 7.64795 14.0087 9.82879V13.9027H11.8066V10.2303C11.8066 9.25404 11.4168 8.39741 10.5051 8.39741C9.39816 8.39741 8.87039 9.14686 8.87039 10.3771V13.9027H6.66825V6.5623ZM2.99803 13.9027H5.20016V6.5623H2.99803V13.9027ZM5.47543 3.99314C5.47543 4.75361 4.85957 5.36947 4.0991 5.36947C3.33863 5.36947 2.72276 4.75361 2.72276 3.99314C2.72276 3.23267 3.33863 2.61681 4.0991 2.61681C4.85957 2.61681 5.47543 3.23267 5.47543 3.99314Z"
                fill="#00AEEF"
              />
            </svg>
          </a>
          <span className="text-black text-sm font-light block leading-snug text-center pt-1">
            <span className="sg-translate">
              Want to explore opportunities? <br /> Write to us at{' '}
            </span>
            <a href="mailto:hr@supremegroup.co.in" className="font-semibold">
              <span className="sg-translate">hr@supremegroup.co.in</span>
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

function Slide({ title }: { title: string }) {
  return (
    <article className="2xl:rounded-[20px] rounded-xl lg:rounded-2xl overflow-hidden">
      <div className="relative h-36 sm:h-48 md:h-56 ">
        <div className="grid place-content-center mx-auto  aspect-square text-center px-2 bg-blueDark rounded-full h-full">
          <span className="font-semibold text-white pb-1 text-base 2xl:text-2xl leading-tight">
            {title}
          </span>
        </div>
      </div>
    </article>
  )
}
