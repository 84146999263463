import React from 'react'
import bg_image from 'assets/filtration/bg-contactUs.jpg'
import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'

export default function ContactUs() {
  const navigate = useNavigate()
  const goToSource = () => {
    navigate('/contact-us')
  }
  return (
    <div
      className=" 2xl:py-28 xl:py-20 py-16"
      style={{
        background: `linear-gradient(to bottom , rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${bg_image})`,
      }}
    >
      <div className="2xl:max-w-2xl xl:max-w-xl max-w-md mx-auto px-4">
        <h1 className="sg-translate text-center text-white font-light ">
          We have a <span className="font-bold">solution</span> for every
          application – Just tell us what you need!
        </h1>
        <div className="flex xl:mt-10 mt-6 justify-center">
          <HeroBtn
            size="small"
            type="button"
            color="blueLight"
            onClick={goToSource}
            text="Contact us"
          />
        </div>
      </div>
    </div>
  )
}
