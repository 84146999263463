import React from 'react'

const sizeMap = {
  small: 'text-sm px-6 py-3 xl:text-base',
  base: 'lg:text-base text-sm px-7 lg:px-9 py-3',
  large: '2xl:text-lg lg:text-base text-sm px-5 lg:px-8 lg:py-4 py-3',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}
const colorMap = {
  transparent:
    'bg-transparent text-dark stroke-dark group-hover:stroke-white group-hover:text-white group-hover:border-transparent border border-black',
  transparentWhite:
    'bg-transparent text-white stroke-dark group-hover:stroke-white group-hover:text-dark group-hover:bg-white group-hover:border-transparent border border-white',
  blue: 'bg-blue stroke-white border-1 border-blueLight',
  blueLight:
    'bg-blueLight stroke-white border-1 border-blue group-hover:bg-blue !font-semibold',
}

type MasterBtnProps<T extends 'submit' | 'button'> = {
  text: string
  size: keyof typeof sizeMap
  color?: keyof typeof colorMap
  type: T
  isDisabled?: boolean
  isTranslate?: boolean
  role?: 'button' | 'link'
  onClick?: T extends 'submit' ? never : () => void
}

const spaceMap = {
  small: 'w-11 h-11',
  base: 'w-14 h-14',
}
export function HeroBtn<T extends 'submit' | 'button'>({
  type,
  role = 'button',
  text,
  onClick,
  size,
  isDisabled,
  isTranslate,
  color = 'blue',
  ...rest
}: MasterBtnProps<T>) {
  let space: 'base' | 'small' = 'base'
  let isSmall = true
  if (size === 'base' || size === 'small') {
    space = size
  }
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  const spaceClass: string = spaceMap[space]
  if (size === 'base') isSmall = false

  return (
    <button
      role={role}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
      type={type === 'button' ? 'button' : 'submit'}
      className="flex gap-1 group relative outline-none"
    >
      <div
        className={` group-focus:!bg-opacity-80 z-10 h-full ${
          isSmall ? '' : ''
        } whitespace-nowrap transition-all duration-700 ease-in-out 
        ${sizeClass}  ${colorClass} rounded-full group-hover:border-transparent font-semibold tracking-wide leading-snug`}
      >
        <span className={`${!isTranslate && ' sg-translate '}`}>{text}</span>
      </div>
    </button>
  )
}
