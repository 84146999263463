import React, { useLayoutEffect } from 'react'
import { Outlet } from 'react-router-dom'
import {
  PageHome,
  PageError404,
  PageContact,
  PageCareers,
  PageGlobalCompetency,
  PageApparel,
  PageSiteMap,
  PageWhoWeAre,
  PageInnovation,
  PageNonwoven,
  PageFiltration,
  PageAutomotive,
  Sustainability,
} from 'screens'
import { Header, Footer } from 'organisms'
import LangConverter from 'atoms/langConverter'

function ViewWithHeaderAndFooter() {
  // This is used to prevent unneccessary language fetch- Do not remove this
  useLayoutEffect(() => {
    localStorage.setItem('sg-lang-changed', '')
  }, [])

  return (
    <div className="bg-white">
      <LangConverter />
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default function routes() {
  return [
    {
      path: '/',
      element: <ViewWithHeaderAndFooter />,
      children: [
        {
          path: '/',
          element: <PageHome />,
        },
        {
          path: '/careers',
          element: <PageCareers />,
        },
        {
          path: '/who-we-are',
          element: <PageWhoWeAre />,
        },
        {
          path: '/global-competency',
          element: <PageGlobalCompetency />,
        },
        {
          path: '/applications/apparel',
          element: <PageApparel />,
        },
        {
          path: '/applications/filtration',
          element: <PageFiltration />,
        },
        {
          path: '/applications/customised-nonwoven',
          element: <PageNonwoven />,
        },
        {
          path: '/contact-us',
          element: <PageContact />,
        },
        {
          path: '/innovation',
          element: <PageInnovation />,
        },
        {
          path: '/esg-impact',
          element: <Sustainability />,
        },
        {
          path: '/sitemap',
          element: <PageSiteMap />,
        },
        {
          path: '/applications/automotive',
          element: <PageAutomotive />,
        },
        {
          path: '*',
          element: <PageError404 />,
        },
      ],
    },
  ]
}
