import { HeroBtn } from 'atoms/buttons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type ISubfooter = {
  title: string
  className: string
  btn: {
    text: string
    target: string
    size?: 'base' | 'small' | 'large' | 'xl' | 'xxl' | '3xl'
  }
}

export default function SubFooter({ title, className, btn }: ISubfooter) {
  const { text, target, size = 'base' } = btn
  const navigate = useNavigate()
  return (
    <section
      className={` h-72 lg:h-80 xl:h-[28rem] xlg:h-[30rem] 2xl:h-[34rem] grid place-content-center text-center place-items-center gap-5 xlg:gap-6  xl:gap-7 2xl:gap-8 bg-cover ${className}`}
    >
      <h2
        dangerouslySetInnerHTML={{ __html: title }}
        className="sg-translate font-light leading-snug text-white "
      />
      <HeroBtn
        size={size}
        text={text}
        color="blueLight"
        onClick={() => navigate(target)}
        type="button"
      />
    </section>
  )
}
