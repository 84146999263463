import React, { DispatchWithoutAction } from 'react'
import { useNavigate } from 'react-router-dom'
// import logomark from 'assets/globals/logo.svg'
import logo from 'assets/globals/logo.svg'
import { Anchor, ExternalAnchor, TextAnchor } from 'atoms/links'
import DropdownMobileView from 'atoms/dropdownMobileView'
import DropdownLang from 'atoms/dropdownLang'
import { langDropdownOptions } from './header'

export default function MobileNavModal({
  toggle,
  data,
}: {
  toggle: DispatchWithoutAction
  data: any
}) {
  const navigate = useNavigate()
  const navigateTo = (target: string) => {
    navigate(target)
    toggle()
  }

  return (
    /* eslint-disable */
    <nav className="bg-white h-[100dvh]">
      <section className="w-container min-h-full text-dark overflow-auto flex flex-col flex-nowrap z-[999]">
        <div
          className="flex-0 flex border-white border-opacity-10 border-b-2 flex-nowrap gap-5 items-center justify-between 
        py-4"
        >
          <Anchor href="/">
            <img
              src={logo}
              className="h-full !cursor-pointer"
              alt="Logo of supreme with text"
              loading="eager"
              onClick={toggle}
            />
          </Anchor>

          <button
            type="button"
            onClick={toggle}
            className="rounded-full stroke-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              className="stroke-blueDark opacity-80 w-7 h-7"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col flex-1 pt-4">
          <ul className="list-none grid gap-3">
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="dark"
                text="Home"
                href="/"
                className="block py-2"
              />
            </li>

            <DropdownMobileView
              title="Application"
              options={data[0]}
              index={0}
              toggle={toggle}
            />

            <DropdownMobileView
              title="Company"
              options={data[1]}
              index={1}
              toggle={toggle}
            />

            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="dark"
                text="Innovation"
                href="/innovation"
                className="block  py-2"
              />
            </li>
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="dark"
                text="ESG Impact"
                href="/esg-impact"
                className="block  py-2"
              />
            </li>
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="dark"
                text="Careers"
                href="/careers"
                className="block  py-2"
              />
            </li>
          </ul>
        </div>
        <div className="mt-auto pt-10 pb-2 grid grid-cols-2 gap-4">
          {/* <DropdownLang
            title=""
            options={langDropdownOptions}
            isMobile={true}
            toggleHandler={toggle}
          /> */}
          <ExternalAnchor
            className="bg-blue text-white flex justify-center gap-3 items-center !text-lg font-normal pt-2 pb-2 px-3 rounded-full w-full hover:bg-opacity-90"
            href="https://www.linkedin.com/company/supreme-group-company/"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mb-[1.5px]"
              viewBox="0 0 24 24"
            >
              <path
                d="M4.98304 7.19704C6.19144 7.19704 7.17104 6.21744 7.17104 5.00904C7.17104 3.80065 6.19144 2.82104 4.98304 2.82104C3.77464 2.82104 2.79504 3.80065 2.79504 5.00904C2.79504 6.21744 3.77464 7.19704 4.98304 7.19704Z"
                fill="white"
              />
              <path
                d="M9.23697 8.85493V20.9939H13.006V14.9909C13.006 13.4069 13.304 11.8729 15.268 11.8729C17.205 11.8729 17.229 13.6839 17.229 15.0909V20.9949H21V14.3379C21 11.0679 20.296 8.55493 16.474 8.55493C14.639 8.55493 13.409 9.56193 12.906 10.5149H12.855V8.85493H9.23697ZM3.09497 8.85493H6.86997V20.9939H3.09497V8.85493Z"
                fill="white"
              />
            </svg>
            <span className="!text-white">LinkedIn</span>
          </ExternalAnchor>
          <button
            role="link"
            type="button"
            onClick={() => navigateTo('/contact-us')}
            className=" bg-blue text-white col-span-1 text-lg h-full font-normal mb-3 pt-2 pb-2 px-3 rounded-full grid  place-content-center  w-full hover:bg-opacity-90 "
          >
            Contact us
          </button>
        </div>
      </section>
    </nav>
  )
}
