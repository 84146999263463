import React, { ReactNode, useState } from 'react'
import mentor from 'assets/who-we-are/mentor.webp'
import amit_kavrie from 'assets/who-we-are/amit_kavrie.webp'

interface FounderStoryState {
  mohan: boolean
  sohan: boolean
}

export default function Founders() {
  const [founderStory, setFoundersStory] = useState<FounderStoryState>({
    mohan: false,
    sohan: false,
  })
  const handleClick = (founderName: keyof FounderStoryState) => {
    setFoundersStory({
      ...founderStory,
      [founderName]: !founderStory[founderName],
    })
    // console.log('el')
  }
  return (
    <section className="sm:w-container-lg xl:w-container min-h-screen blade-top-padding-sm xl:blade-bottom-padding-lg">
      <div className="grid md:place-content-center md:place-items-center md:text-center blade-bottom-padding-sm blade-top-padding">
        <h2 className="sg-translate font-light leading-tight md:block px-3">
          Meet the visionaries <br /> behind{' '}
          <strong className="font-semibold">Team Supreme</strong>
        </h2>
      </div>
      <Founder
        title="Mohan Kavrie"
        subtitle="Founder & Chairman"
        linkedinProfile="https://www.linkedin.com/in/mohan-kavrie-06b59425/"
        profilePic={mentor}
      >
        <h5 className="font-medium leading-relaxed">
          <span className="sg-translate ">
            Mohan Kavrie, a visionary leader and the founder of Supreme
            Nonwovens Group, brings a wealth of expertise and experience to the
            company. He is an alumnus of the prestigious IIT Mumbai and holds an
            M.S. in Operations Research from the USA. As the driving force
            behind Supreme Nonwoven Industries Pvt Ltd, India&apos;s largest and
            most diversified Nonwovens Company, Mohan Kavrie has played a
            pivotal role in the organization&apos;s growth and success.
          </span>
          <span className="lg:hidden">
            {founderStory.mohan ? (
              ''
            ) : (
              <>
                ..
                <button
                  onClick={() => handleClick('mohan')}
                  type="button"
                  className="text-blueDark mt-1"
                >
                  Read More
                </button>
              </>
            )}
          </span>
        </h5>
        <div
          className={`${
            founderStory.mohan ? 'h-fit' : 'h-0 lg:h-fit'
          } overflow-hidden`}
        >
          <h5 className="sg-translate font-normal leading-relaxed pb-4 pt-4 ">
            Over the past two decades, Mohan Kavrie has actively participated in
            numerous initiatives of the Ministry of Textiles and served as a
            member of various Government and Industry Association Committees,
            dedicated to advancing the awareness and development of Technical
            Textiles in India. His contribution also extends as member of
            Governing Council of several research and educational institutions.
          </h5>
          <h5 className="sg-translate font-normal leading-relaxed ">
            Recognized for his outstanding contributions to the field of
            Manufacturing, Mohan Kavrie has received the Distinguished Alumnus
            Award from IIT Mumbai. He is also the proud recipient of the
            Lifetime Achievement Award from the Indian Textile Association,
            acknowledging his profound dedication and service to the Textile
            Industry, particularly within the realm of Technical Textiles.
          </h5>
        </div>
        <span>
          {founderStory.mohan ? (
            <button
              onClick={() => handleClick('mohan')}
              type="button"
              className="text-blueDark text-sm xlg:text-xl"
            >
              Read Less
            </button>
          ) : (
            ''
          )}
        </span>
      </Founder>
      <div className="sm:blade-top-padding 2xl:blade-top-padding-lg">
        <Founder
          title="Amit Mohan Kavrie"
          subtitle="Managing Director"
          linkedinProfile="https://www.linkedin.com/in/amit-kavrie-11b8852/"
          profilePic={amit_kavrie}
        >
          <h5 className="font-medium leading-relaxed pb-4">
            <span className="sg-translate ">
              Amit Kavrie, the Managing Director of Supreme Nonwoven Group,
              leads with a combination of strategic insight and innovative
              thinking. His academic background with a BS from Philadelphia
              College of Textiles and Science (now part of Thomas Jefferson
              University), coupled with a dual MS from NC State University,
              Raleigh, and global experiences, shapes his comprehensive
              leadership style.
            </span>
            <span className="lg:hidden">
              {founderStory.sohan ? (
                ''
              ) : (
                <>
                  ..
                  <button
                    onClick={() => handleClick('sohan')}
                    type="button"
                    className="text-blueDark mt-1"
                  >
                    Read More
                  </button>
                </>
              )}
            </span>
          </h5>
          <div
            className={`${
              founderStory.sohan ? 'h-fit' : 'h-0 lg:h-fit'
            } overflow-hidden  `}
          >
            <h5 className="sg-translate font-normal leading-relaxed pb-4 xl:pb-6 2xl:pb-6">
              Under Amit’s guidance Supreme is positioned as a leader in diverse
              value-added nonwoven products and services for industries such as
              filtration, apparel interlinings, and automotive trims solutions.
              He actively participates in key industry forums, including ACMA
              and global business platform, focusing on technology and business
              innovation.
            </h5>
            <h5 className="sg-translate font-normal leading-relaxed pb-2 md:w-11/12 ">
              His vision for Supreme intertwines market leadership with a
              commitment to sustainability. Amit cultivates an organisational
              culture that values innovation, advanced technologies, and dynamic
              leadership, steering the company towards global recognition and
              excellence in the nonwovens industry.
            </h5>
          </div>
          <span className="lg:hidden">
            {founderStory.sohan ? (
              <button
                onClick={() => handleClick('sohan')}
                type="button"
                className="text-blueDark text-sm xlg:text-xl"
              >
                Read Less
              </button>
            ) : (
              ''
            )}
          </span>
        </Founder>
      </div>
    </section>
  )
}

type IFounder = {
  title: string
  subtitle: string
  linkedinProfile: string
  profilePic: string
  children: ReactNode
}
function Founder({
  title,
  subtitle,
  linkedinProfile,
  profilePic,
  children,
}: IFounder) {
  return (
    <div className="lg:grid grid-cols-10 blade-top-padding-sm">
      <div className="max-w-sm col-start-1 col-end-5 md:max-w-lg md:mx-auto ">
        <div className="px-5 md:px-0">
          <img
            className="w-full h-auto object-cover object-center"
            src={profilePic}
            alt={title}
          />
        </div>
        <div className="pl-5 xl:pl-6 pr-5 xl:pr-4 2xl:pr-6  pb-4 pt-6  md:pt-6 xl:pt-8 2xl:pt-10 flex justify-between gap-x-8">
          <div className="flex-1">
            <div className="flex justify-between">
              <h3 className="sg-translate font-semibold pb-1 md:text-2xl text-xl">
                {title}{' '}
              </h3>
              <div className="grid place-content-center md:place-content-end flex-0 pb-px ">
                <a
                  rel="noreferrer"
                  href={linkedinProfile}
                  target="_blank"
                  className="grid p-1 hover:fill-blueDark transition-all duration-300 ease-in-out focus-visible:fill-blueDark  fill-blue place-content-center"
                >
                  <svg
                    width={29}
                    height={30}
                    viewBox="0 0 29 30"
                    fill="inherit"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Group">
                      <path
                        id="Vector"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.1869 0.00341797H2.12813C0.977355 0.00341797 0 0.955654 0 2.12872V27.4143C0 28.5888 0.641263 29.541 1.79204 29.541H25.8508C27.0029 29.541 28.2317 28.5888 28.2317 27.4143V2.12872C28.2317 0.955654 27.339 0.00341797 26.1869 0.00341797ZM10.7549 11.2558H14.5555V13.2827H14.5971C15.1766 12.1898 16.8879 11.08 19.004 11.08C23.0653 11.08 24.1986 13.3361 24.1986 17.515V25.3214H20.1655V18.2844C20.1655 16.4137 19.4516 14.7722 17.7819 14.7722C15.7546 14.7722 14.788 16.2083 14.788 18.5657V25.3214H10.7549V11.2558ZM4.0331 25.3214H8.0662V11.2558H4.0331V25.3214ZM8.57034 6.3329C8.57034 7.79009 7.44241 8.97019 6.04965 8.97019C4.65689 8.97019 3.52896 7.79009 3.52896 6.3329C3.52896 4.87572 4.65689 3.69562 6.04965 3.69562C7.44241 3.69562 8.57034 4.87572 8.57034 6.3329Z"
                        fill="inherit"
                      />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
            <h6
              className="sg-translate font-medium md:max-w-[200px] leading-snug 2xl:max-w-none opacity-50"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </div>
        </div>
      </div>
      <div className="md:bg-gradientToBottom md:blade-top-margin-sm md:blade-top-margin md:blade-top-padding blade-bottom-padding grid grid-cols-8 col-start-5 col-end-12 xlg:col-end-11">
        <div className="pl-5 md:pl-0 col-start-1 md:col-start-2 col-end-8 flex flex-col gap-1 justify-center items-start">
          {children}
        </div>
      </div>
    </div>
  )
}
