import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/autoplay'

import c_01 from 'assets/who-we-are/customization/01.jpg'
import c_02 from 'assets/who-we-are/customization/02.jpg'
import c_03 from 'assets/who-we-are/customization/03.jpg'

const slides = [
  {
    title: 'Online technical bulletin',
    desc: ' A technical topic related to interlining and its application is shared fortnightly with our business partners.',
    cover: c_01,
  },
  {
    title: 'Shop floor studies',
    desc: 'Qualified textile technologists assess the relative productivity and performance of interlinings.',
    cover: c_02,
  },
  {
    title: 'Technical seminars',
    desc: 'Our experts conduct educational seminars on the importance of using the right quality of interlining for different applications and fabrics.',
    cover: c_03,
  },
]

export default function Ecosystem() {
  return (
    <section className="blade-top-padding-sm ecosystem-wrapper blade-bottom-padding-xl bg-blueDark">
      <div className="w-container lg:grid grid-cols-11 mx-auto blade-top-padding blade-bottom-padding-lg  ">
        <div className=" col-start-1 xlg:col-start-2 col-end-7 pt-3 xl:pt-0">
          <h2 className="sg-translate font-light leading-snug text-white">
            Nurturing an ecosystem <br className="md:block hidden" /> of
            <strong className="font-semibold"> customer-centric</strong>{' '}
            <br className="md:block hidden" /> customisation
          </h2>
          {/* <h3 className=" md:hidden block font-light leading-snug text-white">
            Nurturing an <br className="md:block hidden" />{' '}
            <strong className="font-semibold">ecosystem</strong>{' '}
            <br className="md:block hidden" /> of customer-centric customization
          </h3> */}
        </div>
        <div className="text-white col-start-7 lg:pl-8 xl:pl-14 col-end-11 max-w-xl flex flex-col gap-y-3 w-11/12 md:w-full md:gap-6 xl:gap-8  2xl:gap-12 pt-6 md:pt-8 lg:pt-0">
          <span className="sg-translate block text-base 2xl:text-xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal font-normal">
            Customisation is more than a service; it&apos;s our hallmark. Our
            state-of-the-art facility is designed to adapt, ensuring that every
            nonwoven solution we create is tailored to your exact
            specifications.
          </span>
          <span className="sg-translate block text-base 2xl:text-xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal font-normal">
            Our broad portfolio offers a spectrum of possibilities, whether you
            need automotive solutions, functional interlining, filtration
            excellence, or bespoke nonwovens for specialized applications.
          </span>
        </div>
      </div>

      <div className="blade-top-padding-xs ecosystem-carousel blade-bottom-padding-sm ">
        <Swiper
          centeredSlides
          slidesPerView={1.1}
          slidesPerGroup={1}
          navigation={{
            prevEl: '.life-carousel-prev-btn',
            nextEl: '.life-carousel-next-btn',
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            1024: {
              centeredSlides: false,
              slidesPerView: 3,
              spaceBetween: 24,
            },
            1280: {
              centeredSlides: false,
              spaceBetween: 24,
              slidesPerView: 3,
            },
            1440: {
              centeredSlides: false,
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
          className="cursor-grab px-5"
          // autoplay={{
          //   delay: 0,
          //   disableOnInteraction: false,
          //   pauseOnMouseEnter: true,
          // }}
          // speed={7000}
          // modules={[Autoplay]}
          spaceBetween={6}
        >
          {slides.map((elem, index: number) => {
            const { cover, title, desc } = elem
            const key = `${index}`
            return (
              <SwiperSlide key={key}>
                <Slide title={title} desc={desc} cover={cover} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </section>
  )
}

function Slide({
  title,
  cover,
  desc,
}: {
  title: string
  desc: string
  cover: string
}) {
  return (
    <article className="2xl:rounded-[20px] group">
      <div className="relative ">
        <div className="bg-gradient-to-b from-transparent to-black h-64 md:h-72 lg:h-80 xlg:h-[26rem] rounded-xl lg:rounded-2xl overflow-hidden  2xl:h-[28.75rem]">
          <img
            className=" h-64 md:h-72 lg:h-80 xlg:h-[26rem] 2xl:h-[28.75rem] w-full object-cover object-center"
            src={cover}
            alt={title}
          />
        </div>
      </div>
    </article>
  )
}
