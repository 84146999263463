import Acc from 'assets/home/partners/newlogos/Acc.png'
import Ambuja from 'assets/home/partners/newlogos/Ambuja.png'
import AshokLeyland from 'assets/home/partners/newlogos/AshokLeyland.png'
import Asics from 'assets/home/partners/newlogos/Asics.png'
import BirlaCement from 'assets/home/partners/newlogos/BirlaCement.png'
import BananaRepublic from 'assets/home/partners/newlogos/BananaRepublic.png'
import CA from 'assets/home/partners/newlogos/C&A.png'
import Cecil from 'assets/home/partners/newlogos/Cecil.png'
import Chettinad from 'assets/home/partners/newlogos/Chettinad.png'
import Clp from 'assets/home/partners/newlogos/Clp.png'
import Daimler from 'assets/home/partners/newlogos/Daimler.png'
import Dalmia from 'assets/home/partners/newlogos/Dalmia.png'
import Debenhams from 'assets/home/partners/newlogos/Debenhams.png'
import Decathlon from 'assets/home/partners/newlogos/Decathlon.png'
import Eicher from 'assets/home/partners/newlogos/Eicher.png'
import Esprit from 'assets/home/partners/newlogos/Esprit.png'
import Force from 'assets/home/partners/newlogos/Force.png'
import Ford from 'assets/home/partners/newlogos/Ford.png'
import Gap from 'assets/home/partners/newlogos/Gap.png'
import George from 'assets/home/partners/newlogos/George.png'
import GeneralMotors from 'assets/home/partners/newlogos/GeneralMotors.png'
import Gokalidas from 'assets/home/partners/newlogos/Gokaldas exports.png'
import Heindelberg from 'assets/home/partners/newlogos/HeindelbergCement.png'
import HM from 'assets/home/partners/newlogos/HM.png'
import Honda from 'assets/home/partners/newlogos/Honda.png'
import HUL from 'assets/home/partners/newlogos/HindustanUnilieverLtd.png'
import Hyundai from 'assets/home/partners/newlogos/Hyundai.png'
import JindalSteelpower from 'assets/home/partners/newlogos/JindalSteel&power.png'
import JKCement from 'assets/home/partners/newlogos/JKCement.png'
import John from 'assets/home/partners/newlogos/JohnDeere.png'
import JohnLewis from 'assets/home/partners/newlogos/JohnLewis.png'
import JSW from 'assets/home/partners/newlogos/JSW.png'
import Kappahl from 'assets/home/partners/newlogos/Kappahl.png'
import KIA from 'assets/home/partners/newlogos/KIA.png'
import Lsfarge from 'assets/home/partners/newlogos/Lafarge.png'
import lanco from 'assets/home/partners/newlogos/lanco.png'
import MS from 'assets/home/partners/newlogos/M&S.png'
import macys from 'assets/home/partners/newlogos/macys.png'
import Mahindra from 'assets/home/partners/newlogos/Mahindra.png'
import MarutiSuzuki from 'assets/home/partners/newlogos/MarutiSuzuki.png'
import Monsoon from 'assets/home/partners/newlogos/Monsoon.png'
import MorrisGarages from 'assets/home/partners/newlogos/MorrisGarages.png'
import Nalco from 'assets/home/partners/newlogos/Nalco.png'
import Mothercare from 'assets/home/partners/newlogos/Mothercare.png'
import Next from 'assets/home/partners/newlogos/Next.png'
import Nissan from 'assets/home/partners/newlogos/Nissan.png'
import Nordstrom from 'assets/home/partners/newlogos/Nordstrom.png'
import NTPC from 'assets/home/partners/newlogos/NTPC.png'
import OldNavy from 'assets/home/partners/newlogos/OldNavy.png'
import Osakahockey from 'assets/home/partners/newlogos/Osakahockey.png'
import Ramco from 'assets/home/partners/newlogos/Ramco.png'
import RelianceCement from 'assets/home/partners/newlogos/RelianceCement.png'
import Renault from 'assets/home/partners/newlogos/Renault.png'
import Rieco from 'assets/home/partners/newlogos/Rieco.png'
import Sail from 'assets/home/partners/newlogos/Sail.png'
import SaintGobain from 'assets/home/partners/newlogos/SaintGobain.png'
import Scania from 'assets/home/partners/newlogos/Scania.png'
import Sears from 'assets/home/partners/newlogos/Sears.png'
import SMLIsuzu from 'assets/home/partners/newlogos/SMLIsuzu.png'
import TataMotors from 'assets/home/partners/newlogos/TataMotors.png'
import TataSteel from 'assets/home/partners/newlogos/TataSteel.png'
import ThemisMed from 'assets/home/partners/newlogos/Themis.png'
import TheIndianCement from 'assets/home/partners/newlogos/TheIndianCement.png'
import Thermax from 'assets/home/partners/newlogos/Thermax.png'
import Toyota from 'assets/home/partners/newlogos/Toyota.png'
import UltraTechCement from 'assets/home/partners/newlogos/UltraTechCement.png'
import Vedanta from 'assets/home/partners/newlogos/Vedanta.png'
import Volvo from 'assets/home/partners/newlogos/Volvo.png'

import BharathiCement from 'assets/filtration/clients/cement/BharathiCement.png'
import BirlaCorporation from 'assets/filtration/clients/cement/BirlaCorporation.png'
import IndiaCements from 'assets/filtration/clients/cement/IndiaCements.png'
import NCL from 'assets/filtration/clients/cement/NCL.png'
import NationalCement from 'assets/filtration/clients/cement/NationalCement.png'
import UltraTech from 'assets/filtration/clients/cement/UltraTech.png'

import Clair from 'assets/filtration/clients/oem/Clair.png'
import Ecomak from 'assets/filtration/clients/oem/Ecomak.png'
import ElexIndia from 'assets/filtration/clients/oem/ElexIndia.png'
import GEA from 'assets/filtration/clients/oem/GEA.png'
import IFI from 'assets/filtration/clients/oem/IFI.png'
import IntensiveFilter from 'assets/filtration/clients/oem/IntensiveFilter.png'
import Isgec from 'assets/filtration/clients/oem/Isgec.png'
import SMSGroup from 'assets/filtration/clients/oem/SMSGroup.png'
import UnitedConveyor from 'assets/filtration/clients/oem/UnitedConveyor.png'

import AdityaBirla from 'assets/filtration/clients/ferro-alloy-&-smelter/AdityaBirlaAlluminium.png'
import HindustanZinc from 'assets/filtration/clients/ferro-alloy-&-smelter/HindustanZinc.png'
import IMFA from 'assets/filtration/clients/ferro-alloy-&-smelter/IMFA.png'
import MaithanAlloys from 'assets/filtration/clients/ferro-alloy-&-smelter/MaithanAlloys.png'

import Adhunik from 'assets/filtration/clients/power/Adhunik.png'
import Balco from 'assets/filtration/clients/power/Balco.png'
import GMR from 'assets/filtration/clients/power/GMR.png'
import MaruitiClean from 'assets/filtration/clients/power/MaruitiClean.png'
import ThermalPowertech from 'assets/filtration/clients/power/ThermalPowertech.png'

import AartiSteel from 'assets/filtration/clients/iron-&-steel/AartiSteel.png'
import BhushanSteel from 'assets/filtration/clients/iron-&-steel/BhushanSteel.png'
import Boldrocchi from 'assets/filtration/clients/iron-&-steel/Boldrocchi.png'
import EssarSteel from 'assets/filtration/clients/iron-&-steel/EssarSteel.png'
import JindalSaw from 'assets/filtration/clients/iron-&-steel/JindalSaw.png'
import JindalStainless from 'assets/filtration/clients/iron-&-steel/JindalStainless.png'

export const partnersLogos = [
  {
    id: 1,
    logo: Acc,
    title: 'ACC',
    category: 'other',
    alt: 'acc-logo',
  },
  {
    id: 81,
    logo: Adhunik,
    title: 'Adhunik',
    category: 'Metals',
    alt: 'adhunik-logo',
  },
  {
    id: 90,
    logo: AdityaBirla,
    title: 'AdityaBirla',
    category: 'Metals',
    alt: 'AdityaBirla-logo',
  },
  {
    id: 2,
    logo: Ambuja,
    title: 'Ambuja',
    category: 'Construction',
    alt: 'ambuja-logo',
  },
  {
    id: 3,
    logo: AshokLeyland,
    title: 'Ashok Leyland',
    category: 'automotive',
    alt: 'ashok-leyland-logo',
  },
  {
    id: 4,
    logo: Asics,
    title: 'Asics',
    category: 'Sportswear',
    alt: 'asics-logo',
  },
  {
    id: 82,
    logo: AartiSteel,
    title: 'AartiSteel',
    category: 'Metals',
    alt: 'aarti-steel-logo',
  },
  {
    id: 5,
    logo: BananaRepublic,
    title: 'Banana Republic',
    category: 'Unknown',
    alt: 'BananaRepublic-logo',
  },
  {
    id: 83,
    logo: Balco,
    title: 'Balco',
    category: 'Metals',
    alt: 'balco-logo',
  },
  {
    id: 84,
    logo: BharathiCement,
    title: 'BharathiCement',
    category: 'Cement',
    alt: 'bharaticement-logo',
  },
  {
    id: 85,
    logo: BhushanSteel,
    title: 'BhushanSteel',
    category: 'Metals',
    alt: 'bhushansteel-logo',
  },
  {
    id: 86,
    logo: Boldrocchi,
    title: 'Boldrocchi',
    category: 'Machinery',
    alt: 'boldrocchi-logo',
  },
  {
    id: 6,
    logo: BirlaCement,
    title: 'Birla Cement',
    category: 'Unknown',
    alt: 'BirlaCement-logo',
  },
  {
    id: 7,
    logo: BirlaCorporation,
    title: 'BirlaCorporation',
    category: 'Diversified',
    alt: 'birlacorporation-logo',
  },
  {
    id: 8,
    logo: CA,
    title: 'C&A',
    category: 'Unknown',
    alt: 'c-and-a-logo',
  },
  {
    id: 9,
    logo: Cecil,
    title: 'Cecil',
    category: 'Fashion',
    alt: 'cecil-logo',
  },
  {
    id: 9,
    logo: '',
    title: 'citroen',
    category: 'automotive',
    alt: 'citroen-logo',
  },
  {
    id: 10,
    logo: Chettinad,
    title: 'Chettinad',
    category: 'Unknown',
    alt: 'chettinad-logo',
  },
  {
    id: 11,
    logo: Clair,
    title: 'Clair',
    category: 'Unknown',
    alt: 'clair-logo',
  },
  {
    id: 12,
    logo: Clp,
    title: 'clp group',
    category: 'Unknown',
    alt: 'clp-logo',
  },
  {
    id: 13,
    logo: Daimler,
    title: 'Daimler',
    category: 'automotive',
    alt: 'daimler-logo',
  },
  {
    id: 14,
    logo: Dalmia,
    title: 'Dalmia',
    category: 'Construction',
    alt: 'dalmia-logo',
  },
  {
    id: 15,
    logo: Debenhams,
    title: 'Debenhams',
    category: 'Retail',
    alt: 'debenhams-logo',
  },
  {
    id: 16,
    logo: Decathlon,
    title: 'Decathlon',
    category: 'Sports',
    alt: 'decathlon-logo',
  },
  {
    id: 87,
    logo: Ecomak,
    title: 'Ecomak',
    category: 'Unknown',
    alt: 'ecomak-logo',
  },
  {
    id: 17,
    logo: Eicher,
    title: 'Eicher',
    category: 'automotive',
    alt: 'Eicher-logo',
  },
  {
    id: 18,
    logo: ElexIndia,
    title: 'ElexIndia',
    category: 'Unknown',
    alt: 'elexindia-logo',
  },
  {
    id: 19,
    logo: Esprit,
    title: 'Esprit',
    category: 'Fashion',
    alt: 'esprit-logo',
  },
  {
    id: 20,
    logo: Force,
    title: 'Force Motors',
    category: 'automotive',
    alt: 'force-logo',
  },
  {
    id: 21,
    logo: Ford,
    title: 'Ford',
    category: 'automotive',
    alt: 'ford-logo',
  },
  {
    id: 22,
    logo: Gap,
    title: 'Gap',
    category: 'Fashion',
    alt: 'gap-logo',
  },
  {
    id: 23,
    logo: George,
    title: 'George',
    category: 'Fashion',
    alt: 'george-logo',
  },
  {
    id: 90,
    logo: GeneralMotors,
    title: 'General Motors',
    category: 'automotive',
    alt: 'general-motors-logo',
  },
  {
    id: 24,
    logo: Gokalidas,
    title: 'Gokalidas Exports',
    category: 'Textiles',
    alt: 'gokalidas-exports-logo',
  },
  {
    id: 89,
    logo: GMR,
    title: 'GMR',
    category: 'Infrastructure',
    alt: 'gmr-logo',
  },

  {
    id: 26,
    logo: Heindelberg,
    title: 'Heindelberg Cement',
    category: 'Unknown',
    alt: 'Heindelberg-logo',
  },
  {
    id: 27,
    logo: Honda,
    title: 'Honda',
    category: 'automotive',
    alt: 'honda-logo',
  },
  {
    id: 28,
    logo: HUL,
    title: 'HUL',
    category: 'FMCG',
    alt: 'hul-logo',
  },

  {
    id: 91,
    logo: HindustanZinc,
    title: 'HindustanZinc',
    category: 'Metals',
    alt: 'hindustanzinc-logo',
  },
  {
    id: 25,
    logo: HM,
    title: 'HM',
    category: 'Unknown',
    alt: 'hm-logo',
  },
  {
    id: 29,
    logo: Hyundai,
    title: 'Hyundai',
    category: 'automotive',
    alt: 'hyundai-logo',
  },
  {
    id: 30,
    logo: IFI,
    title: 'IFI',
    category: 'Unknown',
    alt: 'ifi-logo',
  },
  {
    id: 31,
    logo: IMFA,
    title: 'IMFA',
    category: 'Unknown',
    alt: 'imfa-logo',
  },
  {
    id: 32,
    logo: IndiaCements,
    title: 'IndiaCements',
    category: 'Cement',
    alt: 'indiacements-logo',
  },
  {
    id: 33,
    logo: IntensiveFilter,
    title: 'IntensiveFilter',
    category: 'Machinery',
    alt: 'intensivefilter-logo',
  },
  {
    id: 34,
    logo: Isgec,
    title: 'Isgec',
    category: 'Machinery',
    alt: 'isgec-logo',
  },
  {
    id: 55,
    logo: '',
    title: 'JEEP',
    category: 'automotive',
    alt: 'jeep-logo',
  },
  {
    id: 35,
    logo: JKCement,
    title: 'JK Cement',
    category: 'Unknown',
    alt: 'JKCement-logo',
  },
  {
    id: 36,
    logo: JindalSaw,
    title: 'JindalSaw',
    category: 'Metals',
    alt: 'jindalsaw-logo',
  },
  {
    id: 37,
    logo: JindalStainless,
    title: 'JindalStainless',
    category: 'Metals',
    alt: 'jindalstainless-logo',
  },
  {
    id: 38,
    logo: JindalSteelpower,
    title: 'JindalSteelSuppo',
    category: 'Steel',
    alt: 'jindalsteelsuppo-logo',
  },
  {
    id: 39,
    logo: John,
    title: 'John Deere',
    category: 'automotive',
    alt: 'john-deere-logo',
  },
  {
    id: 40,
    logo: JohnLewis,
    title: 'JohnLewis',
    category: 'Retail',
    alt: 'johnlewis-logo',
  },
  {
    id: 41,
    logo: JSW,
    title: 'JSW',
    category: 'Steel',
    alt: 'jsw-logo',
  },
  {
    id: 43,
    logo: Kappahl,
    title: 'Kappahl',
    category: 'Fashion',
    alt: 'kappahl-logo',
  },
  {
    id: 44,
    logo: KIA,
    title: 'KIA',
    category: 'automotive',
    alt: 'kia-logo',
  },
  {
    id: 45,
    logo: Lsfarge,
    title: 'Lafarge',
    category: 'Construction',
    alt: 'lsfarge-logo',
  },
  {
    id: 46,
    logo: lanco,
    title: 'Lanco',
    category: 'Construction',
    alt: 'lafarge-logo',
  },
  {
    id: 47,
    logo: MS,
    title: 'M&S',
    category: 'Retail',
    alt: 'm-and-s-logo',
  },
  {
    id: 48,
    logo: macys,
    title: 'macys',
    category: 'Retail',
    alt: 'macys-logo',
  },
  {
    id: 49,
    logo: Mahindra,
    title: 'Mahindra',
    category: 'automotive',
    alt: 'Mahindra-logo',
  },
  {
    id: 50,
    logo: MarutiSuzuki,
    title: 'Maruti Suzuki',
    category: 'automotive',
    alt: 'marutiSuzuki-logo',
  },
  {
    id: 93,
    logo: MaruitiClean,
    title: 'MarutiClean',
    category: 'Unknown',
    alt: 'maruiticlean-logo',
  },
  {
    id: 51,
    logo: MaithanAlloys,
    title: 'MaithanAlloys',
    category: 'Metals',
    alt: 'maithanalloys-logo',
  },
  {
    id: 52,
    logo: Monsoon,
    title: 'Monsoon',
    category: '',
    alt: '-logo',
  },
  {
    id: 53,
    logo: MorrisGarages,
    title: ' Morris Garages',
    category: 'automotive',
    alt: '-logo',
  },
  {
    id: 54,
    logo: Mothercare,
    title: 'Mothercare',
    category: 'other',
    alt: '-logo',
  },

  {
    id: 94,
    logo: NationalCement,
    title: 'NationalCement',
    category: 'Cement',
    alt: 'nationalcement-logo',
  },
  {
    id: 95,
    logo: NCL,
    title: 'NCL',
    category: 'Unknown',
    alt: 'ncl-logo',
  },
  {
    id: 55,
    logo: Nalco,
    title: 'NALCO',
    category: 'other',
    alt: 'NALCO-logo',
  },

  {
    id: 58,
    logo: Next,
    title: 'NEXT',
    category: 'retail',
    alt: '-logo',
  },
  {
    id: 59,
    logo: Nissan,
    title: 'Nissan',
    category: 'automotive',
    alt: 'nissan-logo',
  },
  {
    id: 60,
    logo: Nordstrom,
    title: 'Nordstrom',
    category: 'other',
    alt: 'Nordstrom-logo',
  },
  {
    id: 61,
    logo: NTPC,
    title: 'NTPC',
    category: 'other',
    alt: 'NTPC-logo',
  },
  {
    id: 62,
    logo: OldNavy,
    title: 'Old Navy',
    category: 'other',
    alt: 'OldNavy-logo',
  },
  {
    id: 92,
    logo: Osakahockey,
    title: 'Osaka',
    category: 'other',
    alt: 'Osakahockey-logo',
  },
  {
    id: 63,
    logo: Ramco,
    title: 'Ramco',
    category: 'other',
    alt: 'other-logo',
  },
  {
    id: 64,
    logo: RelianceCement,
    title: 'Reliance Cement',
    category: 'other',
    alt: '-logo',
  },
  {
    id: 65,
    logo: Renault,
    title: 'Renault',
    category: 'automotive',
    alt: 'renault-logo',
  },
  {
    id: 66,
    logo: Rieco,
    title: 'Rieco',
    category: 'other',
    alt: 'Rieco-logo',
  },
  {
    id: 67,
    logo: Sail,
    title: 'Sail',
    category: 'other',
    alt: '-logo',
  },
  {
    id: 68,
    logo: SaintGobain,
    title: '',
    category: 'other',
    alt: '-logo',
  },
  {
    id: 69,
    logo: '',
    title: 'Scania',
    category: 'automotive',
    alt: '-logo',
  },
  {
    id: 70,
    logo: Sears,
    title: '',
    category: 'retail',
    alt: '-logo',
  },
  {
    id: 71,
    logo: SMLIsuzu,
    title: 'SML ISUZU',
    category: 'automotive',
    alt: '-logo',
  },
  {
    id: 96,
    logo: SMSGroup,
    title: 'SMSGroup',
    category: 'machinery',
    alt: 'smsgroup-logo',
  },
  {
    id: 100,
    logo: '',
    title: 'Suzuki',
    category: 'automotive',
    alt: 'Suzuki logo',
  },

  {
    id: 72,
    logo: TataMotors,
    title: 'Tata Motors',
    category: 'automotive',
    alt: '-logo',
  },
  {
    id: 73,
    logo: TataSteel,
    title: '',
    category: 'other',
    alt: '-logo',
  },
  {
    id: 74,
    logo: ThemisMed,
    title: '',
    category: 'other',
    alt: '-logo',
  },
  {
    id: 75,
    logo: TheIndianCement,
    title: '',
    category: 'other',
    alt: '-logo',
  },
  {
    id: 97,
    logo: ThermalPowertech,
    title: 'ThermalPowertech',
    category: 'Energy',
    alt: 'thermalpowertech-logo',
  },
  {
    id: 76,
    logo: Thermax,
    title: '',
    category: 'other',
    alt: '-logo',
  },
  {
    id: 77,
    logo: Toyota,
    title: 'Toyota',
    category: 'automotive',
    alt: '-logo',
  },
  {
    id: 98,
    logo: UnitedConveyor,
    title: 'UnitedConveyor',
    category: 'Machinery',
    alt: 'unitedconveyor-logo',
  },
  {
    id: 78,
    logo: UltraTechCement,
    title: '',
    category: 'other',
    alt: '-logo',
  },

  {
    id: 79,
    logo: Vedanta,
    title: '',
    category: 'other',
    alt: '-logo',
  },
  {
    id: 80,
    logo: Volvo,
    title: 'Volvo',
    category: 'automotive',
    alt: '-logo',
  },
]
