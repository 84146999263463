import React, { useEffect, useRef } from 'react'
import officeFronteView from 'assets/who-we-are/office.webp'
import officeFronteViewMobile from 'assets/who-we-are/office_mobile.webp'
import { useIntersectionObserver } from 'hooks'

export default function Values() {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, { threshold: 0.3 })
  const isVisible = !!entry?.isIntersecting

  // ${
  //   isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
  // }

  return (
    <section className="blade-top-padding-lg blade-bottom-padding-">
      <div ref={ref} className="w-container blade-top-padding-sm">
        <h4
          className={`sg-translate text-black text-opacity-60 font-medium transition-all duration-700 ease-in-out `}
        >
          Who we are
        </h4>

        <h2
          className={`sg-translate transition-all delay-100 duration-700 ease-in-out leading-snug pt-2 `}
        >
          Since 1986, we&apos;ve been advancing in{' '}
          <br className="hidden md:block" /> the field of nonwoven solutions,
          setting <br className="hidden md:block" /> new standards year after
          year.
        </h2>
        <div className="md:block hidden md:h-[280px] lg:h-[300px] xl:h-[330px] 2xl:h-[350px] my-6 md:my-8 lg:my-10 xl:my-12 bg-[url('assets/who-we-are/office_loader.webp')] bg-center bg-cover bg-no-repeat">
          <img
            loading="lazy"
            className="h-full w-full object-cover object-center"
            src={officeFronteView}
            alt="front view of office of supreme group"
          />
        </div>
        <div
          className="mh-[200px] my-6 md:hidden block bg-[url('assets/who-we-are/office_mobile_loader.webp')] bg-center bg-cover 
        bg-no-repeat"
        >
          <img
            loading="lazy"
            className="h-full w-full object-cover object-center"
            src={officeFronteViewMobile}
            alt="front view of office of supreme group"
          />
        </div>
        <div className="lg:grid grid-cols-5 gap-y-4 flex flex-col gap-x-4 xl:gap-x-6 blade-bottom-padding-sm">
          <h5 className="sg-translate leading-relaxed col-start-1 col-end-4  pr-3 max-w-xl">
            From cutting-edge manufacturing technology to scalable development
            and customer-centric innovation, we stand at the forefront of
            India&apos;s nonwoven market. Not only do we provide tailored
            solutions for emerging markets, but also help our customers improve
            their product performance and process efficiency at the same time.
          </h5>
          <h5 className="sg-translate leading-relaxed w-11/12 md:w-full col-start-4 col-end-6 max-w-xl">
            With more thans{' '}
            <strong className="font-semibold"> 1400 members,</strong> our
            practices are <br className="md:block hidden" /> guided by our
            unwavering values.
          </h5>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col px-8 xl:w-container items-center py-8 md:py-10 lg:py-8">
        <Circle
          activeStyleClass="values-circle-yellow"
          text="Safety"
          description="Make safety a part of every decision and action"
        />
        <Circle
          activeStyleClass="values-circle-blue"
          text="Performance"
          description="Drive improvements actively while ensuring sustainability"
        />
        <Circle
          activeStyleClass="values-circle-purple"
          text="Integrity"
          description="Create an atmosphere of trust and respect for commitments"
        />
        <Circle
          activeStyleClass="values-circle-orange"
          text="Innovation"
          description="Continuously improve and drive change actively by adopting future trends"
        />
        <Circle
          activeStyleClass="values-circle-green"
          text="Respect for people"
          description="Be fair and treat everyone with respect"
        />
      </div>
    </section>
  )
}

function Circle({
  text,
  activeStyleClass,
  description,
}: {
  text: string
  description: string
  activeStyleClass: string
}) {
  return (
    <article
      className="relative h-44 lg:h-48 xl:h-56 2xl:h-[330px] md:basis-48 lg:basis-64 xl:basis-60 xlg:basis-80 
      lg:w-7/12 md:w-[220px] sm:w-[210px] w-[200px] group lg:max-w-md max-w-xs values-container"
    >
      <div
        className={`absolute transition-all duration-300 origin-center ease-in-out 
      aspect-square border-1 lg:-m-2 border-solid border-[#00000099] border-opacity-5 grid place-content-center 
      place-items-center rounded-full ${activeStyleClass}`}
      >
        <span className="sg-translate font-semibold text-center text-sm xl:text-lg xlg:text-xl 2xl:text-2xl">
          {text}
        </span>
        <h6
          className="text-sm xl:text-md 2xl:text-xl text-center px-4 md:group-hover:h-20 md:opacity-0 group-hover:opacity-100 
          md:h-0 transition-all duration-300 mt-2 mx-2 xl:mx-5"
        >
          <span className="sg-translate">{description}</span>
        </h6>
      </div>
    </article>
  )
}
