import React from 'react'
import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'

export default function Subfooter() {
  const navigate = useNavigate()
  return (
    <section className="relative h-72 lg:h-80 xl:h-[28rem] xlg:h-[30rem] 2xl:h-[34rem]  text-center  bg-cover bg-center bg-[url('assets/apparel/subfooter_bg-blue.jpg')]">
      <div className="relative z-10 grid place-content-center place-items-center gap-5 xlg:gap-8  xl:gap-10 2xl:gap-12 h-full w-full">
        <h2 className="sg-translate font-light leading-tight text-white md:block hiden -mb-1 xl:-mb-2">
          Connect with our <br />
          <span className="font-medium">interlining experts</span>
        </h2>

        <HeroBtn
          size="small"
          text="Contact us"
          color="blueLight"
          onClick={() => navigate('/contact-us')}
          type="button"
        />
      </div>
      {/* <div className="bg-black bg-opacity-50 h-full w-full absolute inset-0 z-1" /> */}
    </section>
  )
}
