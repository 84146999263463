import React, { useEffect } from 'react'
import useScrollTop from 'hooks/useScrollTop'
import SubFooter from 'organisms/subFooter'

import PageBanner from 'organisms/pageBanner'
import bannerPoster from 'assets/videos/general-nonwoven.webp'
import bannerVideo from 'assets/videos/general-nonwoven.mp4'
import nonwoven from 'assets/globals/nonwoven.svg'
// import Banner from './banner'
import Application from './application'
import EnableEcosystem from './enable_ecosystem'

export default function GeneralNonwoven() {
  const scrollTop = useScrollTop()

  useEffect(() => {
    scrollTop()
  }, [])
  return (
    <div>
      <PageBanner
        bgVideo={bannerVideo}
        videoPoster={bannerPoster}
        pageName="Engineered for excellence"
        title="<strong class='font-semibold text-blue'>Customised Nonwoven</strong> solutions <br class='md:block hidden' /> for specialized applications"
        subtitle=""
        icon={nonwoven}
        iconAlt="Supreme nonwoven"
        iconClasses="h-8 lg:h-10 w-auto object-contain object-center mx-auto mb-1 xl:mb-2 animate-fade opacity-0"
      />
      <EnableEcosystem />
      <Application />
      <SubFooter
        title="Looking for <strong class='font-semibold'> customised <br class='md:block hidden' /> nonwoven </strong> solutions?"
        btn={{ text: 'Contact us', target: '/contact-us' }}
        className="bg-[url('assets/general_nonwoven/subfooter.jpg')] bg-center"
      />
    </div>
  )
}
