import React, { ReactNode, useState } from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import Aluminium from 'assets/filtration/applicationExpertise/aluminium.jpg'
import cement from 'assets/filtration/applicationExpertise/cement.jpg'
import petrochemicals from 'assets/filtration/applicationExpertise/petrochemicals.jpg'
import ferros from 'assets/filtration/applicationExpertise/FerroAlloys.jpg'
import mining from 'assets/filtration/applicationExpertise/mining.jpg'
import powerplant from 'assets/filtration/applicationExpertise/ThermalPowerPlants.jpg'
import filterbagHouse from 'assets/filtration/applicationExpertise/FilterBagHouse.jpg'
import scientist from 'assets/filtration/applicationExpertise/Pharmaceuticals.jpg'
import techAI from 'assets/filtration/applicationExpertise/automotive.jpg'
import iron from 'assets/filtration/applicationExpertise/IronAndSteel.jpg'

import { Navigation } from 'swiper'
import 'swiper/css/navigation'
import './style.css'

const slides = [
  {
    title: 'Cement',
    cover: cement,
  },
  {
    title: 'Iron & steel',
    cover: iron,
  },
  {
    title: 'Mining',
    cover: mining,
  },
  {
    title: 'Aluminium',
    cover: Aluminium,
  },
  {
    title: 'Ferro – Alloys',
    cover: ferros,
  },
  {
    title: 'Thermal power plants',
    cover: powerplant,
  },
  {
    title: 'Petro chemicals',
    cover: petrochemicals,
  },
  {
    title: 'Pharmaceuticals',
    cover: scientist,
  },
  {
    title: 'Automotive',
    cover: techAI,
  },
  {
    title: 'Filter bag house',
    cover: filterbagHouse,
  },
]

const width = window.innerWidth

export default function ApplicationExpertise() {
  const [current, setCurrent] = useState(0)

  return (
    <div className="bg-white blade-top-padding-lg sm:blade-top-padding-xl blade-bottom-padding-sm">
      <div className="w-container-lg 2xl:w-container flex justify-between flex-col gap-y-2 gap-x-10">
        <h1 className="sg-translate font-light flex-1 leading-tight max-sm:text-center">
          <span className="font-semibold">Application expertise</span> <br />
          across industries
        </h1>
        <div className="max-w-sm mx-auto sm:mx-0 lg:max-w-md xl:max-w-2xl 2xl:max-w-[800px] basis-0 grow-[2] mt-4 shrink pr7 md:pr-0 mb-2">
          <span className="sg-translate font-normal z-10 leading-normal block opacity-60 text-base md:text-base lg:text-lg  2xl:text-[22px] max-sm:text-center">
            Our filtration solutions span across vital industries and associated
            applications, ensuring enhanced performance and compliance.
          </span>
        </div>
      </div>
      <div className="">
        <Swiper
          centeredSlides={false}
          slidesPerView={width < 768 ? 1 : 'auto'}
          // loop
          className="cursor-grab pt-1 relative 2xl:min-h-[400px] lg:min-h-[400px] md:pl-[6%] lg:pl-[5.5%] 
          min-[1800px]:pl-[10.5%] px-1"
          onSlideChange={(swiper) => {
            if (width >= 768) setCurrent(swiper.activeIndex)
          }}
          modules={[Navigation]}
          spaceBetween={10}
          navigation={{
            nextEl: '.swiper-next-btn',
            prevEl: '.swiper-prev-btn',
          }}
        >
          {slides.map((elem, index: number) => {
            const { cover, title } = elem
            const key = `${index}`
            return (
              <SwiperSlide
                key={key}
                className="pt-4 md:py-2 md:px-0 px-2 w-[300px] md:w-[400px] lg:w-[420px] xl:w-[450px] last:pr-4 
                transition-all duration-300"
              >
                <Slide title={title} cover={cover} />
              </SwiperSlide>
            )
          })}
        </Swiper>

        <div
          className="flex z-10 -top-[1%] md:-top-[2%] right-6 lg:right-16 xl:right-[12%] justify-end w-container-sm 
          gap-5 pt-3 pl-5 md:pl-0 md:mb-6"
        >
          <SliderBtn direction="left" slideClass="swiper-prev-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
          <SliderBtn direction="right" slideClass="swiper-next-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
        </div>
      </div>
    </div>
  )
}

export function SliderBtn({
  slideClass,
  children,
  direction,
  disable,
}: {
  slideClass: string
  children: ReactNode
  direction: string
  disable?: boolean
}) {
  const swiper = useSwiper()

  const clickHandler = () => {
    if (direction === 'left') {
      swiper.slidePrev()
    } else {
      swiper.slideNext()
    }
  }
  return (
    <button
      disabled={disable}
      onClick={clickHandler}
      type="button"
      className={`${slideClass} place-items-center rounded-full grid place-content-center h-10 lg:h-12 aspect-square 
      transition-all disabled:opacity-20
      duration-500 ease-in-out bg-blue focus:outline-blueDark outline-1 outline-sollid outline-transparent  `}
    >
      <span className="sg-translate">{children}</span>
    </button>
  )
}

function Slide({
  title,
  cover,
  desc,
}: {
  title: string
  desc?: string
  cover: string
}) {
  return (
    <article className="rounded-xl group overflow-hidden ">
      <div className="relative">
        <div className="bg-gradient-to-b from-transparent to-black h-[220px] sm:h-[300px] md:h-auto rounded-xl overflow-hidden">
          <img
            className="h-full w-full object-cover object-center"
            src={cover}
            alt={title}
          />
        </div>

        <div className="relative inset-0 flex flex-col justify-end px-2 pt-4 transition-all duration-500 ease-in-out ">
          <span className="sg-translate font-semibold text-black md:pb-2 block text-xl 2xl:text-xl text-center md:text-left">
            {title}
          </span>
        </div>
      </div>
    </article>
  )
}
