import React, { useEffect } from 'react'
import Banner from './banner'
import Divisons from './divisions'
import Form from './form'
import Offices from './offices'

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="bg-white">
      <Banner />
      <Form />
      <div className='md:bg-[url("assets/contact/wavesBg.svg")] bg-no-repeat bg-center bg-contain'>
        <Divisons />
        <Offices />
      </div>
    </div>
  )
}
