import React from 'react'
import cardA from 'assets/sustainability/governance-and-ethics/corporate-governance.jpg'
import cardB from 'assets/sustainability/governance-and-ethics/transparency-and-reporting.jpg'
import cardC from 'assets/sustainability/governance-and-ethics/ethical-conduct.jpg'

type CardProps = {
  cover: string
  title: string
  desc: string
}

export function GovernanceAndEthics() {
  return (
    <section className="blade-top-padding-lg blade-bottom-padding-xl bg-blueShade">
      <div className="grid place-content-center gap-2 text-center md:w-11/12 mx-auto px-5 blade-bottom-padding-sm ">
        <p className="sg-translate text-green text-lg md:text-xl xl:text-[22px] 2xl:text-2xl font-normal">
          Governance and ethics
        </p>
        <h2 className="sg-translate font-normal leading-tight text-black">
          Integrity in action{' '}
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-10 gap-x-6 2xl:gap-x-10 w-container pt-4 sm:pt-6 md:pt-0">
        <Card
          title="Corporate governance"
          desc="Our governance framework ensures accountability, risk management, and ethical operations, guided by a transparent and effective oversight structure."
          cover={cardA}
        />
        <Card
          title="Transparency and reporting"
          desc="We pledge full transparency in our Governance and Compliance, with comprehensive reporting and adherence to international standards and audits."
          cover={cardB}
        />
        <Card
          title="Ethical conduct"
          desc="Supreme Nonwovens upholds the highest ethical standards, with zero tolerance for corruption and unwavering commitment to fairness and integrity."
          cover={cardC}
        />
      </div>
    </section>
  )
}

function Card({ cover, title, desc }: CardProps) {
  return (
    <article className="flex flex-col w-full rounded-lg lg:rounded-xl 2xl:rounded-[20px] border-2 border-[#e1e1e1a1]">
      <div
        className="basis-[200px] rounded-t-lg lg:rounded-t-xl 2xl:rounded-t-[20px] lg:basis-[200px] 
      xlg:basis-[250px] 2xl:basis-[310px] flex-0 overflow-hidden"
      >
        <img
          src={cover}
          className="h-full w-full object-cover object-center"
          alt={title}
        />
      </div>
      <div className="flex-1 bg-white rounded-b-lg lg:rounded-b-xl 2xl:rounded-b-[20px] overflow-hidden">
        <div className="pt-4 pb-6 md:pt-8 md:pb-12 xl:pb-8 2xl:pt-8 px-4 lg:px-6 2xl:px-8">
          <h4 className="sg-translate font-semibold leading-snug md:leading-tight pr-3 pb-2">
            {title}
          </h4>
          <div className="w-11/12 md:w-full leading-snug">
            <span className="sg-translate pr-3 text-sm md:text-base 2xl:text-lg inline">
              {desc}
            </span>
          </div>
        </div>
      </div>
    </article>
  )
}
