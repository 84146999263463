import React, { useState } from 'react'
import usedBag from 'assets/filtration/used-bag.jpg'
import technical from 'assets/filtration/technical.jpg'

function ExpertAssistance() {
  const [current, setCurrent] = useState(0)
  const slides = [
    {
      title: 'Used bag analysis',
      cover: usedBag,
      list: [
        'Analysis of fiber degradation',
        'Blend composition of filter media',
        'Microscopic analysis',
        'Air density of filter media',
        'Tensile strength/bursting strength',
        'Air permeability',
        'Chemical finish',
      ],
    },
    {
      title: 'Technical services',
      cover: technical,
      list: [
        'Filter bag installation and initiation',
        'Leak seeker test',
        'Gas flow rate measurement',
        'Bag-house performance audit',
        'Troubleshooting bag-house problem',
      ],
    },
  ]

  const prevSlide = () => {
    setCurrent(0)
  }

  const nextSlide = () => {
    setCurrent(1)
  }
  return (
    <section className="relative blade-top-padding bg-blueShade">
      <div className="pl-4 pr-4 md:pr-0 md:pl-[5%] 2xl:pl-[10%] md:w-[40%]">
        <h2 className="sg-translate font-light">
          <span className="font-semibold">Expert assistance</span> for{' '}
          <br className="md:block hidden" />
          filter longevity
        </h2>

        <h5 className="sg-translate mt-5">
          Our commitment extends beyond filter products. We provide
          comprehensive support for installation and maintenance, ensuring your
          filtration systems operate optimally throughout their lifecycle.
        </h5>
      </div>

      <div className="md:block hidden w-[55%] h-full md:absolute top-0 right-0 z-0">
        <img
          className="h-full w-full object-cover"
          src={slides[current].cover}
          alt={slides[current].title}
        />
      </div>

      <div className="mt-5 relative md:bg-transparent bg-blueDark">
        <div
          className="pl-4 md:pl-[5%] 2xl:pl-[10%] md:w-[50%] md:bg-blueDark 2xl:h-[30rem] lg:h-[25rem] md:h-[22rem] h-fit 
        2xl:py-20 md:py-10 py-5  2xl:mt-16 md:mt-12  text-white relative"
        >
          <div className="flex">
            <h3 className="sg-translate text-white font-semibold mb-3">
              {slides[current].title}
            </h3>
            <div className="w-container hidden md:flex justify-end lg:gap-5 gap-3 pl-5 md:pl-0 md:mb-6 md:absolute right-5 lg:right-[5%] xl:right-[10%]">
              <SliderBtn
                callback={prevSlide}
                slideClass="expert-carousel-prev-btn"
                isDisabled={current === 0}
              />
              <SliderBtn
                callback={nextSlide}
                isDisabled={current === slides.length - 1}
                slideClass="expert-carousel-next-btn"
                reverse
              />
            </div>
          </div>

          {/* mobile view image */}
          <div className="block md:hidden h-full max-w-[300px] pr-4">
            <img
              className="h-full w-full object-cover"
              src={slides[current].cover}
              alt=""
            />
          </div>
          <ul className="mt-4 list-none 2xl:pb-10 pb-5 grid gap-2 font-light">
            {slides[current].list.map((el: any, index: number) => {
              const keyVal = `expertAssitance${index}`
              return (
                <li key={keyVal} className="relative pr-4">
                  <span className="absolute mt-1 w-5 my-auto">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z" />
                    </svg>
                  </span>
                  <h5 className="sg-translate ml-7">{el}</h5>
                </li>
              )
            })}
          </ul>
          <div className="md:hidden flex justify-start gap-3">
            <SliderBtn
              callback={prevSlide}
              slideClass="expert-carousel-prev-btn"
              isDisabled={current === 0}
            />
            <SliderBtn
              callback={nextSlide}
              isDisabled={current === slides.length - 1}
              slideClass="expert-carousel-next-btn"
              reverse
            />
          </div>
        </div>
      </div>
    </section>
  )
}
export default ExpertAssistance

function SliderBtn({
  slideClass,
  isDisabled,
  reverse,
  callback,
}: {
  slideClass: string
  isDisabled: boolean
  reverse?: boolean
  callback: () => void
}) {
  return (
    <button
      type="button"
      onClick={callback}
      className={`${slideClass} ${
        reverse ? 'rotate-180' : ''
      } place-items-center rounded-full grid place-content-center h-10 lg:h-12 aspect-square  transition-all duration-500 ease-in-out bg-blue focus:outline-blueDark outline-1 outline-sollid outline-transparent ${
        isDisabled
          ? 'bg-opacity-50 cursor-not-allowed'
          : 'bg-opacity-100 hover:bg-blueDark '
      } `}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
          fill="white"
        />
      </svg>
    </button>
  )
}
