import useScrollTop from 'hooks/useScrollTop'
import React, { useEffect } from 'react'
import FlexCards from 'molecules/flexCards'
import bannerVideo from 'assets/videos/global-competency.mp4'
import bannerPoster from 'assets/videos/global-impact.webp'
import applications from 'assets/globalCompetency/applications.png'
import applicationsMob from 'assets/globalCompetency/applications-mob.jpg'
import PageBanner from 'organisms/pageBanner'
import OurExpertise from './ourExpertise'
import Glance from './glance'
import './globalCompetency.css'

const flexCardsData = {
  title: 'Enabling global customers across industries and applications',
  paragraphList: [
    'Our foresight in identifying and adapting to transformative global trends has emphasized our position as frontrunners in nonwoven technology.',
    'Harnessing our specialized in-house expertise and commitment to purpose-led innovation, we consistently exceed the expectations of our customers globally.',
  ],
  flexImageLarge: applications,
}
export default function PageGlobalCompetency() {
  const scrollTop = useScrollTop()

  useEffect(() => {
    scrollTop()
  }, [])
  return (
    <section>
      <PageBanner
        pageName="Our global competency"
        title='Creating innovative <span class="text-[#00AEEF] font-semibold">nonwoven</span> <br class="sm:block hidden"/> solutions for the world'
        titleClassName="2xl:max-w-screen-md max-w-screen-sm"
        isVideo
        bgVideo={bannerVideo}
        videoPoster={bannerPoster}
      />

      <FlexCards
        flexImageSmall={applicationsMob}
        data={flexCardsData}
        flexImageLarge={applications}
        reverseFlex
      />
      <OurExpertise />
      <Glance />
      {/* <Slider /> */}
    </section>
  )
}
