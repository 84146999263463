import React from 'react'
import '../styles/partners.css'

type Props = {
  logos: {
    id: number
    logo: string
    alt: string
    category: string
    title: string
  }[]
  isReverse?: boolean
  largeText?: boolean
  gridStyle: string
}

export default function LogoMarquee({
  logos,
  isReverse,
  largeText,
  gridStyle,
}: Props) {
  return (
    <div>
      <div className="flex gap-4 select-none w-full overflow-hidden">
        <div className={`${gridStyle} grid flex-wrap justify-center  mx-auto`}>
          {logos.map((elem, index) => {
            return (
              <div key={elem.id} className="flex items-center">
                {elem.category !== 'automotive' ? (
                  <div className="max-w-[140px] md:max-w-[140px] mx-auto">
                    <img
                      src={elem.logo}
                      aria-hidden
                      alt={elem.alt}
                      className="2xl:h-20 xl:h-16 h-14 object-contain object-center w-full"
                    />
                  </div>
                ) : (
                  <h4
                    className={`${
                      largeText
                        ? '2xl:text-[30px] xl:text-2xl text-lg '
                        : '2xl:text-[22px] xl:text-lg text-lg md:whitespace-nowrap'
                    } h-14 flex justify-center items-center text-center font-light uppercase leading-tight mx-auto opacity-75`}
                  >
                    {elem.title}
                  </h4>
                )}
              </div>
            )
          })}
        </div>
      </div>
      {/* 
      <div className="flex gap-4 select-none w-full overflow-hidden">
        <div
          className={`flex gap-4 ${isReverse ? 'marquee-reverse' : 'marquee'}`}
        >
          {logos.map((elem) => {
            return (
              <div
                key={elem.id}
                className="grid place-content-center max-w-[140px] md:max-w-[140px] 2xl:max-w-[200px] "
              >
                {elem.category !== 'automotive' ? (
                  <img
                    src={elem.logo}
                    aria-hidden
                    alt={elem.alt}
                    className="2xl:h-28 h-20 object-contain object-center"
                  />
                ) : (
                  <h4 className="md:text-3xl text-xl font-extrabold uppercase leading-none mx-5 xl:mb-2">
                    {elem.title}
                  </h4>
                )}
              </div>
            )
          })}
        </div>
        <div
          className={`flex gap-4 ${isReverse ? 'marquee-reverse' : 'marquee'}`}
        >
          {logos.map((elem) => {
            return (
              <div
                key={elem.id}
                className="grid place-content-center max-w-[140px] md:max-w-[140px] 2xl:max-w-[200px]"
              >
                {elem.category !== 'automotive' ? (
                  <img
                    src={elem.logo}
                    aria-hidden
                    alt={elem.alt}
                    className="2xl:h-28 h-20 object-contain object-center"
                  />
                ) : (
                  <h4 className="md:text-3xl text-xl font-extrabold uppercase leading-none mx-5 xl:mb-2">
                    {elem.title}
                  </h4>
                )}
              </div>
            )
          })}
        </div>
      </div> */}

      <h6 className="text-[12px] opacity-70 w-container text-center xl:mt-5 mt-3 px-4">
        *All trademarks, logos or brand names are the property of their
        respective owners.
      </h6>
    </div>
  )
}
