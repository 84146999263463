import React from 'react'
import ecosystem_one from 'assets/home/ecosystem/1.webp'
import ecosystem_two from 'assets/home/ecosystem/2.webp'
import ecosystem_three from 'assets/home/ecosystem/3.webp'

const data = [
  {
    title: 'Cutting-edge Research & Development',
    cover: ecosystem_one,
  },
  {
    title: 'Advanced Manufacturing Processes',
    cover: ecosystem_two,
  },
  {
    title: 'End-to-end Control',
    cover: ecosystem_three,
  },
]

export default function EcosystemMobile() {
  return (
    <div className="blade-bottom-padding-xl  blade-top-padding-sm">
      <h2 className="sg-translate font-light text-center w-container mx-auto text-black leading-tight">
        An unmatched in-house
        <span className="text-blue font-semibold"> ecosystem</span>{' '}
        <br className="sm:block hidden" /> enabling product excellence
      </h2>

      <div className="grid gap-4 w-container mx-auto blade-top-padding">
        {data.map((elem, index: number) => {
          const key = `${index}`
          return (
            <article key={key} className="h-52 relative w-full rounded-2xl">
              <div className="absolute inset-0">
                <img
                  src={elem.cover}
                  alt={elem.title}
                  className="h-full w-full object-cover object-center rounded-2xl"
                />
              </div>
              <div className="flex items-end pl-3 pb-4 text-white absolute inset-0">
                <span className="sg-translate w-10/12 font-semibold leading-snug">
                  {elem.title}
                </span>
              </div>
            </article>
          )
        })}
      </div>
    </div>
  )
}
