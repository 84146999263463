import React from 'react'
import bannerPoster from 'assets/videos/career.webp'
import bannerVideo from 'assets/videos/career.mp4'

export default function Banner() {
  return (
    <section className="relative lg:h-screen min-h-[450px] oveflow-hidden bg-blueDark">
      <div className="absolute inset-0">
        <video
          autoPlay
          muted
          poster={bannerPoster}
          playsInline
          loop
          className="h-full w-full object-cover object-center"
        >
          <source src={bannerVideo} type="video/mp4" />
        </video>
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-70" />
      <div className="absolute inset-0 grid place-content-center pt-8 md:pt-5 lg:pt-0 gap-2 text-center w-11/12 mx-auto">
        <h2 className="sg-translate text-white font-light leading-tight pb-2 ">
          Your growth fuels <br className="sm:hidden block" />
          our progress
        </h2>
        <span className="sg-translate text-xl text-white block leading-snug font-extralight xl:text-[1.75rem]">
          We <span className="text-blue font-semibold">innovate</span> and{' '}
          <span className="text-blue font-semibold">empower</span>{' '}
          <br className="sm:hidden block" />
          industries worldwide
        </span>
      </div>
      {/* <div className="absolute inset-0 bg-white z-[10000] animate-reveal-banner" /> */}
    </section>
  )
}
