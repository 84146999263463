import React, {
  DispatchWithoutAction,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { CSSTransition } from 'react-transition-group'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { useNavigate } from 'react-router-dom'
import { useLanguage } from 'context/languageContext'
import { TextNavAnchor, TextNavbar } from './links'

gsap.registerPlugin(ScrollTrigger)

interface Dropdown {
  title: string
  index?: number
  toggle: DispatchWithoutAction
  options: {
    text: string
    href: string
  }[]
}

export default function DropdownMobileView({
  title,
  options,
  index,
  toggle,
}: Dropdown) {
  const [mobileNav, toggleMobileNav] = useReducer((s) => !s, false)
  const [isNavFixed, setisNavFixed] = useState(false)
  const [isOpen, toggleState] = useReducer((s) => !s, false)
  const { dropdownText, language } = useLanguage()

  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const toggleDropdown = () => {
    setIsOpenMenu(!isOpenMenu)
  }
  const dropdownRef = useRef<any>()
  const solutionsRef = useRef<any>()

  const navigate = useNavigate()
  const goToContact = () => {
    navigate('/contact-us')
  }

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [mobileNav])

  return (
    <li className="relative">
      <button
        ref={solutionsRef}
        onClick={toggleDropdown}
        type="button"
        className="dropdown-toggle text-black 2xl:text-base text-sm border-none  w-full flex justify-between py-2 opacity-80"
      >
        <span className="sg-translate">{title}</span>

        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          className={`w-5 transition-all duration-300 ${
            isOpenMenu ? '-rotate-90' : ''
          } `}
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
        </svg>
      </button>

      <CSSTransition
        in={isOpenMenu}
        timeout={600}
        unmountOnExit
        onEnter={(node: any) =>
          gsap.fromTo(
            node,
            { height: 0, overflow: 'hidden' },
            { height: 'auto', duration: 0.3 },
          )
        }
        onExiting={(node: any) => gsap.to(node, { height: 0, duration: 0.3 })}
      >
        <div ref={dropdownRef}>
          <button
            type="button"
            onClick={() => {
              setIsOpenMenu(!isOpenMenu)
              toggle()
            }}
            className="flex w-full flex-col gap-3 mt-2 rounded-sm"
          >
            {options.map((item) => {
              return (
                <TextNavbar
                  key={item.text}
                  color="dark"
                  href={item.href}
                  text={dropdownText[language][item.text]}
                  className="whitespace-nowrap px-4"
                  size="small"
                />
              )
            })}
          </button>
        </div>
      </CSSTransition>
    </li>
  )
}
