import React, { useState, useRef, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import {
  slidesA,
  slidesB,
  slidesC,
  slidesD,
  slidesE,
  slidesF,
  type ISlide,
  type IAccordion,
} from './applicationData'

export default function Application() {
  return (
    <section
      className="blade-top-padding-xl blade-bottom-padding-xl bg-blueDark 
    md:bg-[url(assets/general_nonwoven/solutions/bg.png)] bg-no-repeat bg-right-top"
    >
      <div className="md:px-6 lg:px-10 xl:px-16 2xl:px-24">
        <div className="w-container text-white flex flex-col gap-4 md:gap-6 xl:gap-8 ">
          <h2 className="sg-translate">
            Nonwoven solutions for <br className="md:block hidden" /> every
            application
          </h2>
          <h5 className="sg-translate max-w-xl text-white font-regular pr-5 md:pr-0">
            Not all businesses are the same, but so are the solutions we
            provide. At Supreme, we strategically craft bespoke products for
            your specific needs, leveraging our material know-how and
            cutting-edge technology.
          </h5>
        </div>
      </div>

      <div className="blade-top-padding-sm md:px-6 lg:px-10 xl:px-16 2xl:px-24">
        <div className="lg:grid grid-cols-7 w-container py-3 lg:py-4 border-b-1 border-white border-opacity-50 ">
          <div className="col-start-1 col-end-2">
            <h5 className="sg-translate text-white text-opacity-60 font-light">
              Industries{' '}
              <span className="lg:hidden inline">and their Applications</span>
            </h5>
          </div>
          <div className="col-start-3 col-end-8 lg:block hidden -ml-4">
            <h5 className="sg-translate text-white text-opacity-60 font-light">
              Application
            </h5>
          </div>
        </div>

        <div className="grid">
          <Accordion
            title="Healthcare & Medical"
            subtitle="Precision engineered nonwovens for face masks, injury care – ensuring safety and comfort."
            accordionSlides={slidesA}
          />
          {/* <Accordion
            title="Hygiene"
            subtitle="Nonwoven for diapers, adult incontinence products, feminine hygiene products, and baby wipes."
            accordionSlides={slidesB}
          /> */}
          <Accordion
            title="Automotive"
            subtitle="Advanced nonwoven materials for cabin and engine air filtration, interior trims and acoustic insulation felts - enhancing vehicle performance and passenger experience."
            accordionSlides={slidesC}
          />
          <Accordion
            title="Agriculture Technology"
            subtitle="Eco-friendly solutions like mulch mats, soil stabilizers, media for hydroponic farming - supporting sustainable and efficient agricultural practices."
            accordionSlides={slidesD}
          />
          <Accordion
            title="Filtration"
            subtitle="High-efficiency air, liquid, and solid filtration media for commercial, and industrial applications - ensuring safety and compliance."
            accordionSlides={slidesF}
          />
          <Accordion
            title="Apparel & Shoes"
            subtitle="Performance interlinings for apparel and shoes - fashion, sportswear, and functional; combining style with performance."
            accordionSlides={slidesF}
          />
          <Accordion
            title="Home Furnishings"
            subtitle="Nonwovens for made-ups, bedding, and flooring - offering comfort, durability, and aesthetic appeal."
            accordionSlides={slidesF}
          />

          {/* <Accordion
            title="Construction"
            subtitle="Geotextiles for soil stabilization, drainage materials, and roofing underlays."
            accordionSlides={slidesE}
          /> */}
          <Accordion
            title="Consumer Products"
            subtitle="Versatile nonwovens for everyday items like mops, cleaning tools, and hygiene products - enhancing convenience and quality of life."
            accordionSlides={slidesF}
          />
          <Accordion
            title="Technical Applications"
            subtitle="Durable materials for technical uses, including geotextiles, civil engineering materials, and industrial packaging."
            accordionSlides={slidesF}
          />
          <Accordion
            title="Electronics"
            subtitle="Specialized nonwovens for insulation, noise reduction, and component separation in high-tech equipment."
            accordionSlides={slidesF}
          />
          <Accordion
            title="Environmental Solutions"
            subtitle="Products for erosion control, and waste containment - contributing to environmental sustainability."
            accordionSlides={slidesF}
          />
        </div>
      </div>
    </section>
  )
}

function Accordion({
  title,
  subtitle,
  accordionSlides,
}: // accordionThumbs,
IAccordion) {
  const [isActive, setActive] = useState(false)
  const [isSmall, setIsSmall] = useState(window.innerWidth < 768)

  useEffect(() => {
    const updateIsSmall = () => {
      setIsSmall(window.innerWidth < 768)
    }

    window.addEventListener('resize', updateIsSmall)

    updateIsSmall()

    return () => {
      window.removeEventListener('resize', updateIsSmall)
    }
  }, [])

  useEffect(() => {
    if (isSmall) setActive(true)
    else setActive(false)
  }, [isSmall])

  const accordionRef = useRef<any>()
  useEffect(() => {
    const elem = accordionRef.current
    if (elem) {
      if (isActive) {
        elem.style.height = `${elem.scrollHeight}px`
        elem.style.opacity = 1
      } else {
        elem.style.height = '0px'
        elem.style.opacity = 0
      }
    }
  }, [isActive])

  return (
    <div className="gap-2 flex flex-col lg:grid border-b-1 border-white border-opacity-25 grid-cols-7 w-container-lg lg:w-container py-4 lg:py-6 max-w-[100vw] overflow-hidden">
      <div className="col-start-1 col-end-3">
        <h5 className="sg-translate text-white  md:block hidden">{title}</h5>
        <h4 className="sg-translate text-white  md:hidden block">{title}</h4>
      </div>
      <div className="col-start-3 col-end-8 pr-2 md:pr-4 xl:pr-16 pt-1.5 md:pt-0">
        <div>
          <h4
            className="sg-translate text-white font-light leading-snug md:block hidden"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <h5
            className="sg-translate text-white font-light leading-snug md:hidden block"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </div>

        {/* desktop view */}
        {/* <div
          className="overflow-hidden transition-all duration-300 ease-in-out md:block hidden"
          ref={accordionRef}
        >
          <div className="pt-6 md:pr-4 xl:pr-7 lg:pt-8 md:pl-1 overflow-hidden max-w-[100vw] select-none">
            <AccordionCarousel slides={accordionSlides} />
          </div>
        </div> */}

        {/* mobile view */}

        {/* <div className="overflow-hidden transition-all duration-300 ease-in-out md:hidden block ">
          <div className="pt-6 md:pr-4 xl:pr-7 lg:pt-8 md:pl-1 overflow-hidden max-w-[100vw] select-none">
            <AccordionCarousel slides={accordionSlides} />
          </div>
        </div> */}
      </div>
      {/* <div className="col-start-8 col-end-9 px-3 md:flex hidden justify-center">
        <button
          onClick={() => setActive((s) => !s)}
          type="button"
          className="h-8 w-8 md:w-10 md:h-10 xl:w-12 xl:h-12 grid place-content-center aspect-square fill-blue hover:fill-white focus-visible:fill-white hover:bg-blue focus-visible:bg-blue outline-none focus-visible:outline-none rounded-full border-1 border-solid border-blue focus-visible:border-blue hover:border-blue transition-all duration-200 ease-in-out"
        >
          <svg
            viewBox="0 0 16 25"
            className={`${
              isActive ? '-rotate-180' : ''
            } object-contain object-center transition-all duration-100 w-auto h-4 md:h-6`}
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1.08057C9 0.528282 8.55228 0.0805664 8 0.0805664C7.44772 0.0805664 7 0.528282 7 1.08057L9 1.08057ZM7.29289 23.7877C7.68342 24.1782 8.31658 24.1782 8.70711 23.7877L15.0711 17.4237C15.4616 17.0332 15.4616 16.4 15.0711 16.0095C14.6805 15.619 14.0474 15.619 13.6569 16.0095L8 21.6664L2.34315 16.0095C1.95262 15.619 1.31946 15.619 0.928932 16.0095C0.538407 16.4 0.538407 17.0332 0.928931 17.4237L7.29289 23.7877ZM7 1.08057L7 23.0806L9 23.0806L9 1.08057L7 1.08057Z"
              fill="inherit"
            />
          </svg>
        </button>
      </div> */}
    </div>
  )
}

function AccordionCarousel({ slides }: { slides: Array<ISlide> }) {
  const [instance, setInstance] = useState<any>(null)
  const [current, setCurrent] = useState(0)

  const swiperRef = useRef<any>(null)

  const getSwiperInstance = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      return swiperRef.current.swiper
    }
    return null
  }

  useEffect(() => {
    const ins = getSwiperInstance()
    setInstance(ins)
  }, [])
  const handlethumbClick = (update: number) => {
    instance.slideTo(update)
  }

  return (
    <>
      <div className="rounded-[20px] overflow-hidden">
        <Swiper
          speed={1000}
          spaceBetween={10}
          ref={swiperRef}
          onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
          className="cursor-grab"
        >
          {slides.map((elem, index: number) => {
            const { src, alt } = elem
            const key = `${index}`
            return (
              <SwiperSlide key={key}>
                <div className="h-fit l:h-[200px] md:h-[220px] 2xl:h-[270px] rounded-lg md:rounded-xl xl:rounded-2xl 2xl:rounded-[20px] overflow-hidden 2xl:w-[90%]">
                  <img
                    className="h-full w-full object-center object-cover"
                    src={src}
                    alt={alt}
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>

      <div className="py-3 md:py-4 lg:py-6 flex gap-1.5 md:gap-3 lg:gap-4">
        {slides.map((elem, index: number) => {
          const { thumbSrc, alt } = elem
          const key = `${index}`
          return (
            <button
              onClick={() => handlethumbClick(index)}
              key={key}
              type="button"
              className={`h-[40px] md:h-[80px] lg:h-[80px] xlg:h-[90px] w-[55px] md:w-[70px] lg:w-[85px] xlg:w-[110px]  
                rounded-md md:rounded-xl overflow-hidden transition-all duration-200 hover:opacity-90
                ${index === current ? 'opacity-100' : 'opacity-60 '}  
              `}
            >
              <img
                src={thumbSrc}
                alt={alt}
                className="w-full h-full object-cover object-center"
              />
            </button>
          )
        })}
      </div>
    </>
  )
}
