import React, { useEffect } from 'react'
import Banner from './banner'
import Application from './applications'
import Quality from './quality'
import InterliningExcellence from './interlining_excellence'
import TechSupport from './tech_support'
import Customers from './customers'
// import Brands from './brands'
import Certifications from './certifications'
import Subfooter from './subfooter'
import Solutions from './solutions'
import './apparel.css'
import WhyChooseUs from './whyChooseUs'

export default function Apparel() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Banner />
      <Application />
      <Quality />
      <Solutions />
      <InterliningExcellence />
      <TechSupport />
      <Customers />
      <WhyChooseUs />
      <Certifications />
      <Subfooter />
    </div>
  )
}
