import React from 'react'
import logo from 'assets/globals/logo.svg'
import { TextAnchor, Anchor, ExternalAnchor } from 'atoms/links'

export default function Footer() {
  return (
    <footer className="text-black bg-footer bg-no-repeat bg-cover bg-right-bottom blade-top-padding blade-bottom-padding border-t border-opacity-10 border-gray">
      <div className="w-container max-w-screen-lg">
        <div>
          <div>
            <Anchor href="/">
              <img
                src={logo}
                className="h-10 md:h-12 xlg:h-12"
                alt="Supreme logo"
                title="logo"
              />
            </Anchor>
          </div>

          <div className="md:flex hidden md:flex-row flex-col items-start justify-between md:pr-10 lg:gap-20 sm:gap-10 gap-4 2xl:mt-10 mt-8">
            <ul className="grid sm:gap-5 gap-3 text-black list-none">
              <li className="mb-2 sg-translate font-semibold uppercase text-opacity-90">
                Applications
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/applications/apparel"
                  text="Apparel"
                />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/applications/automotive"
                  text="Automotive"
                />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/applications/filtration"
                  text=" Filtration"
                />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/applications/customised-nonwoven"
                  text="Customised Nonwoven"
                />
              </li>
            </ul>
            <ul className="grid sm:gap-5 gap-3 text-black list-none">
              <li className="mb-2 sg-translate uppercase font-bold">Company</li>
              <li>
                <TextAnchor color="dark" href="/who-we-are" text="Who We Are" />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  href="/global-competency"
                  text="Global Compentency"
                />
              </li>
              <li>
                <TextAnchor color="dark" href="/innovation" text="Innovation" />
              </li>
              <li>
                <TextAnchor color="dark" href="/esg-impact" text="ESG Impact" />
              </li>
            </ul>
            <ul className="grid sm:gap-5 gap-3 text-black list-none">
              <li className="mb-2 sg-translate font-semibold uppercase text-opacity-90">
                MORE
              </li>
              <li>
                <TextAnchor color="dark" href="/contact-us" text="Contact Us" />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/careers"
                  text="Careers"
                />
              </li>
              {/* <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/privacy-policy"
                  text="Privacy Policy"
                />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/terms-and-conditions"
                  text="Terms and Conditions"
                />
              </li> */}
              {/* <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/sitemap"
                  text="Sitemap"
                />
              </li> */}
            </ul>
            <ul className="grid sm:gap-5 gap-3 text-black list-none">
              <li className="mb-2 sg-translate font-semibold uppercase text-opacity-90">
                Follow Us
              </li>
              {/* <li>
                <ExternalAnchor
                  className="block text-black text-opacity-70 hover:text-opacity-100 whitespace-nowrap"
                  href="/twitter"
                >
                   
                </ExternalAnchor>
              </li> */}
              <li>
                <ExternalAnchor
                  className="block text-black text-opacity-70 hover:text-opacity-100 whitespace-nowrap"
                  href="https://www.linkedin.com/company/supreme-group-company/"
                >
                  LinkedIn
                </ExternalAnchor>
              </li>
            </ul>
          </div>
          <div className="grid grid-cols-2 md:hidden items-start justify-between md:pr-10 lg:gap-20 sm:gap-10 gap-6 2xl:mt-10 mt-8">
            <ul className="grid sm:gap-5 gap-3 text-black list-none">
              <li className=" font-semibold uppercase text-opacity-90">
                Applications
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/applications/apparel"
                  text="Apparel"
                />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/applications/automotive"
                  text="Automotive"
                />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/applications/filtration"
                  text=" Filtration"
                />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/applications/customised-nonwoven"
                  text="Customized Nonwoven"
                />
              </li>
            </ul>
            <ul className="grid sm:gap-5 gap-3 text-black list-none">
              <li className="uppercase font-bold">Company</li>
              <li>
                <TextAnchor color="dark" href="/who-we-are" text="Who we are" />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  href="/global-competency"
                  text="Global Compentency"
                />
              </li>
              <li>
                <TextAnchor color="dark" href="/innovation" text="Innovation" />
              </li>
              <li>
                <TextAnchor color="dark" href="/esg-impact" text="ESG Impact" />
              </li>
            </ul>
            <ul className="grid sm:gap-5 gap-3 text-black list-none">
              <li className="font-semibold uppercase text-opacity-90">MORE</li>
              <li>
                <TextAnchor color="dark" href="/contact-us" text="Contact Us" />
              </li>
              <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/careers"
                  text="Careers"
                />
              </li>

              {/* <li>
                <TextAnchor
                  color="dark"
                  className="block whitespace-nowrap"
                  href="/sitemap"
                  text="Sitemap"
                />
              </li> */}
            </ul>
            <ul className="grid sm:gap-5 gap-3 text-black list-none">
              <li className="font-semibold uppercase text-opacity-90">
                Follow Us
              </li>

              <li>
                <ExternalAnchor
                  className="block text-black text-opacity-70 hover:text-opacity-100 whitespace-nowrap"
                  href="https://www.linkedin.com/company/supreme-group-company/"
                >
                  LinkedIn
                </ExternalAnchor>
              </li>
            </ul>
          </div>
        </div>

        <div className=" flex gap-3 md:flex-row flex-col  justify-between items-center blade-top-padding-xl">
          <h6 className="md:block hidden text-sm text-black whitespace-nowrap">
            ©2024. All Rights Reserved.
          </h6>
          <h6 className="md:block hidden text-sm text-black whitespace-nowrap">
            Supreme House, 110, 16th Road, Chembur, Mumbai – 400071.
          </h6>

          <h6 className="md:hidden block text-sm text-black whitespace-nowrap">
            ©2024. All Rights Reserved.
          </h6>
        </div>
      </div>
    </footer>
  )
}
