import React, { useRef, useState } from 'react'
import innovation from 'assets/home/possibilities/innovation.jpg'
import circiline from 'assets/home/possibilities/circiline.jpg'
import embracing from 'assets/home/possibilities/embracing.jpg'
import 'swiper/css'
import 'swiper/css/navigation'
import './home.css'

import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'
import Carousal from 'organisms/carousal'

const data = [
  {
    coverImage: embracing,
    title: 'Embracing digital transformation that goes beyond products',
    description:
      'Our pillars of technology development, system integration, and process engineering drive innovation, streamline operations, and ensure long-term success in a dynamic market.',
  },
  {
    coverImage: innovation,
    title: 'A tradition of continuous innovation',
    description:
      'Innovation is in our DNA. We don’t settle for the status quo. Our teams are constantly pushing boundaries to bring you the products that are ahead of the curve, setting new industry standards.',
  },
  {
    coverImage: circiline,
    title: 'Powering industries with greener nonwoven solutions ',
    description:
      'From design to manufacturing, we pride ourselves on empowering industries with renewable, recyclable, and reclaimable nonwoven materials, positioning us at the forefront of innovation.',
  },
]
export default function Possibilities() {
  // const [current, setCurrent] = useState(0)
  const navigate = useNavigate()

  return (
    <div className="blade-top-padding-lg possibilities-wrapper blade-bottom-padding-lg">
      <div className="">
        <h2 className="sg-translate font-light text-center">
          Leading you into the <br className="md:block " />{' '}
          <span className="font-semibold text-blue">next era</span> of
          possibilities
        </h2>

        <Carousal data={data} height="h-44 2xl:h-52 min-[1800px]:h-44" />

        <div className="grid place-content-center pt3">
          <HeroBtn
            role="link"
            size="small"
            color="blueLight"
            text="How do we innovate?"
            onClick={() => navigate('/innovation')}
            type="button"
          />
        </div>
      </div>
    </div>
  )
}
