import React, { useEffect } from 'react'
import { useScrollTop } from 'hooks'

// import OurPeopleMobile from './our_people_mobile'
// import Solutions from './03_solutions'
// import OurPeopleDraft from './our_people_draft'
import './home.css'
// import { useNavigate } from 'react-router-dom'
import SolutionsOptions from 'screens/home/03_solutionsOptions'
import Banner from './01_banner'
import Partners from './02_partners'
import Possibilities from './possibilities'
import Journey from './journey'
import Ecosystem from './ecosystem'
import EcosystemMobile from './ecosystem-mobile'
import OurPeople from './our_people'
import LeadershipStats from './04_leadershipStats'

export default function PageHome() {
  const scrollTop = useScrollTop()

  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <div id="top-banner" className="bg-white homepage">
      <Banner />
      <Partners />
      <SolutionsOptions />
      {/* <Solutions /> // */}
      <div id="leadershipStats">
        <LeadershipStats />
      </div>

      <div className="lg:block hidden">
        <Ecosystem />
      </div>
      <div className="lg:hidden block">
        <EcosystemMobile />
      </div>
      <Journey />
      <Possibilities />
      <div className=" overflow-hidden">
        <OurPeople />
      </div>
    </div>
  )
}
