import React from 'react'
import interlining from 'assets/contact/divisons/interlining.jpg'
import automotive from 'assets/contact/divisons/automotive.jpg'
import bespoke from 'assets/contact/divisons/bespoke.jpg'
import filtration from 'assets/contact/divisons/filtration.jpg'

const tiles = [
  {
    title: 'Apparel',
    coverImage: interlining,
    email: 'terca-interlnings@supremegroup.co.in',
  },
  {
    title: 'Automotive',
    coverImage: automotive,
    email: 'auto@supremegroup.co.in',
  },
  {
    title: 'Filtration',
    coverImage: filtration,
    email: 'nowofilt@supremegroup.co.in',
  },
  {
    title: 'Customised Solutions',
    coverImage: bespoke,
    email: 'customised@supremegroup.co.in',
  },
]

type ITile = (typeof tiles)[0]
export default function Divisons() {
  return (
    <div className=" w-container-lg 2xl:w-container blade-top-padding-lg">
      <div className=" px-0 2xl:px-4 pt-4 md:pt-6 xl:pt-8 2xl:pt-10">
        <center className="sg-translate text-2xl lg:text-2xl xlg:text-[1.75rem] 2xl:text-[2rem] md:leading-snug lg:leading-snug 2xl:leading-snug font-medium">
          Business Units
        </center>
        <div className="grid  2xl:w-11/12  grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-6 md:gap-5 xl:gap-4 2xl:gap-6 mx-auto pt-4 md:pt-6 xl:pt-10 blade-bottom-padding">
          {tiles.map((elem, index: number) => {
            const key = `${index}`
            const { coverImage, title, email } = elem
            return (
              <article
                key={key}
                className="rounded-lg overflow-hidden mx-auto max-w-md w-full  border-1 pb-1 border-solid bg-white border-[#DADADA]"
              >
                <div className=" h-48 md:h-40 2xl:h-44">
                  <img
                    src={coverImage}
                    className="h-full w-full object-cover object-center"
                    alt={title}
                  />
                </div>
                <div className="flex flex-col gap-px 2xl:p-4 p-3 ">
                  <span className="sg-translate text-lg xl:text-xl font-semibold">
                    {title}
                  </span>
                  <a
                    href={`mailto:${email}`}
                    className="text-sm lg:text-base text-blueDark font-normal hover:underline focus-visible:underline underline-offset-4 decoration-from-font outline-none focus-visible:outline-none"
                  >
                    {email}
                  </a>
                </div>
              </article>
            )
          })}
        </div>
      </div>
    </div>
  )
}
