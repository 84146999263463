import React, { useEffect } from 'react'
import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'
import './error404.css'

export default function PageError404() {
  const navigate = useNavigate()
  const goToHome = () => {
    navigate('/')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="md:h-screen h-[80vh] grid gap-4 place-content-center bg-gradient-to-t from-blueShade  to-white">
      <h1 className="font-bold text-transparent bg-gradient-to-tr from-blueDark to-blueDark bg-clip-text px-3 uppercase leading-none text-center md:text-8xl text-6xl block ">
        404
      </h1>
      <h5 className="font-normal px-3 leading-none text-center block pt-2 md:pb-6 pb-2 ">
        The page you have requested doesn&apos;t exist.
      </h5>
      <div className="flex justify-center">
        <HeroBtn
          role="link"
          size="large"
          color="blueLight"
          text="Go to Homepage"
          onClick={goToHome}
          type="button"
        />
      </div>
    </div>
  )
}
