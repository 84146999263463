import React, { useEffect, useState } from 'react'
import cardA from 'assets/innovation/pointers/cardA.webp'
import cardB from 'assets/innovation/pointers/cardB.jpg'
import cardC from 'assets/innovation/pointers/cardC.webp'
import filterbag from 'assets/innovation/pointers/filter-bag.jpg'
import { useLanguage } from 'context/languageContext'

export default function Pointers() {
  const [openIndex, setIndex] = useState(10)

  const toggleReadMore = (update: number) => {
    if (update === openIndex) setIndex(10)
    else setIndex(update)
  }

  return (
    <section className="blade-top-padding-lg blade-bottom-padding-xl bg-blueShade">
      <div className="grid place-content-center gap-2 text-center md:w-11/12 mx-auto px-5  blade-bottom-padding-sm ">
        <h2 className="sg-translate font-normal leading-tight text-black  ">
          Innovation in action
        </h2>
        <h5 className="sg-translate">
          Showcasing breakthroughs across <br className="md:hidden block" /> our
          business lines.
        </h5>
      </div>
      <div className="grid lg:grid-cols-2 gap-y-10 gap-x-6 xl:gap-x-10 2xl:gap-x-20 w-container md:w-7/12 lg:w-10/12 xlg:w-9/12 2xl:w-8/12">
        {/* client changes */}
        {/* <Card
          className="md:min-h-[105px]"
          index={0}
          openIndex={openIndex}
          handleToggle={toggleReadMore}
          title="Rethinking embroidery backing for our customers"
          desc="In the ever-evolving world of textile and embroidery, Supreme has emerged as a game-changer with its innovative approach to embroidery backing. Traditionally, embroidery backing posed challenges in terms of the labour-intensive manual removal process, impacting production costs. In response, Terca introduced a water-soluble embroidery backing—a simple yet transformative solution."
          cover={cardA}
        /> */}
        {/* <Card
          className=""
          index={1}
          openIndex={openIndex}
          handleToggle={toggleReadMore}
          title="Enhancing automotive components with nonwoven innovation"
          desc="In a bid to elevate the driving experience, our team embarked on a mission to reimagine the automotive acoustic duct. Traditionally made using conventional materials, this
          component posed challenges in sound absorption. Seeking a solution, we turned to the versatility of nonwoven materials for better sound absorption."
          cover={cardB}
        /> */}
        <Card
          className=""
          index={2}
          openIndex={openIndex}
          handleToggle={toggleReadMore}
          title="Ink resistant interlining"
          desc="Addressing customer needs with our innovative ink resistant interlining solution. The interlining repels ink to ensure stain free garments maintaining its pristine appearance throughout their lifespan. This revolutionary product, which repels ink, not only maintains the garment's aesthetic appeal but also increases its durability and longevity."
          cover={cardC}
        />
        <Card
          className=""
          index={1}
          openIndex={openIndex}
          handleToggle={toggleReadMore}
          title="Composite nonwoven filter"
          desc="Traditional woven fiberglass filter bags provide excellent resistance to high temperatures; however, they often degrade rapidly when exposed to acidic or alkaline environments. This degradation leads to weakened structure, frequent failures, and a limited lifespan of only a few months. To address these issues, our cutting-edge composite nonwoven filter bags offer a superior solution. Designed for robustness and durability, they excel in harsh conditions and high-temperature baghouse applications. These innovative filter bags offer a significantly extended lifespan, significantly reducing downtime, production interruptions and maintenance costs."
          cover={filterbag}
        />
      </div>
    </section>
  )
}

function Card({
  title,
  cover,
  handleToggle,
  index,
  openIndex,
  className,
  desc,
}: {
  className: string
  index: number
  openIndex: number
  title: string
  handleToggle: (update: number) => void
  cover: string
  desc: string
}) {
  const [description, setDescription] = useState<{ [key: string]: string }>({
    en: desc,
    th: '',
    de: '',
    ja: '',
  })
  const { language } = useLanguage()

  useEffect(() => {
    if (index !== openIndex || language === 'en') {
      return
    }

    if (description.th) {
      return
    }

    function fetchLang() {
      const url = `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=ja,th,de&textType=html`

      fetch(url, {
        method: 'POST',
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_MS_KEY as string,
          'Content-Type': 'application/json; charset=UTF-8',
          'Ocp-Apim-Subscription-Region': process.env
            .REACT_APP_MS_REGION as string,
        },
        body: JSON.stringify([
          {
            Text: desc,
          },
        ]),
      })
        .then((response) => response.json())
        .then((res) => {
          // console.log('Res:', res)
          if (!Array.isArray(res)) {
            throw new Error('Response reading error')
          }
          const obj: { [key: string]: string } = {}
          res[0]?.translations?.forEach((elem: any) => {
            obj[elem.to] = elem.text
          })

          if (!obj.th || !obj.ja || !obj.de) {
            throw new Error('Fetch error')
          }

          setDescription(() => ({
            en: desc,
            ...obj,
          }))
          // console.log(obj)
        })
        .catch((error) => {
          console.error('Error during translation:', error)
        })
    }

    // console.log('fetching')
    fetchLang()
  }, [index, openIndex, language, desc, description])

  return (
    <article className="h-auto w-full flex flex-col">
      <div className="basis-[200px] h-[250px] bg-[#E6E4E2] rounded-t-2xl lg:rounded-t-2xl 2xl:rounded-t-2xl salient-pointer-card-img sm:basis-[250px] md:basis-auto flex-0 overflow-hidden ">
        <img
          src={cover}
          className={`h-full w-full object-cover object-center aspect-video ${
            index === 1 && 'mix-blend-multiply' // Remove this later if image changes for the card
          }`}
          alt={title}
        />
      </div>

      <div
        className={`block flex-1 bg-white ${
          openIndex === index
            ? 'flex-1'
            : 'flex-0 md:min-h-[190px] 2xl:min-h-[200px]'
        }  salient-pointer-card-content rounded-b-2xl lg:rounded-b-2xl 2xl:rounded-b-2xl overflow-hidden `}
      >
        <div className="pt-4 pb-6 md:py-4 2xl:py-6 px-4 lg:px-6 2xl:px-8">
          <h4 className="sg-translate font-semibold leading-snug md:leading-tight pr-3 pb-2 md:pb-4 ">
            {title}
          </h4>
          <div className={`w-full ${className} leading-snug `}>
            <span className="pr-3 text-sm md:text-base inline">
              {openIndex === index ? (
                <span className="sg-translate">{description[language]}</span>
              ) : (
                <span className="sg-translate">
                  {String(description[language])}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    </article>
  )
}
