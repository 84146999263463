import React from 'react'
import handFeel from 'assets/apparel/quality/hand-feel.svg'
import appearance from 'assets/apparel/quality/appearance.svg'
import performance from 'assets/apparel/quality/performance.svg'

export default function Quality() {
  return (
    <section className="bg-white blade-top-padding-lg blade-bottom-padding quality-wrapper">
      <div className="grid place-content-center place-items-center text-center blade-bottom-padding-sm gap-4 xl:gap-6 pt-3">
        <h2 className="sg-translate font-light text-black">
          Products tailored for
          <br />
          <span className="font-semibold">exceptional</span> quality
        </h2>
        <span className="sg-translate text-base md:text-lg xlg:text-xl text-center block  max-w-2xl xl:max-w-5xl mx-auto  px-5 lg:px-0  md:w-10/12">
          Leveraging our advanced research and development capabilities, we
          craft interlinings that perform under various treatments, finishes,
          and wash processes. Our products are engineered to compliment and
          enhance a wide range of fabrics and applications, ensuring quality
          that sets industry standards.
        </span>
        <div className="flex md:flex-row flex-col pt-6 lg:pt-6 xl:pt-6">
          <article className="transition-all duration-100 ease-in-out translate-y-4 md:translate-y-0 md:translate-x-6 h-48 lg:h-52 xl:h-60 xlg:h-64 2xl:h-[17rem] aspect-square border-1 border-solid border-blue border-opacity-20 grid place-content-center place-items-center gap-3 md:gap-6 xl:gap-8 rounded-full">
            <img
              className="scale-[0.85] md:scale-100 "
              src={handFeel}
              alt="delicate tocuh resembling touch of a hand"
            />
            <span className="sg-translate font-semibold text-center text-base xl:text-lg px-2">
              Hand feel
            </span>
          </article>
          <article className="transition-all duration-100 ease-in-out h-48 lg:h-52 xl:h-60 xlg:h-64 2xl:h-[17rem] aspect-square border-1 border-solid border-blue border-opacity-20 grid place-content-center place-items-center gap-3 md:gap-6 xl:gap-8 rounded-full">
            <img
              className="scale-[0.85] md:scale-100 w-fit"
              src={appearance}
              alt="good quality appearance"
            />
            <span className="sg-translate font-semibold text-center text-base xl:text-lg px-2">
              Appearance
            </span>
          </article>
          <article className="transition-all duration-100 ease-in-out md:translate-y-0 -translate-y-4 md:-translate-x-6 h-48 lg:h-52 xl:h-60 xlg:h-64 2xl:h-[17rem] aspect-square border-1 border-solid border-blue border-opacity-20 grid place-content-center place-items-center gap-3 md:gap-6 xl:gap-8 rounded-full">
            <img
              className=" scale-[0.85] md:scale-100 w-fit"
              src={performance}
              alt="industry-standard performance"
            />
            <span className="sg-translate font-semibold text-center text-base xl:text-lg px-2">
              Performance
            </span>
          </article>
        </div>
      </div>
    </section>
  )
}
