import cementLogo1 from 'assets/filtration/clients/cement/AmbujaCement.png'
import cementLogo2 from 'assets/filtration/clients/cement/BharathiCement.png'
import cementLogo3 from 'assets/filtration/clients/cement/BirlaCorporation.png'
import cementLogo4 from 'assets/filtration/clients/cement/Chettinad.png'
import cementLogo5 from 'assets/filtration/clients/cement/DalmiaBharat.png'
import cementLogo6 from 'assets/filtration/clients/cement/Heidelberg.png'
import cementLogo7 from 'assets/filtration/clients/cement/IndiaCements.png'
import cementLogo8 from 'assets/filtration/clients/cement/JKCement.png'
import cementLogo9 from 'assets/filtration/clients/cement/JSWCement.png'
import cementLogo11 from 'assets/filtration/clients/cement/NCL.png'
import cementLogo12 from 'assets/filtration/clients/cement/NationalCement.png'
import cementLogo13 from 'assets/filtration/clients/cement/Ramco.png'
import cementLogo14 from 'assets/filtration/clients/cement/UltraTech.png'

import OEMLogo1 from 'assets/filtration/clients/oem/Clair.png'
import OEMLogo2 from 'assets/filtration/clients/oem/Ecomak.png'
import OEMLogo3 from 'assets/filtration/clients/oem/ElexIndia.png'
import OEMLogo4 from 'assets/filtration/clients/oem/GEA.png'
import OEMLogo5 from 'assets/filtration/clients/oem/IFI.png'
import OEMLogo6 from 'assets/filtration/clients/oem/IntensiveFilter.png'
import OEMLogo7 from 'assets/filtration/clients/oem/Isgec.png'
import OEMLogo8 from 'assets/filtration/clients/oem/Rieco.png'
import OEMLogo9 from 'assets/filtration/clients/oem/SMSGroup.png'
import OEMLogo10 from 'assets/filtration/clients/oem/Thermax.png'
import OEMLogo11 from 'assets/filtration/clients/oem/UnitedConveyor.png'

import ferroAlloyLogo2 from 'assets/filtration/clients/ferro-alloy-&-smelter/AdityaBirlaAlluminium.png'
import ferroAlloyLogo3 from 'assets/filtration/clients/ferro-alloy-&-smelter/HindustanZinc.png'
import ferroAlloyLogo4 from 'assets/filtration/clients/ferro-alloy-&-smelter/IMFA.png'
import ferroAlloyLogo5 from 'assets/filtration/clients/ferro-alloy-&-smelter/MaithanAlloys.png'
import ferroAlloyLogo6 from 'assets/filtration/clients/ferro-alloy-&-smelter/Nalco.png'
import ferroAlloyLogo7 from 'assets/filtration/clients/ferro-alloy-&-smelter/VedantaAluminium.png'

import powerLogo1 from 'assets/filtration/clients/power/Adhunik.png'
import powerLogo2 from 'assets/filtration/clients/power/Balco.png'
import powerLogo3 from 'assets/filtration/clients/power/GMR.png'
import powerLogo4 from 'assets/filtration/clients/power/MaruitiClean.png'
import powerLogo5 from 'assets/filtration/clients/power/NTPC.png'
import powerLogo6 from 'assets/filtration/clients/power/ThermalPowertech.png'
import powerLogo7 from 'assets/filtration/clients/power/Vedanta.png'

import ironSteelLogo1 from 'assets/filtration/clients/iron-&-steel/AartiSteel.png'
import ironSteelLogo2 from 'assets/filtration/clients/iron-&-steel/BhushanSteel.png'
import ironSteelLogo3 from 'assets/filtration/clients/iron-&-steel/Boldrocchi.png'
import ironSteelLogo4 from 'assets/filtration/clients/iron-&-steel/EssarSteel.png'
import ironSteelLogo5 from 'assets/filtration/clients/iron-&-steel/JSWSteel.png'
import ironSteelLogo6 from 'assets/filtration/clients/iron-&-steel/JindalSaw.png'
import ironSteelLogo7 from 'assets/filtration/clients/iron-&-steel/JindalStainless.png'
import ironSteelLogo8 from 'assets/filtration/clients/iron-&-steel/JindalSteel.png'
import ironSteelLogo9 from 'assets/filtration/clients/iron-&-steel/TataSteel.png'

export const clientLogos = [
  {
    id: 'cement',
    title: 'Cement',
    icons: [
      { id: 1, logo: cementLogo1, alt: 'Ambuja Cement Logo' },
      { id: 2, logo: cementLogo2, alt: 'Bharathi Cement Logo' },
      { id: 3, logo: cementLogo3, alt: 'Birla Corporation Logo' },
      { id: 4, logo: cementLogo4, alt: 'Chettinad Logo' },
      { id: 5, logo: cementLogo5, alt: 'Dalmia Bharat Logo' },
      { id: 6, logo: cementLogo6, alt: 'Heidelberg Logo' },
      { id: 7, logo: cementLogo7, alt: 'India Cements Logo' },
      { id: 8, logo: cementLogo8, alt: 'JK Cement Logo' },
      { id: 9, logo: cementLogo9, alt: 'JSW Cement.png Logo' },
      { id: 11, logo: cementLogo11, alt: 'NCL Logo' },
      { id: 12, logo: cementLogo12, alt: 'National Cement Logo' },
      { id: 13, logo: cementLogo13, alt: 'Ramco Logo' },
      { id: 14, logo: cementLogo14, alt: 'UltraTech Logo' },
    ],
  },
  {
    id: 'ferro-allow-and-smelter',
    title: 'Ferro alloy & smelter',
    icons: [
      { id: 2, logo: ferroAlloyLogo2, alt: 'Hindal co Industries Logo' },
      { id: 3, logo: ferroAlloyLogo3, alt: 'Hindustan Zinc Logo' },
      { id: 4, logo: ferroAlloyLogo4, alt: 'IMFA Logo' },
      { id: 5, logo: ferroAlloyLogo5, alt: 'Maithan Alloys Logo' },
      { id: 6, logo: ferroAlloyLogo6, alt: 'Nalco Logo' },
      { id: 7, logo: ferroAlloyLogo7, alt: 'Vedanta Aluminium Logo' },
    ],
  },
  {
    id: 'iron-and-steel',
    title: 'Iron & steel',
    icons: [
      { id: 1, logo: ironSteelLogo1, alt: 'Aarti Steel Logo' },
      { id: 2, logo: ironSteelLogo2, alt: 'Bhushan Steel Logo' },
      { id: 3, logo: ironSteelLogo3, alt: 'Boldrocchi Logo' },
      { id: 4, logo: ironSteelLogo4, alt: 'Essar Steel Logo' },
      { id: 5, logo: ironSteelLogo5, alt: 'JSW Steel Logo' },
      { id: 6, logo: ironSteelLogo6, alt: 'Jindal Saw Logo' },
      { id: 7, logo: ironSteelLogo7, alt: 'Jindal Stainless Logo' },
      { id: 8, logo: ironSteelLogo8, alt: 'Jindal Steel Logo' },
      { id: 9, logo: ironSteelLogo9, alt: 'Tata Steel Logo' },
    ],
  },
  {
    id: 'power',
    title: 'Power',
    icons: [
      { id: 1, logo: powerLogo1, alt: 'Adhunik Logo' },
      { id: 2, logo: powerLogo2, alt: 'Balco Logo' },
      { id: 3, logo: powerLogo3, alt: 'GMR Logo' },
      { id: 4, logo: powerLogo4, alt: 'MaruitiClean Logo' },
      { id: 5, logo: powerLogo5, alt: 'NTPC Logo' },
      { id: 6, logo: powerLogo6, alt: 'Thermal Powertech Logo' },
      { id: 7, logo: powerLogo7, alt: 'Vedanta Logo' },
    ],
  },
  {
    id: 'oem',
    title: 'OEM',
    icons: [
      { id: 1, logo: OEMLogo1, alt: 'Clair Logo' },
      { id: 2, logo: OEMLogo2, alt: 'comak Logo' },
      { id: 3, logo: OEMLogo3, alt: 'ElexIndia Logo' },
      { id: 4, logo: OEMLogo4, alt: 'GEA Logo' },
      { id: 5, logo: OEMLogo5, alt: 'IFI Logo' },
      { id: 6, logo: OEMLogo6, alt: 'Intensive Filter Logo' },
      { id: 7, logo: OEMLogo7, alt: 'Isgec Logo' },
      { id: 8, logo: OEMLogo8, alt: 'Rieco Logo' },
      { id: 9, logo: OEMLogo9, alt: 'SMSGroup Logo' },
      { id: 10, logo: OEMLogo10, alt: 'Thermax Logo' },
      { id: 11, logo: OEMLogo11, alt: 'United Conveyor Logo' },
    ],
  },
]
