import React from 'react'
import Carousal from 'organisms/carousal'
import image1 from 'assets/automotive/use-cases/sound-absorption.jpg'
import image2 from 'assets/automotive/use-cases/thermal-management.jpg'
import image3 from 'assets/automotive/use-cases/lightweight-parts-2.jpg'
import image4 from 'assets/automotive/use-cases/emission-reduction.jpg'
import './automotive.css'

const data = [
  {
    coverImage: image1,
    title: 'Sound absorption',
    description:
      'Ensure a serene cabin environment and minimize external noise for an immersive driving experience.',
  },
  {
    coverImage: image2,
    title: 'Thermal management',
    description:
      'Optimize thermal characteristics aligned with the weight and cost requirements.',
  },
  {
    coverImage: image3,
    title: 'Lightweight parts',
    description:
      'Engineer lightweight parts to enhance the vehicle’s agility, fuel efficiency, and overall performance.',
  },
  {
    coverImage: image4,
    title: 'Emission reduction',
    description:
      'Develop components that contribute to lower emissions, reducing the carbon footprint of vehicles.',
  },
]

export default function UseCases() {
  return (
    <div className="blade-top-padding-lg use-cases-wrapper blade-bottom-padding">
      <div className=" lg:blade-bottom-padding-sm">
        <h2 className="sg-translate font-light text-center px-3">
          Engineered to fuel your{' '}
          <span className="text-blue font-bold">performance</span>
        </h2>

        <Carousal data={data} height="" />
      </div>
    </div>
  )
}
