import React from 'react'
import bgImage from 'assets/apparel/threadBg.png'

export default function InterliningExcellence() {
  return (
    <section className=" gap-3 bg-white ">
      <div className="blade-top-padding-xl blade-bottom-padding-xl blade-bottom-margin-sm">
        <div className="grid place-content-center place-items-center text-center blade-bottom-padding-sm">
          <h2 className="sg-translate font-light text-black px-3">
            Precision Bonding: The foundation of{' '}
            <br className="hidden sm:block" />
            <span className="font-semibold">interlining excellence</span>
          </h2>
        </div>

        <div className="px-3 ">
          <div className="webkit-rounded h-36 md:h-44 xl:h-52 2xl:h-[16rem] relative w-container mx-auto rounded-3xl overflow-hidden">
            {/* <div className="webkit-rounded d h-36 md:h-44 xl:h-52 2xl:h-[16rem] relative max-w-screen-xlg 2xl:max-w-[1600px] px-3 w-[98%] md:w-11/12 mx-auto rounded-3xl overflow-hidden"> */}
            <div className="absolute -left-60 -right-60 -top-10 -bottom-10 h-96 animate-spin-slow grid  place-content-center">
              <img
                className="scale-150 h-[100vw] w-[100vw] blur-sm"
                src={bgImage}
                alt="background of the thread"
                aria-hidden
              />
            </div>
            <div className=" grid place-content-center h-full relative">
              <h3 className="sg-translate font-semibold md:block hidden text-center px-4 text-white">
                Temperature x Pressure x Time = Appearance + Resilience +
                Controlled elasticity
              </h3>
              <h4 className="sg-translate md:hidden block font-semibold text-center px-4  text-white">
                Temperature x Pressure x Time = Appearance + Resilience +
                Controlled elasticity
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
