import { HeroBtn } from 'atoms/buttons'
import React from 'react'
import './home.css'
import image from 'assets/home/team.webp'
import imageMobile from 'assets/home/team-mobile.png'
import { useNavigate } from 'react-router-dom'

export default function Journey() {
  const navigate = useNavigate()

  const onNavigate = () => {
    navigate('/who-we-are')
  }

  return (
    <div className="journey-wrapper h-[700px] sm:h-[1200px] md:h-[500px] lg:h-screen xl:min-h-[640px] 2xl:min-h-screen relative">
      <div className="w-full bg-blueDark bg-opacity-70 md:hidden h-full object-cover ">
        <img
          className="w-full md:h-full md:object-cover xl:min-h-[600px]"
          src={imageMobile}
          alt="team created S shape"
        />
      </div>
      <div
        className="absolute -bottom-0 w-full h-full bg-gradient flex flex-col justify-end pb-12 md:pb-0 
        md:justify-center items-start
       "
      >
        <div className="z-10 w-full px-4 md:w-container relative">
          <h2 className="sg-translate text-white font-light 2xl:pb-12">
            Our journey towards <br className="md:block " /> excellence{' '}
            <span className="font-semibold">since 1986</span>
          </h2>
          {/* <h3 className="sg-translate text-white lg:hidden block font-light pb-5">
            Our journey towards <br className="hidden sm:block" /> excellence{' '}
            <span className="font-semibold">since 1986</span>
          </h3> */}
          <div className="2xl:blade-top-padding-xl pt-8 md:pt-12 lg:pt-24 xl:pt-16 xlg:pt-20 2xl:pt-32">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className=" h-8 lg:h-8 2xl:h-14"
              viewBox="0 0 68 51"
              fill="none"
            >
              <path
                d="M20.5 18.8896C22.5 18.8896 24.9333 20.1131 27.8 22.5602C30.7333 24.9412 32.2 28.3472 32.2 32.7785C32.2 37.1435 30.5667 41.1449 27.3 44.7824C24.0333 48.42 20.1333 50.2388 15.6 50.2388C11.0667 50.2388 7.33333 48.9491 4.4 46.3697C1.46667 43.7904 0 39.6568 0 33.9689C0 28.2811 2.63333 21.7665 7.9 14.4253C13.2333 7.08401 19.3333 2.35517 26.2 0.23877C28.4667 1.42925 29.7 3.24803 29.9 5.69512C27.4333 7.61311 25.2667 9.99406 23.4 12.838C21.6 15.6158 20.6333 17.6329 20.5 18.8896ZM56.3 18.8896C58.3 18.8896 60.7333 20.1131 63.6 22.5602C66.5333 24.9412 68 28.3472 68 32.7785C68 37.1435 66.3667 41.1449 63.1 44.7824C59.8333 48.42 55.9333 50.2388 51.4 50.2388C46.8667 50.2388 43.1333 48.9491 40.2 46.3697C37.3333 43.7904 35.9 40.252 35.9 35.7546C35.9 31.2573 37.0667 26.5615 39.4 21.6673C41.8 16.707 45.0333 12.2427 49.1 8.27448C53.1667 4.30623 57.5 1.62766 62.1 0.23877C64.3667 1.49538 65.6 3.31417 65.8 5.69512C63.2667 7.61311 61.0667 9.99406 59.2 12.838C57.4 15.6158 56.4333 17.6329 56.3 18.8896Z"
                fill="#40B7E9"
              />
            </svg>
            <h4 className="sg-translate font-semibold text-white pb-4 md:pb-5 pt-3 md:pt-6 max-w-xs xl:max-w-[320px] 2xl:max-w-md">
              Supreme’s growth today finds its roots in the integrity of our
              actions and the honesty of our intentions.
            </h4>
            <h6 className="font-semibold text-sm md:text-base text-white -mt-1">
              - Mohan Kavrie
            </h6>
            <div className="blade-top-padding-sm mt-3 lg:mt-0 2xl:-mt-2">
              <button
                type="button"
                onClick={onNavigate}
                className="flex gap-1 group relative outline-none !bg-transparent rounded-full transition-all duration-700 ease-in-out hover:text-black focus:text-black hover:!bg-white focus:!bg-white text-white stroke-dark group-hover:stroke-white font-semibold group-hover:text-dark !group-hover:bg-white group-hover:border-transparent border border-white lg:text-base text-sm px-6 lg:px-7 py-3"
              >
                <span className="sg-translate">Discover our story</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:absolute top-0 w-full h-full">
        <img
          alt="team created S shape"
          className="w-full h-full object-cover"
          src={image}
        />
      </div>
    </div>
  )
}
