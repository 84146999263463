import React, { useState } from 'react'
import { Navigation, Pagination } from 'swiper'
import { SwiperSlide, Swiper } from 'swiper/react'
import 'swiper/css'

import womensWear from 'assets/apparel/applications/womenswear.jpg'
import mensWear from 'assets/apparel/applications/menswear.jpg'
import kidsWear from 'assets/apparel/applications/kidswear.jpg'
import formalWear from 'assets/apparel/applications/formalwear.jpg'
import activeWear from 'assets/apparel/applications/activewear.jpg'
import footWear from 'assets/apparel/applications/footwear.jpg'
import thermalInsulation from 'assets/apparel/applications/thermal-insulation.jpg'
import homeFurnishings from 'assets/apparel/applications/home-furnishings.jpg'
import fashionAccessories from 'assets/apparel/applications/fashion-accessories.jpg'
import { SliderBtn } from 'screens/filtration/applicationExpertise'

const slides = [
  {
    cover: womensWear,
    title: 'Womenswear',
    isSmall: false,
    isStatic: false,
  },
  {
    cover: mensWear,
    title: 'Menswear',
    isSmall: true,
    isStatic: true,
  },
  {
    cover: kidsWear,
    title: 'Kidswear',
    isSmall: true,
    isStatic: true,
  },
  {
    cover: formalWear,
    title: 'Formalwear',
    isSmall: false,
    isStatic: false,
  },
  {
    cover: activeWear,
    title: 'Activewear',
    isSmall: true,
    isStatic: true,
  },
  {
    cover: footWear,
    title: 'Footwear',
    isSmall: false,
    isStatic: false,
  },
  {
    cover: thermalInsulation,
    title: 'Thermal insulation',
    isSmall: false,
    isStatic: false,
  },
  {
    cover: homeFurnishings,
    title: 'Home furnishings',
    isSmall: true,
    isStatic: true,
  },
  {
    cover: fashionAccessories,
    title: 'Fashion accessories',
    isSmall: false,
    isStatic: false,
  },
]

export default function Application() {
  const [current, setCurrent] = useState(0)
  return (
    <section className="bg-white blade-top-padding-lg pb-5 xl:pb-8">
      <div className="grid place-content-center pt-3 md:pt-5 place-items-center text-center blade-bottom-padding-sm gap-4 xl:gap-6">
        <h2 className="sg-translate font-light text-black px-3 mb-2">
          Solutions for every
          <span className="font-semibold"> application</span>
        </h2>
        {/* <h3 className="font-light text-black leading-tight  md:hidden block">
          Solutions for every <br />
          <span className="font-semibold"> application</span>
        </h3> */}
      </div>

      <div className="relative">
        <div className="max-w-[1700px] mx-auto w-full">
          <button
            disabled={current === 0}
            type="button"
            className="appearal-applications-prev-btn 
            absolute top-1/2 -translate-y-[80%] z-10 left-[2%] 
            opacity-100 disabled:opacity-0 disabled:pointer-events-none aspect-square border-1 border-white border-solid rounded-full hover:bg-white hover:text-black transition-all duration-200 ease-in-out focus-visible:bg-white focus-visible:text-black focus-visible:outline-white outline-offset-2 outline outline-transparent text-white font-semibold 2xl:text-sm md:h-14 lg:h-16 xl:h-20 2xl:h-24 md:block hidden"
          >
            <span className="sg-translate">Prev</span>
          </button>
          <button
            disabled={slides.length - 1 === current}
            type="button"
            className="appearal-applications-next-btn 
              absolute top-1/2 -translate-y-[80%] z-10 right-[2%]
              opacity-100 disabled:opacity-0 disabled:pointer-events-none aspect-square border-1 border-white border-solid rounded-full hover:bg-white hover:text-black transition-all duration-200 ease-in-out focus-visible:bg-white focus-visible:text-black focus-visible:outline-white outline-offset-2 outline outline-transparent text-white font-semibold 2xl:text-sm md:h-14 lg:h-16 xl:h-20 2xl:h-24  md:block hidden"
          >
            <span className="sg-translate">Next</span>
          </button>
        </div>
        <div className="md:pl-0">
          <Swiper
            slidesPerView={1}
            breakpoints={{
              480: {
                slidesPerView: 'auto',
              },
              768: {
                slidesPerView: 'auto',
                slidesPerGroup: 2,
                speed: 1500,
              },
            }}
            spaceBetween={16}
            speed={1200}
            modules={[Navigation]}
            navigation={{
              prevEl: '.appearal-applications-prev-btn',
              nextEl: '.appearal-applications-next-btn',
            }}
            onSlideChange={(swiper) => setCurrent(swiper.realIndex)}
            className="h80 lg:h-[400px] xl:h-[500px] xlg:h-[600px] 2xl:h-[700px] cursor-grab px-3"
          >
            {slides.map((elem, index: number) => {
              const { cover, title, isSmall } = elem
              const key = `${index}`
              return (
                <SwiperSlide
                  key={key}
                  className={`h-80 lg:h-[400px] xl:h-[500px] xlg:h-[600px] 2xl:h-[700px] last:pr-3 last:xl:pr-5 flex flex-col gap-1 ${
                    isSmall
                      ? 'w-[300px] lg:w-[360px] xl:w-[420px] xlg:w-[480px] 2xl:w-[530px]'
                      : 'w-[410px] md:w-[520px] lg:w-[580px] xl:w-[680px] xlg:w-[750px] 2xl:w-[835px]'
                  } `}
                >
                  <div className="flex-1 rounded-2xl overflow-hidden">
                    <img
                      src={cover}
                      alt={title}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div className="flex-0 text-lg lg:text-xl xl:text-2xl font-medium pl6 text-center md:text-left md:pl-8 xl:pl-10 pt-4">
                    <span className="sg-translate">{title}</span>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div
            className="flex md:hidden z-10 -top-[1%] md:-top-[2%] right-6 lg:right-16 xl:right-[12%] justify-end w-container-sm 
                gap-5 pt-6 pl-5 md:pl-0 md:mb-6"
          >
            <SliderBtn
              disable={current === 0}
              direction="left"
              slideClass="appearal-applications-prev-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
                  fill="white"
                />
              </svg>
            </SliderBtn>
            <SliderBtn
              disable={slides.length - 1 === current}
              direction="right"
              slideClass="appearal-applications-next-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
                  fill="white"
                />
              </svg>
            </SliderBtn>
          </div>
        </div>
      </div>
    </section>
  )
}
