import React, { ReactNode, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import innovationAtWork from 'assets/careers/life-at-supreme/innovation_at_work.jpg'
import pathwaysToGrowth from 'assets/careers/life-at-supreme/pathways_to_growth.jpg'
import commitmentToLearning from 'assets/careers/life-at-supreme/commitment_to_learning.jpg'
import safetyOnPriority from 'assets/careers/life-at-supreme/safety_on_priority.jpg'
import { Navigation } from 'swiper'
import 'swiper/css/navigation'

const slides = [
  {
    title: 'Innovation at work',
    desc: 'We foster an environment where creativity thrives and cutting-edge ideas become realities. Be at the forefront of transforming our industry.',
    cover: innovationAtWork,
  },
  {
    title: 'Commitment to learning',
    desc: 'Embark on a journey of continuous growth with opportunities to learn, adapt, and excel in your field. Knowledge is our power.',
    cover: commitmentToLearning,
  },
  {
    title: 'Safety on priority',
    desc: 'Safety is integral to our operations. We prioritize a secure and healthy workplace, where everyone is protected and empowered to speak up.',
    cover: safetyOnPriority,
  },
  {
    title: 'Pathways to growth',
    desc: 'Your growth fuels our progress. We offer dynamic career paths that recognize your talents and ambition, aligning them with our collective goals.',
    cover: pathwaysToGrowth,
  },
]

export default function LifeAtSupreme() {
  const [current, setCurrent] = useState(0)
  return (
    <div className="bg-white blade-top-padding-lg md:blade-top-padding-lg md:blade-bottom-padding-sm blade-bottom-padding-lg ">
      <div className=" w-container 2xl:w-container flex justify-between flex-col gap-y-2 gap-x-10 xl:flex-row  blade-bottom-padding-sm">
        <h2 className="sg-translate font-light flex-1 px-1 md:px-0">
          Life at <span className="font-semibold">Supreme</span>
        </h2>
        <div className=" max-w-2xl 2xl:max-w-[800px] basis-0 grow-[2] shrink pr-7 md:pr-0 flex flex-col gap-3 px-1 md:px-0">
          <span className="sg-translate font-normal leading-normal block text-base md:text-base xl:text-lg  2xl:text-[22px]">
            Our success story is built on a foundation of care, from the moment
            you join our team to your daily work and personal growth. Here,
            individual achievements blend seamlessly into our larger mission,
            advancing material science and manufacturing for a better world.
          </span>
          <span className="sg-translate font-normal leading-normal block text-base md:text-base xl:text-lg  2xl:text-[22px]">
            We champion a culture where collaboration fuels breakthroughs and
            where your contribution is pivotal in our shared quest for
            excellence. Join us, and be part of a dynamic team that&apos;s not
            just shaping the future of our industry, but also driving progress
            for the greater good.
          </span>
        </div>
      </div>
      <div className=" ml-auto blade-bottom-padding">
        <Swiper
          centeredSlides
          slidesPerView={1.1}
          loop={false}
          navigation={{
            prevEl: '.life-carousel-prev-btn',
            nextEl: '.life-carousel-next-btn',
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            1024: {
              centeredSlides: false,
              slidesPerView: 2.1,
            },
            1280: {
              slidesPerGroup: 3,
              centeredSlides: false,
              spaceBetween: 20,
              slidesPerView: 2.6,
            },
            1440: {
              centeredSlides: false,
              slidesPerGroup: 3,
              slidesPerView: 2.3,
              spaceBetween: 24,
            },
          }}
          className="cursor-grab md:pl-[6%] lg:pl-[9.5%] min-[1800px]:pl-[10.5%]"
          onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
          modules={[Navigation]}
          spaceBetween={10}
          speed={1200}
        >
          {slides.map((elem, index: number) => {
            const { cover, title, desc } = elem
            const key = `${index}`
            return (
              <SwiperSlide key={key} className="py-2 last:pr-4">
                <Slide title={title} desc={desc} cover={cover} />
              </SwiperSlide>
            )
          })}
        </Swiper>

        <div className=" gap-4 pt-3 xl:gap-5 md:pt-5 xl:pt-8 pl-5 md:pl-0 w-container-sm flex justify-end">
          <SliderBtn
            slideClass="life-carousel-prev-btn"
            isDisabled={current === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
          <SliderBtn
            isDisabled={current === slides.length - 1}
            slideClass="life-carousel-next-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
        </div>
      </div>
    </div>
  )
}

function SliderBtn({
  slideClass,
  isDisabled,
  children,
}: {
  slideClass: string
  isDisabled: boolean
  children: ReactNode
}) {
  return (
    <button
      type="button"
      className={`${slideClass} disabled:opacity-50 disabled:cursor-not-allowed place-items-center rounded-full grid place-content-center h-10 lg:h-12 2xl:h-16 aspect-square  transition-all duration-500 ease-in-out bg-blue focus:outline-blueDark outline-1 outline-sollid outline-transparent ${
        isDisabled
          ? 'cursor-not-allowed'
          : 'bg-opacity-100 enabled:hover:bg-blueDark '
      } `}
    >
      {children}
    </button>
  )
}

function Slide({
  title,
  cover,
  desc,
}: {
  title: string
  desc: string
  cover: string
}) {
  return (
    <article className="2xl:rounded-[20px] group">
      <div className="relative ">
        <div className="bg-gradient-to-b from-transparent to-black h-64 md:h-72 lg:h-80 xlg:h-[26rem] rounded-xl lg:rounded-2xl overflow-hidden  2xl:h-[28.75rem]">
          <img
            className=" h-64 md:h-72 lg:h-80 xlg:h-[26rem] 2xl:h-[28.75rem] w-full object-cover object-center"
            src={cover}
            alt={title}
          />
        </div>

        <div
          className="absolute rounded-[18px] hidden md:block inset-0 group-hover:opacity-100 lg:opacity-0 transition-all duration-[900ms] ease-in-out"
          style={{
            backgroundImage:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #006ABC 85.34%)',
          }}
        />
        <div
          className="absolute inset-0 group-hover:opacity-0 lg:opacity-100 transition-all duration-[900ms] ease-in-out md:block hidden rounded-xl lg:rounded-2xl"
          style={{
            backgroundImage:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%,  rgba(0, 0, 0, 0.8) 90.34%)',
          }}
        />

        <div className=" relative md:absolute inset-0 flex flex-col justify-end px-3 pl-0 pt-4 sm:px-4 md:px-6 xl:px-8 2xl:px-[40px] pb-2 md:pb-8 xl:pb-10 group-hover:pb-4 transition-all duration-500 ease-in-out ">
          <span className="sg-translate font-semibold text-black md:text-white pb-1 md:pb-2 block text-xl 2xl:text-2xl">
            {title}
          </span>

          <div className="lg:h-0 overflow-hidden lg:group-hover:h-28 xl:group-hover:h-24  2xl:group-hover:h-24 transition-all duration-500 ease-in-out">
            <span className="sg-translate font-normal text-sm md:text-base 2xl:text-lg lg:opacity-0 group-hover:opacity-100 transition-all duration-300  ease-in-out text-black md:text-white leading-snug w-11/12  block">
              {desc}
            </span>
          </div>
        </div>
      </div>
    </article>
  )
}
