import React from 'react'
import PageBanner from 'organisms/pageBanner'
import bannerPoster from 'assets/videos/who-we-are.webp'
import bannerVideo from 'assets/videos/who-we-are.mp4'

export default function Banner() {
  return (
    <PageBanner
      bgVideo={bannerVideo}
      videoPoster={bannerPoster}
      title="True passion for <strong class='font-semibold text-blue'>nonwoven</strong>"
      subtitle="Delivering the highest quality nonwoven solutions that empower <br class='md:block hidden' /> industries, protect the environment, and enhance lives."
    />
  )
}
