import React, { ReactNode, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Sitemap() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="min-h-screen bg-gradient-to-b from-white balde-top-padding blade-bottom-padding to-slate-200">
      <div className="w-container blade-top-padding blade-top-padding">
        <div className="text-center blade-top-padding blade-bottom-padding grid place-content-center place-items-center w-container">
          <h2 className="font-semibold">Sitemap</h2>
        </div>

        <div className="pb-10 max-w-2xl mx-auto ">
          <ul className="list-disc flex  pl-6 flex-col gap-1.5 text-lg  ">
            <li>
              <PageLink to="/" active>
                Home
              </PageLink>
            </li>
            <li>
              <PageLink to="/global-competency" active>
                Global Competency
              </PageLink>
            </li>
            <li>
              <PageLink to="/innovation" active>
                Innovation
              </PageLink>
            </li>
            <li>
              <PageLink to="/esg-impact" active>
                ESG Impact
              </PageLink>
            </li>
            <li>
              <PageLink to="/careers" active>
                Careers
              </PageLink>
            </li>
            <li>
              <PageLink to="/contact-us" active>
                Contact us
              </PageLink>
            </li>
          </ul>
        </div>

        <div className="pb-10 max-w-2xl mx-auto ">
          <h5 className="font-semibold pb-2">Application</h5>
          <ul className="list-disc flex  pl-6 flex-col gap-1.5 text-lg  ">
            <li>
              <PageLink to="/applications/apparel" active>
                Apparel
              </PageLink>
            </li>
            <li>
              <PageLink to="/applications/automotive" active>
                Automotive
              </PageLink>
            </li>

            <li>
              <PageLink to="/applications/filtration" active>
                Filtration
              </PageLink>
            </li>

            <li>
              <PageLink to="/applications/customised-nonwoven" active>
                Customized Nonwoven
              </PageLink>
            </li>
          </ul>
        </div>

        {/* <div className="pb-10 max-w-2xl mx-auto ">
          <h5 className="font-semibold pb-2">Innovation</h5>
          <ul className="list-disc flex  pl-6 flex-col gap-1.5 text-lg  ">
            <li>
              <PageLink to="/">Research & Development</PageLink>
            </li>

            <li>
              <PageLink to="/">Filtration</PageLink>
            </li>

            <li>
              <PageLink to="/">Bespoke Solutions</PageLink>
            </li>
          </ul>
        </div> */}

        <div className="pb-10 max-w-2xl mx-auto ">
          <h5 className="font-semibold pb-2">Company</h5>
          <ul className="list-disc flex  pl-6 flex-col gap-1.5 text-lg  ">
            <li>
              <PageLink to="/global-competency" active>
                Global Competency
              </PageLink>
            </li>

            <li>
              <PageLink to="/who-we-are" active>
                Who-we-are
              </PageLink>
            </li>
          </ul>
        </div>
        <div className="pb-10 max-w-2xl mx-auto ">
          <h5 className="font-semibold pb-2">Others</h5>
          <ul className="list-disc flex  pl-6 flex-col gap-1.5 text-lg">
            {/* <li>
              <PageLink to="/coming-soon">Privacy Policy</PageLink>
            </li>
            <li>
              <PageLink to="/coming-soon">Terms & Conditions</PageLink>
            </li> */}

            <li>
              <PageLink to="/sitemap" active>
                Sitemap
              </PageLink>
            </li>
            <li>
              <PageLink to="/404" active>
                404
              </PageLink>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

function PageLink({
  to,
  children,
  active = false,
}: {
  children: ReactNode
  to: string
  active?: boolean
}) {
  if (active) {
    return (
      <Link
        target="_blank"
        className="text-opacity-100  hover:text-blueDark underline hover:underline focus-visible:text-blueDark transition-all duration-300 ease-in-out underline-offset-4 decoration-from-font"
        to={to}
      >
        {children}{' '}
      </Link>
    )
  }
  return (
    <Link
      onClick={(event) => event.preventDefault()}
      className="text-opacity-50 text-black cursor-not-allowed  underline-offset-4 decoration-from-font"
      to={to}
    >
      {children}
    </Link>
  )
}
