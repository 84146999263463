import React, { useEffect, useRef, useState, useCallback } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import ecosystem_one from 'assets/home/ecosystem/1.webp'
import ecosystem_two from 'assets/home/ecosystem/2.webp'
import ecosystem_three from 'assets/home/ecosystem/3.webp'

gsap.registerPlugin(ScrollTrigger)

const data = [
  {
    title: 'Cutting-edge research & development',
    cover: ecosystem_one,
  },
  {
    title: 'Advanced manufacturing processes',
    cover: ecosystem_two,
  },
  {
    title: 'End-to-end control',
    cover: ecosystem_three,
  },
]

export default function Ecosystem() {
  const wrapper = useRef<any>()
  const indicator = useRef<any>()
  const [scrollController, setScrollController] = useState<any>(null)
  const [activeSlide, setActiveSlide] = useState(0)
  const [instance, setInstance] = useState<any>(null)

  useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth >= 768) {
      const ctx = gsap.context(() => {
        const anim = gsap.from(wrapper.current, {})
        const controller = ScrollTrigger.create({
          animation: anim,
          trigger: wrapper.current,
          start: 'top top',
          end: '+300% bottom',
          scrub: true,
          pin: true,
          markers: false,
          onUpdate: (e: any) => {
            const progress: number = +(e.progress * 90).toFixed(2) ?? 0
            progressHandler(progress)
            // console.log(progress)
          },
        })
        setScrollController(controller)
      })
      return () => ctx.revert()
    }
  }, [])

  // const setPosition = (update: number) => {
  //   scrollController?.scroll(
  //     scrollController.start +
  //       (update / data.length) *
  //         (scrollController.end - scrollController.start),
  //   )
  // }

  const setPosition = (index: number) => {
    if (index !== 2) {
      scrollController?.scroll(
        scrollController.start +
          (index / data.length) *
            (scrollController.end - scrollController.start),
      )
    } else {
      scrollController?.scroll(scrollController.end)
    }
  }

  const slidePosHandler = useCallback(
    (update: number) => {
      instance?.slideTo(update)
      setActiveSlide(update)
    },
    [instance],
  )

  const progressHandler = useCallback(
    (progress: number) => {
      const onePartOfWholeProgress = +(100 / 2).toFixed(2)
      const progressIndex = Math.round(progress / onePartOfWholeProgress)
      // console.log(progressIndex)
      if (progressIndex === 3) {
        return
      }
      slidePosHandler(progressIndex)
    },
    [instance, slidePosHandler],
  )

  return (
    <div ref={wrapper}>
      <div className="bg-gradient-to-br from-black to-black min-h-[650px] h-screen relative">
        {data.map((elem, index: number) => {
          const key = `${index}`
          return (
            <div
              key={key}
              className={`${
                activeSlide === index ? 'opacity-100' : 'opacity-0'
              } transition-all duration-700 ease-in-out absolute inset-0`}
            >
              <img
                src={elem.cover}
                className="w-full h-full object-cover object-right"
                alt={elem.cover}
              />
            </div>
          )
        })}
        <div className="grid grid-cols-12 blade-top-padding-lg blade-bottom-padding-lg relative">
          <div className="col-start-2 col-end-10 2xl:pt-5">
            <h2 className="sg-translate font-light text-white">
              An unmatched in-house <br className="md:block hidden" />{' '}
              <span className="text-blue font-semibold">ecosystem</span>{' '}
              enabling product <br className="md:block hidden" /> excellence
            </h2>
            <div className="flex blade-top-margin blade-bottom-margin ">
              <div
                style={{
                  transform: `translateY(${
                    window.innerWidth < 1536
                      ? activeSlide * 120
                      : activeSlide * 105
                  }%)`,
                }}
                className="flex flex-col h-[38px] 2xl:h-[50px] basis-[2px] transition-all ease-in-out duration-500 bg-white relative"
              >
                <div
                  ref={indicator}
                  className="absolute inset-0 w-full bg-white "
                />
              </div>
              <div className="grid pl-5">
                {data.map((elem, index) => {
                  const key = `${index}`
                  return (
                    <button
                      type="button"
                      key={key}
                      onClick={() => setPosition(index)}
                      className="text-left"
                    >
                      <h4
                        className={`sg-translate py-2 ${
                          activeSlide === index
                            ? 'text-opacity-100 font-semibold'
                            : 'text-opacity-60 font-normal'
                        } text-white `}
                      >
                        {elem.title}
                      </h4>
                    </button>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
