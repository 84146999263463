import React from 'react'

import 'styles/partners.css'
import LogoMarquee from 'organisms/logoMarquee'
import { partnersLogos } from 'static/partners'

import Asics from 'assets/apparel/brands/Asics.png'
import BR from 'assets/apparel/brands/BR.png'
import A from 'assets/apparel/brands/C&A.png'
import Cecil from 'assets/apparel/brands/Cecil.png'
import Debenhams from 'assets/apparel/brands/Debenhams.png'
import Decathlon from 'assets/apparel/brands/Decathlon.png'
import Esprit from 'assets/apparel/brands/Esprit.png'
import Gap from 'assets/apparel/brands/Gap.png'
import George from 'assets/apparel/brands/George.png'
import HM from 'assets/apparel/brands/HM.png'
import JohnLewis from 'assets/apparel/brands/JohnLewis.png'
import Kappahl from 'assets/apparel/brands/Kappahl.png'
import S from 'assets/apparel/brands/M&S.png'
import macys from 'assets/apparel/brands/macys.png'
import Osakahockey from 'assets/home/partners/newlogos/Osakahockey.png'
import Monsoon from 'assets/home/partners/newlogos/Monsoon.png'
import Mothercare from 'assets/apparel/brands/Mothercare.png'
import Next from 'assets/apparel/brands/Next.png'
import Nordstrom from 'assets/apparel/brands/Nordstrom.png'
import OldNavy from 'assets/apparel/brands/OldNavy.png'
import Kmart from 'assets/apparel/brands/Kmart.png'
import poster from 'assets/apparel/brands/poster.png'
import Sears from 'assets/apparel/brands/Sears.png'
import UCB from 'assets/apparel/brands/UCB.png'

const logos = [
  { id: 1, logo: Asics, title: 'Asics', category: '', alt: 'Asics-logo' },
  { id: 2, logo: BR, title: 'BR', category: '', alt: 'Banana-Republic-logo' },
  { id: 3, logo: A, title: 'C&A', category: '', alt: 'C&A-logo' },
  { id: 4, logo: Cecil, title: 'Cecil', category: '', alt: 'Cecil-logo' },
  {
    id: 5,
    logo: Debenhams,
    title: 'Debenhams',
    category: '',
    alt: 'Debenhams-logo',
  },
  {
    id: 6,
    logo: Decathlon,
    title: 'Decathlon',
    category: '',
    alt: 'Decathlon-logo',
  },
  { id: 7, logo: Esprit, title: 'Esprit', category: '', alt: 'Espriy-logo' },
  { id: 8, logo: Gap, title: 'Gap', category: '', alt: 'Gap-logo' },
  { id: 8, logo: George, title: 'George', category: '', alt: 'George-logo' },
  { id: 9, logo: HM, title: 'HM', category: '', alt: 'H&M-logo' },
  {
    id: 10,
    logo: JohnLewis,
    title: 'JohnLewis',
    category: '',
    alt: 'John-Lewis-logo',
  },
  {
    id: 11,
    logo: Kappahl,
    title: 'Kappahl',
    category: '',
    alt: 'Kappahl-logo',
  },
  { id: 17, logo: Kmart, title: 'Kmart', category: '', alt: 'Kmart' },
  { id: 12, logo: S, title: 'S', category: '', alt: 'M&S-logo' },
  { id: 13, logo: macys, title: 'macys', category: '', alt: 'macys-logo' },
  {
    id: 52,
    logo: Monsoon,
    title: 'Monsoon',
    category: '',
    alt: '-logo',
  },
  {
    id: 14,
    logo: Mothercare,
    title: 'Mothercare',
    category: '',
    alt: 'Mothercare-logo',
  },
  { id: 15, logo: Next, title: 'Next', category: '', alt: 'Next-logo' },
  {
    id: 30,
    logo: Nordstrom,
    title: 'Nordstrom',
    category: 'other',
    alt: 'Nordstrom-logo',
  },
  {
    id: 16,
    logo: OldNavy,
    title: 'OldNavy',
    category: '',
    alt: 'OldNavy-logo',
  },
  {
    id: 32,
    logo: Osakahockey,
    title: 'Osaka',
    category: 'other',
    alt: 'Osakahockey-logo',
  },
  { id: 1, logo: Sears, title: 'Sears', category: '', alt: 'Sears-logo' },
  { id: 20, logo: UCB, title: 'UCB', category: '', alt: 'UCB-logo' },
]
export default function Partners() {
  return (
    <div className="bg-white overflow-hidden blade-top-padding-lg blade-bottom-padding-lg">
      <div className="grid gap-3">
        <h2 className="sg-translate px-3 text-center font-semibold">
          Our key customers
        </h2>
        <span className="sg-translate px-3  text-center text-base 2xl:text-[1.375rem] lg:text-lg  font-normal">
          Innovation, quality, and service trusted by{' '}
          <br className="hidden min-[360px]:block md:hidden" />{' '}
          <span className="font-semibold"> leading global apparel brands.</span>
        </span>
      </div>
      <div className="2xl:pt-16 xl:pt-4 pt-5">
        <LogoMarquee
          logos={logos}
          gridStyle=" xl:grid-cols-7 grid-cols-3 gap-5"
        />
      </div>
    </div>
  )
}

// ;<section className="flex justify-center items-center flex-wrap gap-x-6 2xl:gap-x-10">
//   {/* // <div
//   //   key={logo.id}
//   //   className="grid place-content-center max-w-[140px] md:max-w-[140px] 2xl:max-w-[200px] "
//   // >
//   //   <img
//   //     src={logo.logo}
//   //     aria-hidden
//   //     alt={logo.alt}
//   //     className="2xl:h-28 h-20 object-contain object-center"
//   //   />
//   // </div> */}

//   {/* <LogoMarquee logos={logos.slice(0, 8)} isReverse={false} /> */}

//   {/* <div className="">
//   <div className="flex select-none w-full overflow-hidden">
//     <div className="flex marquee-reverse">
//       {logos.slice(7, 15).map((elem) => {
//         return (
//           <div
//             key={elem}
//             className="grid place-content-center max-w-[140px] md:max-w-[140px] 2xl:max-w-[250px] "
//           >
//             <img
//               src={elem}
//               aria-hidden
//               alt="Logo of the clientele."
//               className="2xl:h-32 h-20  dfull object-contain object-center "
//             />
//           </div>
//         )
//       })}
//     </div>
//     <div className="flex marquee-reverse">
//       {logos.slice(7, 15).map((elem) => {
//         return (
//           <div
//             key={elem}
//             className="grid place-content-center max-w-[140px] md:max-w-[140px] 2xl:max-w-[250px] "
//           >
//             <img
//               aria-hidden
//               src={elem}
//               alt="dummy icon"
//               className="2xl:h-32 h-20  dfull object-contain object-center "
//             />
//           </div>
//         )
//       })}
//     </div>
//   </div>
// </div> */}

//   {/* <LogoMarquee logos={logos.slice(7, 16)} isReverse /> */}
// </section>
