import React from 'react'
import 'swiper/css/navigation'
import bg from 'assets/home/ourPeople/bg.webp'
import bgMobile from 'assets/home/ourPeople/bg-mobile.jpg'
import { HeroBtn } from 'atoms/buttons'
import './home.css'

// import image1 from 'assets/home/ourPeople/image-01.webp'
// import image2 from 'assets/home/ourPeople/image-02.webp'
// import image3 from 'assets/home/ourPeople/image-03.webp'
// import image4 from 'assets/home/ourPeople/image-04.webp'
// import image5 from 'assets/home/ourPeople/image-05.webp'
// import image7 from 'assets/home/ourPeople/image-07.webp'
// import image8 from 'assets/home/ourPeople/image-08.webp'
// import image6 from 'assets/home/ourPeople/image-06.webp'
import { useNavigate } from 'react-router-dom'

// const data = [
//   { id: 1, img: image1 },
//   { id: 2, img: image2 },
//   { id: 3, img: image3 },
//   { id: 4, img: image4 },
//   { id: 5, img: image5 },
//   { id: 6, img: image6 },
//   { id: 7, img: image7 },
//   { id: 8, img: image8 },
// ]

export default function OurPeople() {
  const navigate = useNavigate()

  return (
    <div className="our-people-wrapper h-[500px] sm:h-[620px] md:h-[500px] lg:h-screen xl:min-h-[600px] 2xl:min-h-screen relative">
      <div className="w-full bg-blueDark bg-opacity-70 md:hidden h-full object-cover">
        <img
          className="w-full md:h-full md:object-cover xl:min-h-[600px]"
          src={bgMobile}
          alt="team created S shape"
        />
      </div>
      <div
        className="flex flex-col blade-bottom-padding-lg md:pb-0 justify-end md:justify-start md:items-start 
        absolute bg-gradient z-10 top-0 w-full h-full"
      >
        <div className="flex flex-col items-start md:blade-top-padding-xl md:ml-[6%] lg:ml-[10%] xl:ml-[9.5%] 2xl:ml-[10.5%]">
          <h2 className="sg-translate font-light lg:blade-top-padding text-left text-white px-4 md:px-0">
            Our people make us <br />
            <span className="font-semibold">Supreme</span>
          </h2>
          <h3
            className="sg-translate font-light mt-4 text-base md:text-xl xl:text-[22px] xlg:text-[28px]
        text-white md:max-w-xs lg:max-w-lg px-4 md:px-0"
          >
            Dynamic culture and people-powered{' '}
            <br className="md:hidden lg:block" /> progress is Supreme’s winning
            formula.
          </h3>
          <div className="md:mt-10 mt-6 px-4 md:px-0">
            <HeroBtn
              text="Join the supreme team"
              type="button"
              size="small"
              color="transparentWhite"
              onClick={() => navigate('/careers')}
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block md:absolute top-0 w-full h-full">
        <img
          alt="team created S shape"
          className="w-full h-full object-cover"
          src={bg}
        />
      </div>
    </div>
  )
}

// eslint-disable-next-line no-lone-blocks
{
  // Do not remove this code
  /* <div className="relative our-people-wrapper lg:h-screen">
    <div className="flex flex-col items-center lg:items-start lg:absolute bg-gradient z-10 top-0 w-full lg:h-full">
      <div className="lg:px-24 xl:px-36 lg:blade-top-padding-xl">
        <h2 className="font-light lg:blade-top-padding text-center lg:text-left lg:text-white">
          Our people make <br /> us{' '}
          <span className="font-semibold">Supreme</span>
        </h2>
        <h3
          className="font-light mt-4 text-lg md:text-xl xl:text-[22px] text-center lg:text-left xlg:text-[28px]
        lg:text-white"
        >
          Dynamic culture and people-powered <br /> progress is Supreme’s
          winning formula.
        </h3>
        <div className="mt-10 hidden lg:block">
          <HeroBtn
            text="Join the supreme team"
            type="button"
            size="base"
            color="transparentWhite"
            onClick={() => navigate('/careers')}
          />
        </div>
      </div>
    </div>

     <Swiper
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      }}
      speed={2000}
      loop
      breakpoints={{
        320: {
          slidesPerView: 4,
        },
        640: {
          slidesPerView: 5,
        },

        1024: {
          slidesPerView: 3.6,
        },
        1280: {
          slidesPerView: 4,
        },
        1366: {
          slidesPerView: 4,
        },
        1536: {
          slidesPerView: 5,
          spaceBetween: 0,
        },
      }}
      className="relative mt-10 lg:mt-0"
      modules={[Autoplay]}
    >
      {data.map((item) => {
        return (
          <SwiperSlide className="lg:h-screen" key={item.id}>
            <div className="h-[260px] sm:h-[300px] lg:h-screen border-l border-white">
              <img className="w-full object-cover" src={item.img} alt="" />
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>

    <div className="lg:hidden mt-6 blade-bottom-padding-lg flex flex-col items-center">
      <HeroBtn
        text="Join the supreme team"
        type="button"
        size="base"
        color="blue"
        onClick={() => {
          navigate('/careers')
        }}
      />
    </div>
  </div> */
}
