import React, { useEffect, useRef } from 'react'
import Banner from './banner'
import Partners from './partners'
import UseCases from './useCases'
import Map from './map'
import Evolution from './evolution'
import Solutions from './solutions'
import Stages from './stages'
import SubFooter from './subfooter'

export default function Automotive() {
  const mainRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)

    mainRef.current?.scrollIntoView()
  }, [])

  return (
    <div ref={mainRef} className="automotive">
      <Banner />
      <Solutions />
      <Partners />
      <UseCases />
      <Stages />
      {/* <Map /> */}
      <Evolution />
      <SubFooter />
    </div>
  )
}
