import React from 'react'
import PageBanner from 'organisms/pageBanner'
import bannerPoster from 'assets/videos/innovation.webp'
import bannerVideo from 'assets/videos/innovation.mp4'

export default function Banner() {
  return (
    <div>
      <PageBanner
        isVideo
        pageName="Always taking the next step"
        bgVideo={bannerVideo}
        videoPoster={bannerPoster}
        title="Catalyzing your progress through <br class='md:block hidden' />  <strong class='font-semibold text-blue'>nonwoven innovation</strong>"
        subtitle=""
      />
    </div>
  )
}
