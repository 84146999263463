import React from 'react'
import dailyLifeLarge from 'assets/careers/dailylife_large.png'
// import dailyLifeMobile from 'assets/careers/dailylife_mobile.jpg'

export default function DailyLife() {
  return (
    <section className="bg-[#EBF6FF] blade-top-padding-xl blade-bottom-padding-xl">
      <div className="">
        <div className="w-container px-4 flex flex-col-reverse lg:flex-row gap-x-6 2xl:gap-x-36 gap-y-10 items-center justify-center">
          <div className="flex-1 grid sm:place-content-center h-fit lg:h-[500px] xlg:h-[600px] xl:h-[550px] 2xl:h-[700px]">
            <div className="md:grid place-content-center">
              <img
                src={dailyLifeLarge}
                alt="gril driving a card"
                className=" w-11/12 2xl:w-full h-full object-contain object-center ml-0"
              />
            </div>
            {/* <div className="grid md:hidden place-content-center">
              <img
                src={dailyLifeMobile}
                alt=""
                className="w-full h-full object-contain object-center"
              />
            </div> */}
          </div>
          <div className="flex-1">
            <h2 className="sg-translate font-light text-black blade-bottom-padding-sm">
              Integrated into every <br />
              aspect of your <span className="font-medium">daily life</span>
            </h2>
            <span className="sg-translate text-black font-normal pb-2 md:pb-5 lg:pb-7 leading-normal text-base pr-6 md:pr-0 xl:text-lg 2xl:text-2xl max-w-lg block">
              Whether it&apos;s your favorite car or the interlining of your
              shirt, we are the minds behind nonwoven solutions for top
              automotive brands and the comfort of your everyday wear.
            </span>
            <span className="sg-translate text-black font-normal  leading-normal text-base xl:text-lg 2xl:text-2xl max-w-lg block pr-6 md:pr-0 ">
              With a genuine passion for nonwovens, we have been developing
              solutions for over four decades, partnering with hundreds of
              renowned brands.
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}
