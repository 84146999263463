// eslint disable
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-fade'

import { A11y, Pagination } from 'swiper'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// videos
import videoApperel from 'assets/home/solutions/apparel.mp4'
import customisedSolution from 'assets/home/solutions/customisedsolution.mp4'
import filtration from 'assets/home/solutions/filtration.mp4'
import videoAutomotive from 'assets/home/solutions/automotive.mp4'
// posters
import apparelPoster from 'assets/home/solutions/apparel-poster.webp'
import customisedsolutionPoster from 'assets/home/solutions/customized-solution-poster.webp'
import filtrationPoster from 'assets/home/solutions/filtration.webp'
import automotivePoster from 'assets/home/solutions/automotive-poster.webp'

import nonowen from 'assets/globals/nonwoven.svg'
import treon from 'assets/globals/treon.svg'
import terca from 'assets/globals/terca.svg'
import nowofilt from 'assets/globals/nowofilt.svg'

import { HeroBtn } from 'atoms/buttons'
import MobileCards from 'molecules/mobileCards'
import { useNavigate } from 'react-router-dom'

// import { carousels } from './yearCarouselMobile'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

// const lastScrollTop = 0

function SolutionsOptions() {
  const [activeSlide, setActiveSlide] = useState(0)
  const swiperRef = useRef<any>(null)
  const [instance, setInstance] = useState<any>(null)
  const [scrollController, setScrollController] = useState<any>(null)
  const [scrollingBottom, setScrollingBottom] = useState(true)
  const yearCarouselRef = useRef<any>(null)
  const navigate = useNavigate()

  // useEffect(() => {
  //   document.addEventListener('scroll', scrollSideGetter)

  //   return () => {
  //     document.removeEventListener('scroll', scrollSideGetter)
  //   }
  // }, [])

  // function scrollSideGetter() {
  //   const currentScroll =
  //     window.pageYOffset || document.documentElement.scrollTop

  //   if (currentScroll > lastScrollTop) {
  //     // Scrolling down
  //     setScrollingBottom(true)
  //   } else {
  //     // Scrolling up
  //     setScrollingBottom(false)
  //   }

  //   lastScrollTop = currentScroll <= 0 ? 0 : currentScroll
  // }

  useLayoutEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth >= 768) {
      const ctx = gsap.context(() => {
        const anim = gsap.from(yearCarouselRef.current, {})
        const controller = ScrollTrigger.create({
          animation: anim,
          trigger: yearCarouselRef.current,
          start: 'top top',
          end: '+400% bottom',
          scrub: 4,
          pin: true,
          markers: false,
          onUpdate: (e: any) => {
            const progress: number = +(e.progress * 100).toFixed(2) ?? 0
            progressHandler(progress)
            // console.log(progress)
          },
        })
        setScrollController(controller)
      })
      return () => ctx.revert()
    }
  }, [])

  const setPosition = (index: number) => {
    if (index !== 3) {
      scrollController?.scroll(
        scrollController.start +
          (index / carousels.length) *
            (scrollController.end - scrollController.start),
      )
    } else {
      scrollController?.scroll(scrollController.end)
    }
    console.log(
      scrollController.scroll(),
      scrollController.start,
      scrollController.end,
      'scroll',
    )
  }

  const slidePosHandler = useCallback(
    (update: number) => {
      instance?.slideTo(update)
      setActiveSlide(update)
    },
    [instance],
  )

  const progressHandler = useCallback(
    (progress: number) => {
      const onePartOfWholeProgress = +(100 / 3).toFixed(2)
      const progressIndex = Math.round(progress / onePartOfWholeProgress)
      // console.log(progressIndex)
      if (progressIndex === 4) {
        return
      }
      slidePosHandler(progressIndex)
    },
    [instance, slidePosHandler],
  )

  const carousels = [
    {
      id: 1,
      link: '/applications/apparel',
      title: 'Nonwoven solutions across segments',
      logo: terca,
      logoTitle: 'Apparel',
      description:
        'Enabling the creation of sustainable, stylish, and functional textiles with performance-driven interlining solutions. ',
      video: videoApperel,
      poster: apparelPoster,
    },
    {
      id: 2,
      link: '/applications/automotive',
      title: 'Nonwoven solutions across segments',
      logo: treon,
      logoTitle: 'Automotive',
      description:
        'Designing soft trims & NVH solutions with high-performance materials, enhancing comfort and durability.',
      video: videoAutomotive,
      poster: automotivePoster,
    },
    {
      id: 3,
      link: '/applications/filtration',
      title: 'Nonwoven solutions across segments',
      logo: nowofilt,
      logoTitle: 'Filtration',
      description:
        ' Delivering filtration solutions for product recovery & emission control.',
      video: filtration,
      poster: filtrationPoster,
    },
    {
      id: 4,
      link: '/applications/customised-nonwoven',
      title: 'Nonwoven solutions across segments',
      logo: nonowen,
      logoTitle: 'Customised Nonwoven',
      description:
        'Addressing a wide array of applications, from hygiene products to industrial innovations.',
      video: customisedSolution,
      poster: customisedsolutionPoster,
    },
  ]

  return (
    <section ref={swiperRef}>
      <section
        ref={yearCarouselRef}
        className="hidden md:block bg-[##EFF3F4] h-screen min-h-[600px] relative overflow-hidden"
      >
        <section className="timeline-wrapper md:grid hidden">
          {/* main content - desktop */}
          <div className="relative">
            <div className="h-screen w-screen bg-center bg-contain bg-no-repeat bg-origin-content z-1">
              {carousels.map((elem: any, index: any) => {
                const key = `${index}`
                return (
                  <div
                    key={key}
                    className={` ${
                      index === activeSlide ? 'opacity-100' : ' opacity-0'
                    } transition-all duration-500`}
                  >
                    <div className="h-screen absolute inset-0" key={elem.id}>
                      <div className="absolute inset-0">
                        <video
                          className={`vid-${elem.id} w-full h-full object-cover`}
                          poster={elem.poster}
                          autoPlay
                          muted
                          loop
                          playsInline
                        >
                          <source src={elem.video} type="video/mp4" />
                          <track kind="captions" srcLang="en" label="English" />
                        </video>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="absolute bg-gradientDark inset-0 mx-auto h-screen w-full">
              {carousels.map((elem: any, index: any) => {
                const key = `${index}`
                return (
                  <div key={key} className="h-screen w-screen ">
                    <div className="h-screen absolute inset-0" key={elem.id}>
                      <section className="relative h-full">
                        <div className="h-full w-container blade-bottom-padding-lg blade-top-padding-lg flex flex-col justify-between ">
                          <h2 className="sg-translate font-semibold text-white z-10">
                            {index === 0
                              ? 'Nonwoven solutions across segments'
                              : ''}
                          </h2>
                          <div
                            className={`max-w-md ${
                              index === activeSlide ? 'z-10' : ''
                            } content-${elem.id}`}
                          >
                            <div
                              className={`${
                                index === activeSlide
                                  ? 'translate-y-0 delay-300 opacity-100'
                                  : 'translate-y-14 opacity-0'
                              }  transition-all duration-1000 lg:h-12 h-12 w-fit max-w-[150px] flex`}
                            >
                              <img
                                className="h-full w-full object-contain"
                                src={elem.logo}
                                alt={elem.logoTitle}
                              />
                            </div>
                            <h3
                              className={`sg-translate ${
                                index === activeSlide
                                  ? 'translate-y-0 delay-300 opacity-100'
                                  : 'translate-y-14 opacity-0'
                              }  text-white font-normal mt-3 transition-all duration-1000`}
                            >
                              {elem.logoTitle}
                            </h3>
                            <h5
                              className={`sg-translate ${
                                index === activeSlide
                                  ? 'translate-y-0 delay-300 opacity-100'
                                  : 'translate-y-14 opacity-0'
                              } text-white font-normal mt-3 transition-all duration-1000`}
                            >
                              {elem.description}
                            </h5>
                            <div
                              className={`${
                                index === activeSlide
                                  ? 'translate-y-0 delay-300 opacity-100'
                                  : 'translate-y-14 opacity-0'
                              } mt-6 !z-50 transition-all duration-1000`}
                            >
                              <HeroBtn
                                text="Know more"
                                type="button"
                                size="small"
                                onClick={() => navigate(elem.link)}
                                color="blueLight"
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          {/* paginations */}
          <div className="absolute z-10  right-0 flex flex-col gap-5 justify-center 2xl:pr-20 lg:pr-10 pr-4 h-screen">
            {carousels.map((ele, tabIndex) => {
              return (
                <button
                  key={ele.id}
                  type="button"
                  className={`${
                    tabIndex === activeSlide
                      ? 'text-blue opacity-100'
                      : 'text-white opacity-60'
                  } tab flex items-center justify-end gap-3 leading-none font-normal`}
                  onClick={() => setPosition(tabIndex)}
                >
                  <span className="sg-translate">{ele.logoTitle}</span>
                  <span
                    className={`${
                      tabIndex === activeSlide ? 'bg-blue' : 'bg-transparent'
                    } dots h-3 w-3 rounded-full border inline-block`}
                  />
                </button>
              )
            })}
          </div>
        </section>

        <div className="absolute left-0 right-0 bottom-0">
          <button
            type="button"
            className="absolute bottom-0 left-1/2 -translate-x-1/2 group outline-none focus-visible:scale-110 transition-transform 
            duration-200 ease-linear"
            onClick={() => {
              gsap.to(window, {
                duration: 0.2,
                scrollTo: '#leadershipStats',
              })
            }}
          >
            <svg
              className="h-12 md:h-14 xl:h-16 2xl:h-20 group-hover:scale-110 cursor-pointer transition-transform duration-200 ease-linear "
              viewBox="0 0 241 117"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M239.372 115.378C239.219 100.697 236.154 86.1755 230.339 72.6023C224.371 58.6719 215.622 46.0118 204.591 35.346C193.559 24.6801 180.46 16.2174 166.041 10.443C151.622 4.66864 136.167 1.69614 120.558 1.69614C104.949 1.69614 89.4933 4.66864 75.0744 10.443C60.6556 16.2174 47.5568 24.6801 36.525 35.346C25.4932 46.0118 16.7445 58.6719 10.7764 72.6023C4.96131 86.1755 1.89651 100.697 1.7432 115.378L239.372 115.378ZM240.531 115.378C240.378 100.539 237.281 85.8626 231.404 72.1458C225.375 58.0721 216.537 45.2843 205.396 34.5127C194.255 23.7411 181.029 15.1966 166.472 9.36708C151.915 3.53754 136.314 0.537109 120.558 0.537109C104.802 0.537109 89.2001 3.53754 74.6435 9.36709C60.0869 15.1966 46.8605 23.7411 35.7193 34.5127C24.5782 45.2843 15.7406 58.0721 9.71103 72.1458C3.83445 85.8626 0.737462 100.539 0.584114 115.378C0.580122 115.764 0.578125 116.151 0.578125 116.537L240.537 116.537C240.537 116.151 240.535 115.764 240.531 115.378Z"
                fill="url(#paint0_linear_1674_10898)"
              />
              <path
                d="M107.578 59.8164L120.395 72.6334L133.212 59.8164"
                stroke="white"
                strokeWidth="3.09074"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1674_10898"
                  x1="120.558"
                  y1="0.537109"
                  x2="120.558"
                  y2="116.537"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00BFFF" />
                  <stop offset="1" stopColor="#fff" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </button>
        </div>

        {/* timeline skip button */}
        {/* <button
        type="button"
        aria-label="Skip timeline"
        className="text-orange px-1 z-10 bottom-8 right-32 font-regular absolute text-2xl after:content-[''] after:h-[2px] after:w-20 after:bg-orange after:absolute after:left-[105%] after:top-1/2 outline-none opacity-80 hover:opacity-100 transition-all duration-200 focus-visible:opacity-100 focus-visible:outline-orange rounded-md"
        onClick={skipCarouselHandler}
      >
        Skip timeline
      </button> */}
      </section>
      <div className="md:hidden block bg-blueShade blade-top-padding blade-bottom-padding">
        <h2 className="title font-light text-center mb-7 px-3">
          Nonwoven <span className="font-semibold"> solutions </span> across
          segments
        </h2>
        <Swiper
          centeredSlides
          slidesPerView={1.1}
          slidesPerGroup={1}
          loop
          pagination
          breakpoints={{
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            1024: {
              centeredSlides: true,
              slidesPerView: 2.1,
            },
            1280: {
              slidesPerGroup: 2,
              centeredSlides: true,
              slidesPerView: 3.5,
            },
            1440: {
              centeredSlides: true,
              slidesPerView: 3.8,
            },
          }}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={1500}
          modules={[Pagination, A11y]}
          spaceBetween={10}
          className="pb-8"
        >
          {carousels.map((item, i) => {
            return (
              <SwiperSlide
                key={item.id}
                className="h-full relative webkit-rounded pb-3"
              >
                <MobileCards
                  id={item.id}
                  video={item.video}
                  poster={item.poster}
                  logo={item.logo}
                  title={item.logoTitle}
                  link={item.link}
                  description={item.description}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </section>
  )
}

export default SolutionsOptions
