import React from 'react'
import newAgeMobile from 'assets/innovation/newAgeMobile.jpg'

export default function NewAge() {
  return (
    <section className="md:bg-[url('assets/innovation/newAgeBg.jpg')] blade-top-padding-lg blade-bottom-padding-lg lg:h-banner lg:min-h-[34rem] min-h-[24rem] bg-cover bg-center ">
      <div className="w-container flex flex-col gap-3 md:gap-5 lg:gap-7">
        <h2 className="sg-translate font-light leading-snug  text-black md:text-white px-1 md:px-0">
          The road to new-age <br />{' '}
          <strong className="font-semibold text-blue">nonwoven</strong>{' '}
          manufacturing
        </h2>

        <h5 className="sg-translate font-normal leading-relaxed md:w-full  text-black md:text-white max-w-2xl px-1 md:px-0">
          Embracing the latest in manufacturing technology, we are equipped with
          in-house competencies to conceptualize and integrate sophisticated
          mechanization, automation, and digital tools, into build-to-suit
          special purpose machines. This strategic integration ensures we stay
          ahead in our industry, delivering products swiftly without
          compromising on quality. As a forward-thinking supplier with global
          ambitions, we&apos;re committed to leading the way in innovative
          manufacturing, providing our customers with the best in both speed and
          excellence.
        </h5>
      </div>
      <div className="md:hidden grid place-content-start place-items-center px-4 rounded-lg overflow-hidden mt-8 sm:mt-10 ">
        <img
          src={newAgeMobile}
          alt="Depiction of new age tech used by supreme group"
        />
      </div>
    </section>
  )
}
