import React from 'react'
import banner from 'assets/contact/banner.jpg'

export default function Banner() {
  return (
    <div>
      <div className="h-[22rem] md:h-[28rem] xl:h-[32.5rem] 2xl:h-[37.5rem] bg-gradient-to-br from-blue  to-blueDark  ">
        <img
          className="h-full w-full object-cover object-top"
          src={banner}
          alt="front view of office building of supreme group"
        />
      </div>
    </div>
  )
}
