import React from 'react'
import glance from 'assets/globalCompetency/glance/01.jpg'
import approach from 'assets/globalCompetency/glance/02.jpg'
import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'

export default function Glance() {
  const navigate = useNavigate()

  return (
    <div className="blade-top-padding-lg blade-bottom-padding-lg bg-white blade-top-margin md:mt-0">
      <div className=" md:w-11/12 mx-auto px-3 2xl:w-container-xl flex md:flex-row flex-col md:gap-8 gap-7">
        <div className="mx-auto max-w-md md:max-w-none flex-1 relative overflow-hidden xl:h-[400px] 2xl:h-[545px] md:h-[380px] h-72 rounded-2xl lg:rounded-3xl 2xl:rounded-[40px]">
          <div className="md:absolute inset-0 md:rounded-none rounded-2xl overflow-hidden">
            <img
              src={glance}
              className="h-full w-full object-cover"
              alt="Supreme group employees working together"
            />
          </div>
          <div className="md:bg-gradient relative h-full flex py-5 xl:px-4 flex-col justify-end items-center gap-3 md:gap-4">
            <h3 className="sg-translate md:text-white text-center">
              Supreme Group at a glance
            </h3>
            <h5 className="sg-translate text-center md:text-white md:pt-1 px-3 md:px-6 xl:px-0 w-full md:w-11/12 mx-auto">
              Explore our multifaceted journey, from humble beginnings to our
              leading presence as a nonwoven manufacturer.
            </h5>
            <div className="pt-3 pb-3 2xl:pb-5">
              <HeroBtn
                size="small"
                text="Who we are?"
                color="blueLight"
                onClick={() => {
                  navigate('/who-we-are')
                }}
                type="button"
              />
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-md md:max-w-none flex-1 relative overflow-hidden xl:h-[400px] 2xl:h-[545px] md:h-[380px] h-72 rounded-2xl lg:rounded-3xl 2xl:rounded-[40px]">
          <div className="md:absolute inset-0 md:rounded-none rounded-2xl overflow-hidden">
            <img
              src={approach}
              className="h-full w-full object-cover"
              alt="Scientist performing innovative testings"
            />
          </div>
          <div className="md:bg-gradient relative h-full flex py-5 xlg:px-4 flex-col justify-end items-center gap-3 md:gap-4">
            <h3 className="sg-translate md:text-white text-center">
              Our innovation approach
            </h3>
            <h5 className="sg-translate text-center md:text-white md:pt-1  mx-auto px-3 md:px-0  w-full md:w-11/12 ">
              Discover how our relentless pursuit of innovation is driving
              Supreme Group to redefine nonwoven solutions across industries.{' '}
            </h5>
            <div className="pt-3 pb-3 2xl:pb-5">
              <HeroBtn
                size="small"
                text="Explore"
                color="blueLight"
                onClick={() => {
                  navigate('/innovation')
                }}
                type="button"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
