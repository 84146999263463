import React from 'react'
import image1 from 'assets/sustainability/case-study/image-01.jpg'
import image2 from 'assets/sustainability/case-study/image-02.jpg'
import image3 from 'assets/sustainability/case-study/image-03.jpg'
import circulineLogo from 'assets/sustainability/case-study/circuline-logo.png'

type CardProps = {
  cover: string
  des: string
  logo?: string
}

export function CaseStudy() {
  return (
    <section className="blade-top-padding-lg blade-bottom-padding-xl">
      <div className="grid place-content-center gap-2 text-center md:w-11/12 mx-auto px-5  blade-bottom-padding-sm ">
        <h2 className="sg-translate font-normal leading-tight text-black">
          Case studies
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-10 gap-x-6 2xl:gap-x-10 w-container pt-2 sm:pt-4 md:pt-0">
        <Card
          des="A hallmark of our commitment, Circuline products, starting with GRS-certified Terca Interlinings, symbolize our ambition to render every product lifecycle sustainable. "
          cover={image1}
          logo={circulineLogo}
        />
        <Card
          des="Our Circuline marked Ultratack range of industrial filters are engineered for optimum performance without the use of environmentally damaging materials such as PTFE. "
          cover={image2}
          logo={circulineLogo}
        />
        <Card
          des="In the automotive Industry we are offering the entire range of our products that are engineered for high performance without the use of harmful PU chemicals and binders."
          cover={image3}
          logo={circulineLogo}
        />
      </div>
    </section>
  )
}

function Card({ cover, des, logo }: CardProps) {
  return (
    <article className="flex flex-col w-full rounded-lg lg:rounded-xl 2xl:rounded-[20px] border-2 border-[#e1e1e1a1]">
      <div
        className="basis-[200px] rounded-t-lg lg:rounded-t-xl 2xl:rounded-t-[20px] lg:basis-[250px] 
        xl:basis-[200px] xlg:basis-[250px] 2xl:basis-[230px] flex-0 overflow-hidden relative"
      >
        <img
          src={cover}
          className="h-full w-full object-cover object-center"
          alt=""
        />
        {logo && (
          <div className="absolute z-10 w-[120px] md:w-[140px] bottom-6 right-6">
            <img src={logo} alt="circuline logo" />
          </div>
        )}
      </div>
      <div className="flex-1 bg-[#FAFAFA] rounded-b-lg lg:rounded-b-xl 2xl:rounded-b-[20px] overflow-hidden">
        <div className="pt-4 pb-8 xl:pt-8 xlg:pb-12 px-4 lg:px-6 2xl:px-8">
          <div className="w-11/12 md:w-full leading-snug">
            <span className="sg-translate pr-3 text-sm md:text-base font-normal 2xl:text-lg inline">
              {des}
            </span>
          </div>
        </div>
      </div>
    </article>
  )
}
