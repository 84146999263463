import React, { useRef, useState } from 'react'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { HeroBtn } from 'atoms/buttons'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const formSchema = z.object({
  name: z
    .string()
    .min(1, 'Name is required.')
    .max(30, 'Name can not be more than 30 characters.'),
  email: z
    .string()
    .email('Invalid email address.')
    .min(1, 'Email is required.'),
  subject: z.string().length(10, 'Please enter a subject.'),
  message: z.string().min(2, 'Please enter a valid message.'),
})

export type FormFieldSchema = z.infer<typeof formSchema>

export default function Form() {
  const [isLoading, setLoading] = useState(false)

  const careerFormRef = useRef<any>()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormFieldSchema> = async (data) => {
    setLoading(true)
    const res = await fetch('')
    if (res.ok) {
      //  handle form submisison here
      // emailjs
      //   .sendForm(
      //     'supreme-service-id',
      //     'template_2malzgm',
      //     careerFormRef.current,
      //     'mG9ssGJLNqKO0OSWG',
      //   )
      //   .then(
      //     (result) => {
      //       toast('Form submitted successfully!')
      //       console.log(result.text)
      //     },
      //     (error) => {
      //       toast('Something went wrong!')
      //       console.log(error.text)
      //     },
      //   )
      console.log('form has been submitted')
    } else {
      console.error('Form error')
    }
    reset()
    setLoading(false)
  }

  return (
    <section>
      <ToastContainer
        position="bottom-right"
        theme="dark"
        progressClassName="bg-subfooter"
        className="text-sm !px-4"
      />
      <div>
        <form
          noValidate
          ref={careerFormRef}
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-screen-lg w-full grid md:grid-cols-2 gap-4 md:gap-7"
        >
          <div className="relative">
            <input
              className="input-style"
              id="name"
              placeholder="Name"
              {...register('name')}
            />
            {errors.email && (
              <span className="absolute top-full left-0 text-rose-500  2xl:text-sm text-[12px]">
                {errors.name?.message}
              </span>
            )}
          </div>

          <div className="relative">
            <input
              className="input-style"
              id="email"
              type="email"
              inputMode="email"
              placeholder="Email"
              {...register('email')}
            />
            {errors.email && (
              <span className="absolute top-full left-0 text-rose-500  2xl:text-sm text-[12px]">
                {errors.email?.message}
              </span>
            )}
          </div>

          <div className="relative">
            <input
              className="input-style"
              type="text"
              id="designation"
              inputMode="text"
              placeholder="Interested In"
              {...register('subject')}
            />
            {errors.subject && (
              <span className="absolute top-full left-0 text-rose-500  2xl:text-sm text-[12px]">
                {errors.subject?.message}
              </span>
            )}
          </div>

          <div className="">
            <textarea
              rows={4}
              cols={5}
              id="message"
              className="input-style"
              placeholder="Messages"
              {...register('message')}
            />
          </div>
          <div className="grid md:col-span-2 place-content-start mt-4">
            <HeroBtn
              type="submit"
              text={isLoading ? 'Loading...' : 'Join Now'}
              isDisabled={isLoading}
              size="small"
              color="transparent"
            />
          </div>
        </form>
      </div>
    </section>
  )
}
