import React from 'react'
import slideA from 'assets/innovation/salientFeatures/slide_a.jpg'
import slideB from 'assets/innovation/salientFeatures/slide_b.jpg'
import slideC from 'assets/innovation/salientFeatures/slide_c.jpg'
import slideD from 'assets/innovation/salientFeatures/slide_d.jpg'

import Carousal from 'organisms/carousal'

const slides = [
  {
    title: 'Dynamic in-house capabilities',
    description:
      'We’re advancing product performance by pioneering fresh thinking to nonwoven and conversion technology.',
    coverImage: slideA,
  },
  {
    title: 'Customer-centric customisation',
    description:
      'Tailoring custom solutions to meet and exceed the diverse needs of our clients.',
    coverImage: slideB,
  },
  {
    title: 'Rigorous in-house testing',
    description:
      'Every product that passes through our development gates is a benchmark of quality and performance.',
    coverImage: slideC,
  },
  {
    title: 'Continuous R&D investment',
    description:
      'By investing in research and development, we strive to continuously lead with technological advancements; driving the industry forward with every innovation.',
    coverImage: slideD,
  },
]

export default function SalientFeatures() {
  return (
    <div className="blade-top-padding use-cases-wrapper blade-bottom-padding">
      <div className="lg:blade-top-padding-sm lg:blade-bottom-padding-sm">
        <h2 className="sg-translate font-light text-center px-3">
          Innovation{' '}
          <span className="font-semibold text-blue"> ecosystem </span> –
          Capabilities <br className="md:block hidden" /> that enable consistent
          breakthroughs
        </h2>

        <Carousal data={slides} height="" />
      </div>
    </div>
  )
}
