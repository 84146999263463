import React, { useEffect } from 'react'
import PageBanner from 'organisms/pageBanner'
import bannerPoster from 'assets/videos/filtration.webp'
import bannerVideo from 'assets/videos/filtration.mp4'
// import nowofilt from 'assets/home/solutions/nowofilt.png'
import nowofilt from 'assets/globals/nowofilt_white.svg'

import ApplicationExpertise from './applicationExpertise'
import Clients from './clients'
import ContactUs from './contactUs'
// import CommonMistakes from './commonMistakes'
// import CommonMistakesMobileView from './commonMistakesMobileView'
import Engineering from './engineering'
import ExpertAssistance from './expertAssistance'
import OurStrengths from './ourStrengths'

export default function Filtration() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <PageBanner
        bgVideo={bannerVideo}
        videoPoster={bannerPoster}
        title="Mastering <span class='text-blue font-bold'> filtration </span> through <br class='md:block hidden'/> material science"
        pageName="We take care of your entire filtration lifecycle"
        icon={nowofilt}
        iconAlt="Nowofilt logo"
        iconClasses="h-5 lg:h-7 w-auto object-contain object-center mx-auto mb-1 xl:mb-2 animate-fade opacity-0"
      />
      <ApplicationExpertise />
      <OurStrengths />
      <Clients />
      {/* <div className="md:block hidden">
        <CommonMistakes data={commonMistakesData} />
      </div>
      <div className="md:hidden block">
        <CommonMistakesMobileView data={commonMistakesData} />
      </div> */}

      <Engineering />

      <ExpertAssistance />

      <ContactUs />
    </div>
  )
}

// const dummy =
//   'https://plus.unsplash.com/premium_photo-1677695581626-2a75bdece138?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
// const dummy2 =
//   'https://images.unsplash.com/photo-1708865746958-658369bdb6b4?q=80&w=2129&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
// const commonMistakesData = [
//   {
//     title: 'Bag design flaws',
//     image: dummy,
//     list: [
//       'Issue of wrong welding',
//       'Insulation of improper sealing',
//       'Incorrect tube sheet and bag spacing',
//       'Incorrect cloth-to-air ratio',
//       'Incorrect hopper design',
//     ],
//   },
//   {
//     title: 'Bag make-up flaws',
//     image: dummy2,
//     list: [
//       'Wrong dimensions',
//       'Incorrect stitching and sewing thread',
//       'Incorrect snap ring used',
//       'Damaged while stitching',
//       'Wrong fabric cut',
//     ],
//   },
//   {
//     title: 'Erection and commissioning flaws',
//     image: dummy,
//     list: [
//       'Wrong bag',
//       'Damaged bags in store',
//       'Incorrect cage',
//       'Wrong tube sheet hole',
//       'Improper bag sealing and tensioning',
//       'False air leakage',
//       'Fan mount',
//     ],
//   },
//   {
//     title: 'Operational flaws',
//     image: dummy2,
//     list: [
//       'Chemical attack',
//       'Too frequent changes in surge temperature',
//       'Mishandling during maintenance operations',
//       'Sparks',
//       'Condensation',
//       'Frequent changes in fuel firing',
//       'Too frequent shutdowns',
//     ],
//   },
// ]
