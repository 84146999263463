import React, { useState, ReactNode } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css/navigation'

import image01 from '../../assets/apparel/support/01.jpg'
import image02 from '../../assets/apparel/support/02.jpg'
import image03 from '../../assets/apparel/support/03.jpg'
import image04 from '../../assets/apparel/support/04.jpg'

const slides = [
  {
    title: 'Online technical bulletin',
    desc: ' A technical topic related to interlining and its application is shared fortnightly with our business partners.',
    cover: image01,
  },
  {
    title: 'Shop floor studies',
    desc: 'Qualified textile technologists assess the relative productivity and performance of interlinings.',
    cover: image02,
  },
  {
    title: 'Technical seminars',
    desc: 'Our experts conduct educational seminars on the importance of using the right quality of interlining for different applications and fabrics.',
    cover: image03,
  },
  {
    title: 'Recommendation report',
    desc: 'An advisory report is developed for any type of fabric after technical analysis.',
    cover: image04,
  },
]

export default function TechSupport() {
  const [current, setCurrent] = useState(0)

  return (
    <section className="bg-[#EBF6FF] blade-top-padding blade-bottom-padding-sm">
      <div className="w-container blade-bottom-padding-sm blade-top-padding-sm gap-4 xl:gap-6">
        <h2 className="sg-translate font-light text-black text-center md:text-left">
          Preferred supplier partner for <br className="hidden sm:block" />
          unmatched <span className="font-semibold">technical support</span>
        </h2>
      </div>

      <div className=" ml-auto blade-bottom-padding">
        <Swiper
          centeredSlides
          slidesPerView={1.1}
          pagination={{
            clickable: true,
          }}
          loop={false}
          navigation={{
            prevEl: '.life-carousel-prev-btn',
            nextEl: '.life-carousel-next-btn',
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              centeredSlides: false,
              pagination: false,
            },
            1024: {
              centeredSlides: false,
              slidesPerView: 2.1,
              pagination: false,
            },
            1280: {
              slidesPerGroup: 3,
              centeredSlides: false,
              spaceBetween: 20,
              slidesPerView: 2.5,
              pagination: false,
            },
            1440: {
              centeredSlides: false,
              slidesPerGroup: 3,
              slidesPerView: 2.6,
              spaceBetween: 24,
              pagination: false,
            },
          }}
          className="cursor-grab md:pl-[6%] lg:pl-[9.5%] min-[1800px]:pl-[10.5%]"
          onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
          modules={[Navigation, Pagination]}
          spaceBetween={10}
          speed={1200}
        >
          {slides.map((elem, index: number) => {
            const { cover, title, desc } = elem
            const key = `${index}`
            return (
              <SwiperSlide key={key} className="py-2 last:pr-4 mb-8 md:mb-0">
                <Slide title={title} desc={desc} cover={cover} />
              </SwiperSlide>
            )
          })}
        </Swiper>

        <div className="hidden gap-4 pt-3 xl:gap-5 md:pt-5 xl:pt-8 pl-5 md:pl-0 w-container-sm md:flex justify-end">
          <SliderBtn
            slideClass="life-carousel-prev-btn"
            isDisabled={current === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
          <SliderBtn
            isDisabled={current === slides.length - 1}
            slideClass="life-carousel-next-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
                fill="white"
              />
            </svg>
          </SliderBtn>
        </div>
      </div>
    </section>
  )
}

function SliderBtn({
  slideClass,
  isDisabled,
  children,
}: {
  slideClass: string
  isDisabled: boolean
  children: ReactNode
}) {
  return (
    <button
      type="button"
      className={`${slideClass} disabled:opacity-50 disabled:cursor-not-allowed place-items-center rounded-full grid place-content-center h-10 lg:h-12 2xl:h-16 aspect-square  transition-all duration-500 ease-in-out bg-blue focus:outline-blueDark outline-1 outline-sollid outline-transparent ${
        isDisabled
          ? 'cursor-not-allowed'
          : 'bg-opacity-100 enabled:hover:bg-blueDark '
      } `}
    >
      <span className="sg-translate">{children}</span>
    </button>
  )
}

function Slide({
  title,
  cover,
  desc,
}: {
  title: string
  desc: string
  cover: string
}) {
  return (
    <article className="2xl:rounded-[20px] group">
      <div className="relative ">
        <div className="bg-gradient-to-b from-transparent to-black h-64 md:h-72 lg:h-80 xlg:h-[26rem] rounded-xl lg:rounded-2xl overflow-hidden  2xl:h-[28.75rem]">
          <img
            className=" h-64 md:h-72 lg:h-80 xlg:h-[26rem] 2xl:h-[28.75rem] w-full object-cover object-center"
            src={cover}
            alt={title}
          />
        </div>

        <div
          className="absolute rounded-[18px] hidden md:block inset-0 group-hover:opacity-100 lg:opacity-0 transition-all duration-[900ms] ease-in-out"
          style={{
            backgroundImage:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #006ABC 85.34%)',
          }}
        />
        <div
          className="absolute inset-0 group-hover:opacity-0 lg:opacity-100 transition-all duration-[900ms] ease-in-out md:block hidden rounded-xl lg:rounded-2xl"
          style={{
            backgroundImage:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%,  rgba(0, 0, 0, 0.8) 90.34%)',
          }}
        />

        <div className=" relative md:absolute inset-0 flex flex-col justify-end px-3 pl-0 pt-4 sm:px-4 md:px-6 xl:px-8 2xl:px-[40px] pb-2 md:pb-8 xl:pb-10 group-hover:pb-4 transition-all duration-500 ease-in-out ">
          <span className="sg-translate font-semibold text-black md:text-white pb-1 md:pb-2 block text-xl 2xl:text-2xl">
            {title}
          </span>

          <div className="lg:h-0 overflow-hidden lg:group-hover:h-20  2xl:group-hover:h-24 transition-all duration-500 ease-in-out">
            <span className="sg-translate font-normal text-sm md:text-base 2xl:text-lg lg:opacity-0 group-hover:opacity-100 transition-all duration-300  ease-in-out text-black md:text-white leading-snug w-11/12  block">
              {desc}
            </span>
          </div>
        </div>
      </div>
    </article>
  )
}
