import React, { useRef, useEffect, useState } from 'react'
import img1 from 'assets/automotive/stages/parts_and_tools_design.jpg'
import img2 from 'assets/automotive/stages/material_development.jpg'
import img3 from 'assets/automotive/stages/testing.jpg'
import img4 from 'assets/automotive/stages/series_production.jpg'
import img5 from 'assets/automotive/stages/co_locating.jpg'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
// import MobileCards from 'molecules/mobileCards'
import './automotive.css'

const data = [
  {
    id: 1,
    title: 'Part & tool design',
    description: 'In-house design team to develop automotive parts and tools.',
    image: img1,
  },
  {
    id: 2,
    title: 'Material development',
    description:
      'In-house R&D and material development teams take care of the development of performance-centric materials.',
    image: img2,
  },
  {
    id: 3,
    title: 'Testing',
    description:
      'In-house automotive testing facilities to test the developed products for quality and performance benchmarks.',
    image: img3,
  },
  {
    id: 4,
    title: 'Series production',
    description:
      'In-house capabilities to conduct the entire cycle of automotive nonwoven production.',
    image: img4,
  },
  {
    id: 5,
    title: 'Co-locating with OEMs for unmatched collaboration',
    description:
      'Our reach empowers us to meet the unique needs of various markets, offering unmatched accessibility and adaptability.',
    image: img5,
  },
]

const statsData = [
  {
    id: 1,
    number: '5000+',
    description: 'parts designed <br class="hidden md:block" /> in-house',
  },
  {
    id: 2,
    number: '85%',
    description:
      'of raw materials are <br class="hidden md:block" /> manufactured in-house',
  },
  {
    id: 3,
    number: '100%',
    description:
      'in-house tooling <br class="hidden md:block" /> concept and design',
  },
  {
    id: 4,
    number: '90%',
    description:
      'of testing and validation <br class="hidden md:block" /> is done in-house',
  },
  { id: 5, number: '+2 million', description: 'car sets delivered every year' },
]

const statsDataMobile = [
  {
    id: 1,
    number: '5000+',
    description: 'parts designed <br class="hidden lg:block" /> in-house',
  },
  {
    id: 2,
    number: '100%',
    description:
      'in-house tooling <br class="hidden lg:block" /> concept and design',
  },
  { id: 3, number: '+2 million', description: 'car sets delivered every year' },
  {
    id: 4,
    number: '85%',
    description:
      'of raw materials are <br class="hidden lg:block" /> manufactured in-house',
  },
  {
    id: 5,
    number: '90%',
    description:
      'of testing and validation <br class="hidden lg:block" /> is done in-house',
  },
]

gsap.registerPlugin(ScrollTrigger, { debug: true })

export default function Stages() {
  const timelineRefStages = useRef<gsap.core.Timeline | null>(null)

  const [refresher, setRefresher] = useState(false)

  function navigateToLabel(label: string) {
    if (timelineRefStages.current) {
      timelineRefStages.current.seek(label)
    }
  }

  useEffect(() => {
    if (window.innerWidth > 768) {
      const gsapCtx = gsap.context(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.stages-wrapper',
            start: 'top top',
            end: 'bottom -200%',
            markers: false,
            scrub: 1.5,
            pin: true,
          },
        })
        timelineRefStages.current = tl

        tl.addPause(1)
          .add('l1')
          // Part & Tool design

          .to('.stages-wrapper .indicators-01', { width: 48 }, 'l1')
          .to('.stages-wrapper .label-02', { opacity: 1 }, 'l1')
          .to('.content-01', { y: 30, opacity: 0 }, 'l1')
          .to('.slide-01', { opacity: 0 }, 'l1')
          .to('.slide-02', { opacity: 1 }, 'l1')
          .fromTo(
            '.content-02',
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, zIndex: '20', delay: 0.2 },
            'l1',
          )

        // Material development
        tl.add('l2')

          .to('.stages-wrapper .indicators-02', { width: 48 }, 'l2')
          .to('.stages-wrapper .label-03', { opacity: 1 }, 'l2')
          .to('.content-02', { y: 30, opacity: 0 }, 'l2')
          .to('.slide-02', { opacity: 0 }, 'l2')
          .to('.slide-03', { opacity: 1 }, 'l2')
          .fromTo(
            '.content-03',
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, zIndex: '20', delay: 0.2 },
            'l2',
          )
        // Testing
        tl.add('l3')

          .to('.stages-wrapper .indicators-03', { width: 48 }, 'l3')
          .to('.stages-wrapper .label-04', { opacity: 1 }, 'l3')
          .to('.content-03', { y: 30, opacity: 0 }, 'l3')
          .to('.slide-03', { opacity: 0 }, 'l3')

          .to('.slide-04', { opacity: 1 }, 'l3')
          .fromTo(
            '.content-04',
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, zIndex: '20', delay: 0.2 },
            'l3',
          )
        // Series production
        tl.add('l4')

          .to('.stages-wrapper .indicators-04', { width: 48 }, 'l4')
          .to('.stages-wrapper .label-05', { opacity: 1 }, 'l4')
          .to('.content-04', { y: 30, opacity: 0 }, 'l4')
          .to('.slide-04', { opacity: 0 }, 'l4')

          .to('.slide-05', { opacity: 1 }, 'l4')
          .fromTo(
            '.content-05',
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, zIndex: '20', delay: 0.2 },
            'l4',
          )

        // tl.add('l5').to(
        //   '.stages-wrapper .tab-05',
        //   { color: '#00BFFF', opacity: 1 },
        //   'l5',
        // )
        // .to(
        //   '.stages-wrapper .tab-05 .dots',
        //   { background: '#00BFFF', opacity: 1 },
        //   'l5',
        // )
      })

      return () => gsapCtx.revert()
    }
  }, [refresher])

  useEffect(() => {
    setTimeout(() => {
      setRefresher((p) => !p)
    }, 1000)
  }, [])

  return (
    <div>
      <section className="relative hidden lg:block stages-wrapper h-screen bg-black overflow-hidden">
        {data.map((item, index) => {
          return (
            <div
              key={item.id}
              className={`slide-0${item.id} ${
                index === 0 ? 'opacity-100' : 'opacity-0'
              } absolute h-screen inset-0`}
            >
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src={item.image}
                  alt={item.title}
                />
              </div>
              <div className="bg-black absolute w-full h-full opacity-70" />
            </div>
          )
        })}
        <div className="h-screen w-full z-10">
          <div className="w-container relative px-4 2xl:px-20 blade-top-padding-lg">
            <h2 className="sg-translate font-light text-white pt-8 xl:pt-5">
              Complete end-to-end integration
            </h2>
            <h5 className="sg-translate font-normal mt-4 2xl:text-[22px] max-w-2xl text-white ">
              With all stages under one roof, we minimize lead times, helping
              you meet your timelines without compromising on quality.
            </h5>

            {/* Client change */}
            <div className="h-16 2xl:h-32" />
            {/* Client change end */}

            {data.map((content, contentIndex) => {
              return (
                <div
                  key={content.id}
                  className={`2xl:mt-8 mt-4 ${
                    contentIndex === 0 ? 'opacity-100' : 'opacity-0'
                  } absolute content-0${content.id}`}
                >
                  <h4 className="sg-translate font-semibold text-2xl 2xl:text-[28px] text-white ">
                    {content.title}
                  </h4>
                  <h5 className="sg-translate font-normal max-w-[420px] xl:max-w-[450px] 2xl:max-w-[550px] mt-2 text-white">
                    {content.description}
                  </h5>
                </div>
              )
            })}
          </div>

          {/* Client change */}
          <div className="flex 2xl:mt-14 mt-6 items-center absolute rotate-90 2xl:top-[35%] top-[40%] -translate-y-1/2 right-0">
            {data.map((label, labelIndex) => {
              return (
                <>
                  <button
                    type="button"
                    className={`w-10 h-10 2xl:w-12 2xl:h-12 cursor-pointer font-normal text-xl 2xl:text-2xl rounded-full bg-white text-black ${
                      labelIndex === 0 ? 'opacity-100' : 'opacity-50'
                    } label-0${label.id}`}
                    key={label.id}
                    onClick={() => {
                      navigateToLabel(`l${label.id}`)
                    }}
                  >
                    <span className="sg-translate inline-block -rotate-90">
                      0{label.id}
                    </span>
                  </button>
                  {labelIndex !== data.length - 1 && (
                    <span className="bg-white bg-opacity-20 h-[2px] w-12 inline-block relative">
                      <span
                        className={`bg-white indicators-0${label.id} w-0 inline-block absolute inset-0 h-[2px]`}
                      />
                    </span>
                  )}
                </>
              )
            })}
          </div>
          {/* Client change end */}
        </div>

        <div className="absolute stats-wrapper z-20 pb-6 pt-3 2xl:py-8 w-full bottom-0 bg-white">
          <div className="flex gap-x-12 xl:gap-x-20 xlg:gap-x-24 2xl:gap-x-32 justify-center">
            {statsData.map((item) => {
              return (
                <div key={item.id} className="flex w-fit flex-col">
                  <h2 className="sg-translate font-semibold stats-text">
                    {item.number}
                  </h2>
                  <h6
                    dangerouslySetInnerHTML={{ __html: item.description }}
                    className="sg-translate font-normal text-sm xlg:text-[16px] text-[#CECECE]"
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>

      {/* Mobile view */}
      <div className="lg:hidden block blade-bottom-padding blade-top-padding">
        <div className="flex flex-col items-center">
          <h2 className="sg-translate font-light text-center px-3">
            Complete end-to-end integration
          </h2>
          <h5 className="sg-translate font-normal text-center mt-4 2xl:text-[22px] max-w-[340px] px-3">
            With all stages under one roof, we minimize lead times, helping you
            meet your timelines without compromising on quality.
          </h5>
        </div>
        <Swiper
          centeredSlides
          slidesPerView={1}
          slidesPerGroup={1}
          loop
          pagination
          breakpoints={{
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            1024: {
              centeredSlides: true,
              slidesPerView: 2.1,
            },
            1280: {
              slidesPerGroup: 2,
              centeredSlides: true,
              slidesPerView: 3.5,
            },
            1440: {
              centeredSlides: true,
              slidesPerView: 3.8,
            },
          }}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={1500}
          modules={[Pagination]}
          spaceBetween={10}
          className="pb-8 pt-4 px-4"
        >
          {data.map((item, i) => {
            return (
              <SwiperSlide key={item.id} className="h-full">
                <Card
                  image={item.image}
                  title={item.title}
                  description={item.description}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>

        <div className="flex flex-wrap justify-center mt-4 gap-y-4 gap-x-6">
          {window.innerWidth >= 1024
            ? statsData.map((item) => {
                return (
                  <div key={item.id} className="flex items-center flex-col">
                    <h2 className="sg-translate font-semibold stats-text">
                      {item.number}
                    </h2>
                    <h6
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      className="sg-translate font-normal text-center max-w-[150px] text-sm xl:text-[16px]"
                    />
                  </div>
                )
              })
            : statsDataMobile.map((item) => (
                <div key={item.id} className="flex items-center flex-col">
                  <h2 className="sg-translate font-semibold stats-text">
                    {item.number}
                  </h2>
                  <h6
                    dangerouslySetInnerHTML={{ __html: item.description }}
                    className="sg-translate font-normal text-center max-w-[150px] text-sm xl:text-[16px]"
                  />
                </div>
              ))}
        </div>
      </div>
    </div>
  )
}

function Card({
  image,
  title,
  description,
}: {
  image: string
  title: string
  description: string
}) {
  return (
    <div className="h-full webkit-rounded">
      <div className="h-full overflow-hidden flex flex-col">
        <div className="rounded-2xl overflow-hidden webkit-rounded">
          <img src={image} alt={title} className="w-full h-56 object-cover" />
        </div>
        <div className="flex flex-col justify-between h-auto flex-1">
          <div className="relative h-full flex py-2 flex-col justify-start items-start gap-2 md:gap-4">
            <h3 className="sg-translate text-black text-xl lg:text-2xl xlg:text-3xl font-semibold">
              {title}
            </h3>

            <h5 className="sg-translate text-black pr-6 font-light">
              {description}
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}
