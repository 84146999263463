import React, { useState } from 'react'
import arrow from 'assets/general_nonwoven/ecoystems/arrow.svg'
import mapping from 'assets/general_nonwoven/ecoystems/mapping.svg'
import materialSelection from 'assets/general_nonwoven/ecoystems/material-section.svg'
import materialDevelopment from 'assets/general_nonwoven/ecoystems/material-development.svg'
import pilotProduction from 'assets/general_nonwoven/ecoystems/pilot.svg'
import productEnhancements from 'assets/general_nonwoven/ecoystems/compliance.svg'
import productionTrials from 'assets/general_nonwoven/ecoystems/large-scale-dev.svg'

import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/pagination'

export default function EnableEcosystem() {
  const [mobileView, setMobileView] = useState<any>(
    data.filter((el) => el.id % 2 !== 0),
  )

  return (
    <section className="blade-top-padding-xl blade-bottom-padding-xl">
      <div className="grid place-content-center text-center md:px-4 px-2 gap-3 blade-bottom-padding-sm">
        <h2 className="sg-translate font-light leading-snug">
          <span className="font-semibold">Enabling an ecosystem</span> of{' '}
          <br className="md:block hidden" /> meaningful customisation
        </h2>
      </div>
      {/* desktop view */}
      <div className="lg:block hidden">
        <div
          className="px-4 xl:w-container-sm grid grid-cols-1 lg:grid-cols-5
         md:gap-0 gap-4"
        >
          {data.slice(0, 5).map((item, ele) => {
            const { title, icon, id, description } = item
            return (
              <Card
                key={id}
                title={title}
                icon={icon}
                imgAlt={title}
                description={description}
              />
            )
          })}
        </div>
        <div
          className="px-4 xl:w-container-sm flex md:justify-end justify-center 2xl:pr-36 xlg:pr-32 
          xl:pr-28 lg:pr-[110px] my-6"
        >
          <div className="grid">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="79"
              viewBox="0 0 10 79"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.29242 71.1855H9.66587L5.23821 78.7758L0.810547 71.1855H4.18402V0.764893H6.29242V71.1855Z"
                fill="#40B6E8"
              />
            </svg>
          </div>
        </div>
        <div
          className="px-4 xl:w-container-sm grid grid-cols-1 lg:grid-cols-5 
        md:grid-cols-[180px_100px_180px_100px_180px] md:gap-0 gap-4"
        >
          {data
            .slice(6, 11)
            .reverse()
            .map((item) => {
              const { title, icon, id, description } = item
              return (
                <Card
                  key={id}
                  title={title}
                  icon={icon}
                  imgAlt={title}
                  description={description}
                  reverse
                />
              )
            })}
        </div>
      </div>

      {/* Mobile view */}
      <div className="max-w-screen-lg lg:hidden grid grid-cols-1 md:gap-0 gap-4">
        <div className="lg:hidden block w-full pt-6 overflow-hidden">
          <Swiper
            className="!pb-10"
            speed={1500}
            navigation={{
              nextEl: '.next-btn',
              prevEl: '.prev-btn',
              disabledClass: 'swiper-button-disabled',
            }}
            modules={[Navigation, Pagination]}
            pagination
            spaceBetween={0}
            loop={false}
            slidesPerView={1}
          >
            {mobileView.map((elem: any, index: any) => {
              return (
                <SwiperSlide key={elem.id} className="relative">
                  <div className="min-h-full md:px-14 px-8">
                    <img
                      src={elem.icon}
                      alt={elem.title}
                      className="h-32 mx-auto"
                    />
                    <h4 className="sg-translate font-semibold text-center mt-4">
                      {elem.title}
                    </h4>
                    <h5 className="sg-translate mt-2 text-base text-center">
                      {elem.description}
                    </h5>
                  </div>

                  <div className="text-white absolute h-full top-20 right-2">
                    <svg
                      width="64"
                      height="9"
                      viewBox="0 0 64 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M57.1691 0L64 4.55421L57.1691 9V5.63859H0.5V3.46991H57.1691V0Z"
                        fill="#40B6E8"
                      />
                    </svg>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

function Card({
  title,
  imgAlt,
  icon,
  reverse,
  description,
}: {
  title?: string
  description?: string
  icon?: string
  imgAlt?: string
  reverse?: boolean
}) {
  return (
    <article className="flex flex-col relative">
      <div className="grid place-content-center">
        {icon ? (
          <div className="group">
            <img
              className="lg:w-[80%] mx-auto md:w-24 w-20"
              src={icon}
              alt={imgAlt ?? title}
            />
            {/* <div
              className="xl:w-52 w-44 min-h-[12rem] translate-y-5 transition-all duration-500 
            group-hover:translate-y-0 group-hover:opacity-100 opacity-0 absolute -top-20 xl:left-32 left-0 z-10 
            bg-[#F6F6F6] 
            rounded-md p-5"
            >
              <h6 className="sg-translate ">{description}</h6>
            </div> */}
          </div>
        ) : (
          <div className="w-full h-full 2xl:pt-40 xl:pt-36 md:pt-32">
            {/* tab and desktop view */}
            <img
              className={`md:block hidden w-full h-full ${
                reverse ? 'rotate-180' : ''
              }`}
              src={arrow}
              alt={imgAlt ?? title}
            />
            {/* mobile view */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="79"
              viewBox="0 0 10 79"
              fill="none"
              className="md:hidden block"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.29242 71.1855H9.66587L5.23821 78.7758L0.810547 71.1855H4.18402V0.764893H6.29242V71.1855Z"
                fill="#40B6E8"
              />
            </svg>
          </div>
        )}
      </div>
      {title ? (
        <h5 className="text-center text-sm lg:text-base 2xl:text-xl 2xl:mt-5 mt-3 font-medium px-3 max-w-xs mx-auto">
          <span className="sg-translate">{title}</span>
        </h5>
      ) : (
        ''
      )}

      {description ? (
        <h5 className="text-center text-sm 2xl:text-base mt-2 font-medium px-3">
          <span className="sg-translate">{description}</span>
        </h5>
      ) : (
        ''
      )}
    </article>
  )
}

const data = [
  {
    icon: mapping,
    title: 'Requirement mapping',
    description:
      'Analyze and understand unique performance requirements and define criteria for customization.',
    id: 1,
  },
  {
    id: 2,
  },
  {
    icon: materialSelection,
    title: 'Material selections',
    description:
      'Choose the right materials from our extensive range to meet specific application demands.',
    id: 3,
  },
  {
    id: 4,
  },
  {
    icon: materialDevelopment,
    title: 'Product development',
    description:
      'Develop specialized products engineered to deliver on performance and quality.',
    id: 5,
  },
  {
    id: 6,
  },
  {
    icon: pilotProduction,
    title: ' Pilot production',
    description:
      'Conduct small-scale production runs to refine and perfect the product before full-scale manufacturing.',
    id: 7,
  },
  {
    id: 8,
  },
  {
    icon: productEnhancements,
    title: 'Stringent compliance & product enhancements',
    description:
      'Ensure all products meet regulatory standards while continuously seeking improvements.',
    id: 9,
  },
  {
    id: 10,
  },
  {
    icon: productionTrials,
    title: 'Large-scale production trials',
    description:
      'Efficiently transition from pilot to large-scale production while maintaining consistency and quality.',
    id: 11,
  },
]
