import React, { useEffect, useRef, useState } from 'react'

import { Navigation, EffectFade, Controller, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'

import ThermalBond from 'assets/apparel/solutions/thermalBond.webp'
import ChemicalBond from 'assets/apparel/solutions/ChemicalBond.webp'
import NeedlePunched from 'assets/apparel/solutions/NeedlePunched.webp'
import Wadding from 'assets/apparel/solutions/wadding.webp'
import Knitted from 'assets/apparel/solutions/knitted.webp'
import Woven from 'assets/apparel/solutions/woven.webp'

export default function Solutions() {
  const [current, setCurrent] = useState(0)

  const swiperCardRef = useRef<any>()
  const swiperButtonRef = useRef<any>()
  const [activeIndex, setActiveIndex] = useState(0)
  const [swiperCardInstance, setSwiperCardInstance] = useState<any>(null)
  const [swiperButtonInstance, setSwiperButtonInstance] = useState<any>(null)

  useEffect(() => {
    if (swiperButtonRef?.current && swiperCardRef?.current) {
      setSwiperButtonInstance(swiperButtonRef.current.swiper)
      setSwiperCardInstance(swiperCardRef.current.swiper)
    }
  }, [])

  return (
    <section className="bg-blueDark blade-top-padding-sm blade-bottom-padding-lg">
      <div className="grid place-content-center pt-5 xl:pt-8 2xl:pt-10 place-items-center text-center blade-bottom-padding-sm gap-4 xl:gap-6">
        <h2 className="sg-translate font-light text-white px-3">
          Comprehensive portfolio of <br className="sm:block hidden" />
          <span className="font-medium">interlining</span> solutions
        </h2>
      </div>
      <div className="max-w-[1700px] mx-auto relative">
        <button
          disabled={current === 0}
          type="button"
          className="appearal-solutions-prev-btn  
            absolute top-1/2 -translate-y-[80%] z-10 left-[10%] lg:left-[5%] xl:left-[10%]
            opacity-100 disabled:opacity-0 disabled:pointer-events-none aspect-square border-1 border-white border-solid rounded-full hover:bg-white hover:text-black transition-all duration-200 ease-in-out focus-visible:bg-white focus-visible:text-black focus-visible:outline-white outline-offset-2 outline outline-transparent text-white font-semibold 2xl:text-sm md:h-14 lg:h-16 xl:h-20 2xl:h-24 md:block hidden"
        >
          <span className="sg-translate">Prev</span>
        </button>
        <button
          disabled={current === 0}
          type="button"
          className="appearal-solutions-prev-btn 
          absolute top-1/2 -translate-y-full z-10 left-[8%] 
          opacity-100 disabled:opacity-0 disabled:pointer-events-none aspect-square border-1 border-white border-solid 
          rounded-full hover:bg-white hover:text-black transition-all duration-200 ease-in-out focus-visible:bg-white focus-visible:text-black focus-visible:outline-white outline-offset-2 outline outline-transparent md:text-white text-black font-semibold 2xl:text-sm md:h-14 lg:h-16 xl:h-20 2xl:h-24 md:hidden block bg-white md:bg-opacity-20 p-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
        </button>
        <button
          disabled={current === 5}
          type="button"
          className="disabled:hidden appearal-solutions-next-btn 
          absolute top-1/2 -translate-y-[80%] z-10 right-[10%] lg:right-[5%] xl:right-[10%]
          opacity-100  disabled:pointer-events-none aspect-square border-1 border-white border-solid rounded-full 
          hover:bg-white hover:text-black transition-all duration-200 ease-in-out focus-visible:bg-white 
          focus-visible:text-black focus-visible:outline-white outline-offset-2 outline outline-transparent 
          text-white font-semibold 2xl:text-sm md:h-14 lg:h-16 xl:h-20 2xl:h-24  md:block hidden"
        >
          <span className="sg-translate">Next</span>
        </button>
        <button
          disabled={current === 5}
          type="button"
          className="disabled:hidden appearal-solutions-next-btn 
          absolute top-1/2 -translate-y-full z-10 right-[8%] 
          opacity-100 disabled:opacity-0  disabled:pointer-events-none aspect-square border-1 border-white border-solid rounded-full hover:bg-white hover:text-black transition-all duration-200 ease-in-out focus-visible:bg-white focus-visible:text-black focus-visible:outline-white outline-offset-2 outline outline-transparent text-black md:text-white font-semibold 2xl:text-sm  p-2 md:hidden block bg-white md:bg-opacity-20"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </button>
        <Swiper
          slidesPerView={1}
          navigation={{
            prevEl: '.appearal-solutions-prev-btn',
            nextEl: '.appearal-solutions-next-btn',
          }}
          modules={[Navigation, EffectFade, Controller, A11y]}
          onSlideChange={(swiper) => setCurrent(swiper.realIndex)}
          ref={swiperCardRef}
          onSwiper={swiperCardInstance}
          controller={{
            control: swiperButtonInstance,
          }}
          speed={500}
          effect="fade"
          // allowTouchMove={false}
          className="px-3.5 max-w-lg lg:max-w-3xl 2xl:max-w-5xl mx-auto cursor-grab "
        >
          <SwiperSlide className="h-auto">
            <Slide coverPoster={ThermalBond} />
          </SwiperSlide>
          <SwiperSlide className="h-auto">
            <Slide coverPoster={ChemicalBond} />
          </SwiperSlide>
          <SwiperSlide className="h-auto">
            <Slide coverPoster={NeedlePunched} />
          </SwiperSlide>
          <SwiperSlide className="h-auto">
            <Slide coverPoster={Wadding} />
          </SwiperSlide>
          <SwiperSlide className="h-auto">
            <Slide coverPoster={Knitted} />
          </SwiperSlide>
          <SwiperSlide className="h-auto">
            <Slide coverPoster={Woven} />
          </SwiperSlide>
        </Swiper>
        <div className="relative pt-3 lg:pt-4 ">
          <Swiper
            centeredSlides
            initialSlide={0}
            slidesPerView="auto"
            modules={[Navigation, Controller, A11y]}
            onSlideChange={(swiper) => setCurrent(swiper.realIndex)}
            speed={500}
            ref={swiperButtonRef}
            onSwiper={swiperButtonInstance}
            controller={{
              control: swiperCardInstance,
            }}
            // slideToClickedSlide
            allowTouchMove
            slideNextClass="!font-light !text-white"
            slidePrevClass="!font-light !text-white"
            slideActiveClass="font-medium"
            className="appearal-text-slide swiper-image-mask px-3 select-none cursor-grab max-w-lg lg:max-w-3xl 2xl:max-w-5xl mx-auto  text-white text-center text-xl xl:text-2xl 2xl:text-[1.75rem]"
          >
            <SwiperSlide className="grid place-content-center text-center w-fit px-10 lg:px-16 ">
              <span className="sg-translate">Thermal Bond</span>
            </SwiperSlide>
            <SwiperSlide className="grid place-content-center text-center w-fit px-10 lg:px-16 ">
              <span className="sg-translate">Chemical Bond</span>
            </SwiperSlide>
            <SwiperSlide className="grid place-content-center text-center w-fit px-10 lg:px-16 ">
              <span className="sg-translate"> Needle Punched</span>
            </SwiperSlide>
            <SwiperSlide className="grid place-content-center text-center w-fit px-10 lg:px-16 ">
              <span className="sg-translate">Wadding</span>
            </SwiperSlide>
            <SwiperSlide className="grid place-content-center text-center px-1 w-44 md:w-48 lg:w-64">
              <span className="sg-translate">Knitted</span>
            </SwiperSlide>
            <SwiperSlide className="grid place-content-center text-center w-fit px-10 lg:px-16 ">
              <span className="sg-translate">Woven</span>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

function Slide({
  cover,
  coverPoster,
}: {
  cover?: string
  coverPoster: string
}) {
  return (
    <article className="relative h-60 md:h-64 lg:h-[20rem] xl:h-[22rem] xlg:h-96 2xl:h-[30rem]">
      <div className="absolute inset-0 rounded-xl md:rounded-xl  xl:rounded-2xl 2xl:rounded-3xl overflow-hidden">
        <video
          autoPlay
          muted
          poster={coverPoster}
          playsInline
          loop
          className="h-full w-full object-cover object-center"
        >
          <source src={cover} type="video/mp4" />
        </video>
      </div>
    </article>
  )
}
