import React, { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useLanguage } from 'context/languageContext'

type Payload = {
  Text: string
}

let prevLang = 'en'
let prevPage = ''

export default function LangConverter() {
  const { language, pageData, setPageData, dropdownText, setDropdownText } =
    useLanguage()
  const location = useLocation()

  const changeContent = useCallback(
    (elemRefs: any[], translations: string[]) => {
      // console.log(translations)
      elemRefs.forEach((elem: any, i: number) => {
        elem.innerHTML = translations[i]
      })
    },
    [],
  )

  const langConvert = useCallback(
    (lang: string, pageName: string, elemRefs: any[], payload: Payload[]) => {
      const dropdownKeys = Object.keys(dropdownText[lang])
      const isTranslationExists =
        dropdownText[lang][dropdownKeys[0]].length !== 0

      if (!isTranslationExists) {
        const dropdownPayload: Payload[] = []
        dropdownKeys.forEach((key) => {
          dropdownPayload.push({
            Text: dropdownText.en[key],
          })
        })
        payload.unshift(...dropdownPayload)
      }

      const url = `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=${lang}&textType=html`

      console.log('request')

      fetch(url, {
        method: 'POST',
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_MS_KEY as string,
          'Content-Type': 'application/json; charset=UTF-8',
          'Ocp-Apim-Subscription-Region': process.env
            .REACT_APP_MS_REGION as string,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((res) => {
          // console.log('Res: ', res)
          if (!Array.isArray(res)) {
            throw new Error('Response reading error')
          }
          const translatedTexts = res?.map(
            (item: any) => item?.translations?.[0]?.text,
          )

          if (!translatedTexts) {
            throw new Error('Fetch error')
          }

          if (!isTranslationExists) {
            const dropdowntranslatedTextsArray = translatedTexts.splice(
              0,
              dropdownKeys.length,
            )
            const dropdownTranslatedTexts: any = {}
            dropdownKeys.forEach((key, index) => {
              dropdownTranslatedTexts[key] = dropdowntranslatedTextsArray[index]
            })

            console.log(dropdownTranslatedTexts, 'translation')

            setDropdownText({
              ...dropdownText,
              [lang]: dropdownTranslatedTexts,
            })
          }

          // console.log(lang)
          setPageData((prev) => {
            return {
              ...prev,
              [pageName]: {
                ...prev[pageName],
                [lang]: translatedTexts,
              },
            }
          })

          changeContent(elemRefs, translatedTexts)
          // console.log('Translation Results:', translatedTexts)
          console.log('Actual payload:', payload)
        })
        .catch((error) => {
          console.error('Error during translation:', error)
        })
    },
    [],
  )

  useEffect(() => {
    const isLangChanged = localStorage.getItem('sg-lang-changed')
    const pageName =
      location.pathname.split('/').at(-1)?.toLowerCase() || 'home'
    const enData: string[] = []
    const payload: Payload[] = []

    if (!pageData[pageName]) {
      return
    }

    const elemRefs: any =
      document.querySelectorAll<HTMLElement>('.sg-translate')
    if (!pageData[pageName].en.length) {
      elemRefs.forEach((elem: any) => {
        enData.push(elem.innerHTML || '')
      })

      setPageData((prev) => {
        return {
          ...prev,
          [pageName]: {
            ...pageData[pageName],
            en: enData,
          },
        }
      })
    }

    if (!isLangChanged) {
      // No need to fetch because default is english and it is not changed yet
      // console.log('No need to do fetch translation')
      return
    }

    if (language === 'en' && isLangChanged) {
      // Language switched back to english
      changeContent(elemRefs, pageData[pageName].en)
      // console.log('Lang changed to english')
      return
    }

    if (pageData[pageName][language].length) {
      // Translation is already fetched and we just need to assign to content
      changeContent(elemRefs, pageData[pageName][language])

      // console.log(
      //   'Translation is fetched already: \nTranslations found: ',
      //   pageData[pageName][language].length,
      // )
      return
    }

    if (prevLang === language && prevPage === pageName) {
      // prevent re-executions
      // console.log('Skipping fetch', prevPage)
      return
    }

    // console.log('Fetching language')

    // Creating payload from english data to get constant results each and everytime
    if (enData.length) {
      // console.log('EnData: ', enData[3])
      enData?.forEach((elem: any) => {
        payload.push({
          Text: elem || '',
        })
      })
    } else if (pageData[pageName].en.length) {
      pageData[pageName].en.forEach((elem: any) => {
        payload.push({
          Text: elem,
        })
      })
    }

    // console.log(payload)

    prevLang = language
    prevPage = pageName
    langConvert(language, pageName, elemRefs, payload)
  }, [language, location.pathname, pageData])

  return null
}
