import j_1996 from 'assets/who-we-are/journey/1996.jpg'
import j_2006 from 'assets/who-we-are/journey/2006.jpg'
import j_2007 from 'assets/who-we-are/journey/2007.jpg'
import j_2017 from 'assets/who-we-are/journey/2017.jpg'
import j_2019 from 'assets/who-we-are/journey/2019.jpg'

import j_01 from 'assets/who-we-are/journey/01.jpg'
import j_02 from 'assets/who-we-are/journey/02.jpg'
import j_03 from 'assets/who-we-are/journey/03.jpg'
import j_04 from 'assets/who-we-are/journey/04.jpg'

export const timelineData = [
  {
    id: 0,
    coverImage: j_01,
    cardtitle: 'The Foundation of Our Success',
    timeRange: ['1986', '1995'],
    slideTitle: 'The Foundation of Our Success',
    slideSubtitle: 'Values, Principles, and Choices',
    slideDescription: 'Cultivating Excellence',
    slideDescription1: 'Founding Values and Offering a Distinct Proposition',
    list: [
      'Inception with strong values of integrity, quality, and innovation.',
      'Initial focus on industrial filtration, orthopedic cast padding, and automotive mats.',
      'Pioneered India’s first Geo-tubes installation for railway infrastructure.',
      'Established relations with leading Indian and global suppliers and customers.',
    ],
    locations: ['1986 - Bhilad', '1992 - Daman'],
  },
  {
    id: 1,
    coverImage: j_02,
    cardtitle: 'Card Title',
    timeRange: ['1996', '2005'],
    slideTitle: 'Building Momentum',
    slideSubtitle: 'Resilience and Dedication',
    slideDescription: 'Broadening Capabilities',
    slideDescription1: 'Strengthening Competence and Diversifying Partnerships',
    list: [
      'Significant growth and transformation.',
      'Strengthened ties with existing automotive partners and formed new relationships with global players.',
      'Invested in material science, design, development, and tooling expertise.',
      'Overcame early challenges and established a reputation for reliability and technical prowess.',
    ],
    locations: ['2005 - Vapi, Irrungattukottai'],
  },
  {
    id: 2,
    coverImage: j_03,
    cardtitle: 'Card Title',
    timeRange: ['2006', '2015'],
    slideTitle: 'Scaling New Heights',
    slideSubtitle: 'Commitment to Quality and Service',
    slideDescription: 'Expanding Horizons',
    slideDescription1: 'Elevating Standards and Global Presence',
    list: [
      'Rapid growth and commitment to quality and service.',
      'Expanded operations globally, increasing capacity and operational reach.',
      'Introduced customized interlining solutions for over 100 global fashion brands.',
      'Leadership in filtration solutions and received global recognition and awards.',
      'Established first overseas manufacturing unit in Thailand.',
    ],
    locations: [
      '2007 - Pantnagar , Halol',
      '2007 - Thailand Subsidiary',
      '2008 - Pune 1',
      '2009 - Bawal',
      '2010 - Sanand 1, Bidadi',
      '2011 - Oragadam',
      '2012 - Harohalli',
      '2015 - Sanand 2',
    ],
  },
  {
    id: 3,
    coverImage: j_04,
    cardtitle: 'Card Title',
    timeRange: ['2016', '2023'],
    slideTitle: 'Driving Innovation ',
    slideSubtitle: 'Sustainability and Stability',
    slideDescription: 'Consolidating Gains',
    slideDescription1:
      'Building Core Stability and Embracing Sustainable Growth',
    list: [
      'Focus on consolidation and sustainability.',
      'Rationalized manpower, optimized operations through automation, and expanded R&D.',
      'Took 100% control of nonwovens and automotive business units.',
      'Established international interlining technical services and sales office in Dhaka, Bangladesh.',
      'Continued success in the automotive sector with supply from Thailand facility.',
      'Navigated challenges of COVID-19 with resilience and agility.',
      'Commitment to sustainability, innovation, and stability.',
    ],
    locations: [
      '2017 - Pune 2',
      '2019 - Rayong 1 - Thailand',
      '2020 - Rayong 2 - Thailand, Valvada',
      '2021 - Anantapur',
      '2022 - Jamshedpur',
      '2023 - Pune 3',
    ],
  },
  {
    id: 4,
    coverImage: j_2019,
    cardtitle: 'Card Title',
    timeRange: ['2024', 'Beyond'],
    slideTitle: 'Visionary Leadership',
    slideSubtitle: 'Embracing the Future',
    slideDescription: 'Innovating for Global Impact',
    slideDescription1: 'Pioneering Sustainability and Advanced Solutions',
    list: [
      'Continued and reinforced focus on innovation and sustainability.',
      'Global expansion through new plants, strategic partnerships, and acquisitions.',
      'Investing in cutting-edge technologies and eco-friendly practices.',
      'Enhancing R&D capabilities and fostering a culture of continuous innovation.',
      'Prioritizing employee development and well-being.',
      'Confidently aiming for global impact and industry leadership.',
    ],
    locations: [],
  },
]
