import React from 'react'
import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'

function JoinUs() {
  const navigate = useNavigate()

  return (
    <section
      className="h-80 md:h-96 lg:h-[28rem] xl:h-[30rem] xlg:h-[34rem] grid place-content-center text-center 
    place-items-center gap-5 xlg:gap-8 xl:gap-10 2xl:gap-6 relative bg-cover bg-center 
    bg-[url('assets/sustainability/subfooter_bg.jpg')]"
    >
      <div className="absolute top-0 w-full h-full bg-black opacity-70" />
      <h2 className="sg-translate font-semibold leading-tight text-white z-10">
        Join our journey
      </h2>
      <p className="sg-translate font-light z-10 text-base lg:text-lg text-white max-w-[480px] leading-snug block px-2">
        We invite all stakeholders to engage with our ESG journey. Whether
        you’re a customer, employee, investor, or community member, your support
        and collaboration are invaluable as we strive for a sustainable future.
      </p>

      {/* Below part is a client change */}
      <HeroBtn
        size="small"
        text="Contact us"
        color="blueLight"
        onClick={() => navigate('/contact-us')}
        type="button"
      />
      {/* <span className="h-[2px] z-10 w-20 bg-white" />
      <p className="font-normal z-10 text-base lg:text-lg text-white">
        For more information and to join us in making a difference, <br />{' '}
        contact us -{' '}
        <a
          href="mailto: info@supremegroup.co.in"
          className="text-green font-semibold"
        >
          info@supremegroup.co.in
        </a>
      </p> */}
    </section>
  )
}
export default JoinUs
