import React, { ReactNode } from 'react'
import { NavLink, Link } from 'react-router-dom'

const sizeMap = {
  small: '2xl:text-base text-sm',
  base: 'xl:text-base text-sm',
  large: ' text-base md:text-lg',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}

const colorMap = {
  dark: 'text-black',
  light: 'text-white',
}
type MasterAnchorProps = {
  size?: keyof typeof sizeMap
  text: string
  href: string
  target?: '_self' | '_blank'
  className?: string
}
type ExternalAnchorProps = {
  size?: keyof typeof sizeMap
  href: string
  target?: '_self' | '_blank'
  className?: string
}

export function TextAnchor({
  text,
  target,
  href,
  color = 'dark',
  size = 'base',
  className,
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <Link
      target={target}
      to={href}
      {...rest}
      className={`${sizeClass} ${colorClass} ${className} text-opacity-70 hover:text-opacity-100 focus:outline-none 
                decoration-from-font underline-offset-4 focus:text-opacity-100`}
    >
      <span className="sg-translate">{text}</span>
    </Link>
  )
}

export function TextNavAnchor({
  text,
  target,
  href,
  color = 'dark',
  size = 'base',
  className,
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <NavLink
      target={target}
      to={href}
      {...rest}
      className={(navData) => {
        return `${
          navData.isActive ? 'text-blue' : 'text-black'
        } ${className} ${sizeClass} ${colorClass} hover:text-blue font-normal transition-all duration-300`
      }}
    >
      <span className="sg-translate">{text}</span>
    </NavLink>
  )
}
export function TextNavbar({
  text,
  target,
  href,
  color = 'dark',
  size = 'base',
  className,
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <NavLink
      target={target}
      to={href}
      {...rest}
      className={(navData) => {
        return `${
          navData.isActive ? 'text-blue' : 'text-black'
        } ${className} ${sizeClass} ${colorClass} hover:text-blue font-normal transition-all duration-300 relative block text-left`
      }}
    >
      <span>{text}</span>

      {/* <span className="absolute inset-0 bottom-0 bg-blue h-1 w-full z-10" /> */}
    </NavLink>
  )
}

// export function HeroAnchor({
//   text,
//   size = 'base',
//   href,
//   target,
//   ...rest
// }: MasterAnchorProps) {
//   const sizeClass: string = sizeMap[size]
//   return (
//     <Link to={href} target={target} {...rest} className={`${sizeClass} `}>
//       <span className="sg-translate">{text}</span>
//     </Link>
//   )
// }
// control the outline of anchor
export function Anchor({
  href,
  children,
  className = '',
}: {
  children: ReactNode
  className?: string
  href: string
}) {
  return (
    <Link className={className} to={href}>
      {' '}
      <span>{children}</span>
    </Link>
  )
}

export function ExternalAnchor({
  target,
  href,
  color = 'dark',
  size = 'base',
  className,
  children,
}: ExternalAnchorProps & { color?: 'dark' | 'light'; children?: ReactNode }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={` ${className} ${sizeClass} ${colorClass} hover:text-black font-normal transition-all duration-300`}
    >
      {children}
    </a>
  )
}
