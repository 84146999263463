import React, { useState, useRef, useEffect, useReducer } from 'react'
import logo from 'assets/globals/logo.svg'
import { HeroBtn } from 'atoms/buttons'
import DropdownLang from 'atoms/dropdownLang'
import { useNavigate } from 'react-router-dom'
import { Anchor, ExternalAnchor, TextNavAnchor } from 'atoms/links'
import './stylesOrganism.css'
import Dropdown from 'atoms/dropdown'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import MobileNavModal from './mobileNav'

gsap.registerPlugin(ScrollTrigger)

const applicationDropdownOptions = [
  {
    text: 'apparel',
    href: '/applications/apparel',
  },
  {
    text: 'automotive',
    href: '/applications/automotive',
  },
  {
    text: 'filtration',
    href: '/applications/filtration',
  },
  {
    text: 'customisedNonwoven',
    href: '/applications/customised-nonwoven',
  },
]
const companyDropdownOptions = [
  {
    text: 'whoWeAre',
    href: '/who-we-are',
  },
  {
    text: 'globalCompetency',
    href: '/global-competency',
  },
  // {
  //   text: 'Careers',
  //   href: '/careers',
  // },
]
export const langDropdownOptions = [
  {
    text: 'English',
    lang: 'en',
  },
  {
    text: 'Chinese',
    lang: 'zh-Hans',
  },
  {
    text: 'French',
    lang: 'fr',
  },
  {
    text: 'German',
    lang: 'de',
  },
  {
    text: 'Japanese',
    lang: 'ja',
  },
  {
    text: 'Spanish',
    lang: 'es',
  },
  {
    text: 'Thai',
    lang: 'th',
  },
]

export default function Navbar() {
  const [isNavFixed, setisNavFixed] = useState(false)
  const [isOpen, toggleState] = useReducer((s) => !s, false)

  const navigate = useNavigate()
  const goToContact = () => {
    navigate('/contact-us')
  }

  const headerWrapperRef = useRef(null)

  const handleScroll = () => {
    const scrollPos = window.scrollY
    if (scrollPos > 80) {
      setisNavFixed(true)
      // setIsOpenMenu(false)
    } else {
      setisNavFixed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    const showAnim = gsap
      .from(headerWrapperRef.current, {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1)
    ScrollTrigger.create({
      start: 'top top',
      end: 99999,
      onUpdate: (self) => {
        if (self.direction === -1) {
          showAnim.play()
        } else {
          showAnim.reverse()
        }
      },
    })
  }, [])

  return (
    <nav>
      <div
        ref={headerWrapperRef}
        id="header-wrapper"
        className={`${
          isNavFixed ? 'shadow-header backdrop-blur-3xl bg-white' : 'bg-white'
        } shadow-lg fixed top-0 left-0 right-0 z-[9999]`}
      >
        <header className="2xl:py-2 xlg:py-1 py-2 z-50">
          <div className="w-container h-full xlg:py-3 py-1 mt-[2px] flex gap-4 items-center justify-between m-auto">
            <Anchor href="/">
              <img
                src={logo}
                className="h-full !cursor-pointer"
                alt="Logo of supreme with text"
                loading="eager"
              />
            </Anchor>
            <ul className="list-none gap-x-4 xl:gap-6 2xl:gap-10 xl:flex items-center hidden pb-1 min-[1160px]gap-x-7">
              <Dropdown
                title="Application"
                options={applicationDropdownOptions}
              />
              <Dropdown title="Company" options={companyDropdownOptions} />
              <li>
                <TextNavAnchor
                  size="small"
                  color="dark"
                  text="Innovation"
                  className="font-medium"
                  href="/innovation"
                />
              </li>

              <li>
                <TextNavAnchor
                  size="small"
                  color="dark"
                  text="ESG Impact"
                  className="font-medium whitespace-nowrap"
                  href="/esg-impact"
                />
              </li>

              <li>
                <TextNavAnchor
                  size="small"
                  color="dark"
                  text="Careers"
                  className="font-medium"
                  href="/careers"
                />
              </li>
            </ul>

            <div className="w52 gap-4 2xl:gap-5 xl:flex justify-end items-center hidden">
              <HeroBtn
                role="link"
                size="small"
                color="blueLight"
                text="Contact Us"
                onClick={goToContact}
                type="button"
                isTranslate
              />

              <ExternalAnchor
                className="block fill-blue hover:bg-blue bg-white whitespace-nowrap group hover:border-blue border-blue border rounded-full"
                href="https://www.linkedin.com/company/supreme-group-company/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-[45px] p-2 group-hover:fill-white transition-all"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M4.98304 7.19704C6.19144 7.19704 7.17104 6.21744 7.17104 5.00904C7.17104 3.80065 6.19144 2.82104 4.98304 2.82104C3.77464 2.82104 2.79504 3.80065 2.79504 5.00904C2.79504 6.21744 3.77464 7.19704 4.98304 7.19704Z"
                    fill="inherit"
                  />
                  <path
                    d="M9.23697 8.85493V20.9939H13.006V14.9909C13.006 13.4069 13.304 11.8729 15.268 11.8729C17.205 11.8729 17.229 13.6839 17.229 15.0909V20.9949H21V14.3379C21 11.0679 20.296 8.55493 16.474 8.55493C14.639 8.55493 13.409 9.56193 12.906 10.5149H12.855V8.85493H9.23697ZM3.09497 8.85493H6.86997V20.9939H3.09497V8.85493Z"
                    fill="inherit"
                  />
                </svg>
              </ExternalAnchor>

              {/* <DropdownLang
                title=""
                options={langDropdownOptions}
                isMobile={false}
              /> */}
            </div>

            {/* Toggle button */}
            <button
              type="button"
              className="xl:hidden block"
              onClick={toggleState}
            >
              <svg
                strokeWidth="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                className="h-6 my-2 fill-blueDark"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </header>
      </div>
      <div
        className={`${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } transition z-[9999] duration-500 fixed inset-0 h-screen w-screen bg-black text-white`}
      >
        <MobileNavModal
          toggle={toggleState}
          data={[applicationDropdownOptions, companyDropdownOptions]}
        />
      </div>
    </nav>
  )
}
