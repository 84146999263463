import surgicalGowns from 'assets/general_nonwoven/solutions/accordian01/surgical gowns.jpg'
import drapes from 'assets/general_nonwoven/solutions/accordian01/drapes.jpg'
import mask from 'assets/general_nonwoven/solutions/accordian01/face masks.jpg'
import woundDressing from 'assets/general_nonwoven/solutions/accordian01/wound dressings.jpg'
import medicalSwipes from 'assets/general_nonwoven/solutions/accordian01/disposable medical wipes.jpg'
import surgicalGownsThumb from 'assets/general_nonwoven/solutions/accordian01/thumb (1).jpg'
import drapesThumb from 'assets/general_nonwoven/solutions/accordian01/thumb (2).jpg'
import maskThumb from 'assets/general_nonwoven/solutions/accordian01/thumb (3).jpg'
import woundDressingThumb from 'assets/general_nonwoven/solutions/accordian01/thumb (4).jpg'
import medicalSwipesThumb from 'assets/general_nonwoven/solutions/accordian01/thumb (5).jpg'

import diapers from 'assets/general_nonwoven/solutions/accordian02/diapers.jpg'
import diapersPink from 'assets/general_nonwoven/solutions/accordian02/diapers - pink.jpg'
import swipes from 'assets/general_nonwoven/solutions/accordian02/swipes.jpg'
import diapersThumb from 'assets/general_nonwoven/solutions/accordian02/thumb (1).jpg'
import diapersPinkThumb from 'assets/general_nonwoven/solutions/accordian02/thumb (2).jpg'
import swipesThumb from 'assets/general_nonwoven/solutions/accordian02/thumb (3).jpg'

import carPart from 'assets/general_nonwoven/solutions/accordian03/car-part.jpg'
import carBackSpace from 'assets/general_nonwoven/solutions/accordian03/car-back-space.jpg'
import carPartThumb from 'assets/general_nonwoven/solutions/accordian03/thumb1.jpg'
import carBackSpaceThumb from 'assets/general_nonwoven/solutions/accordian03/thumb2.jpg'

import covers from 'assets/general_nonwoven/solutions/accordian04/covers.jpg'
import coversThumb from 'assets/general_nonwoven/solutions/accordian04/thumb1.jpg'

import sheetRolled from 'assets/general_nonwoven/solutions/accordian05/sheet-rolled.jpg'
import sheetCutting from 'assets/general_nonwoven/solutions/accordian05/sheet-cutting.jpg'
import sheet from 'assets/general_nonwoven/solutions/accordian05/sheet.jpg'
import sheetRolledThumb from 'assets/general_nonwoven/solutions/accordian05/thumb1.jpg'
import sheetCuttingThumb from 'assets/general_nonwoven/solutions/accordian05/thumb2.jpg'
import sheetThumb from 'assets/general_nonwoven/solutions/accordian05/thumb3.jpg'

import filterClothes from 'assets/general_nonwoven/solutions/accordian06/filter-clothes.jpg'
import filterBags from 'assets/general_nonwoven/solutions/accordian06/filter-bags.jpg'
import filterCarMachinary from 'assets/general_nonwoven/solutions/accordian06/car-machinary.jpg'
import filterEnginePart from 'assets/general_nonwoven/solutions/accordian06/engine-part.jpg'
import filterEngineDust from 'assets/general_nonwoven/solutions/accordian06/dust-collection.jpg'

import filterClothesThumb from 'assets/general_nonwoven/solutions/accordian06/thumb1.jpg'
import filterbagsThumb from 'assets/general_nonwoven/solutions/accordian06/thumb2.jpg'
import filterCarThumb from 'assets/general_nonwoven/solutions/accordian06/thumb3.jpg'
import filterEngineThumb from 'assets/general_nonwoven/solutions/accordian06/thumb4.jpg'
import filterDustThumb from 'assets/general_nonwoven/solutions/accordian06/thumb5.jpg'

type ISlide = {
  src: string
  alt: string
  thumbSrc: string
}

type IAccordion = {
  title: string
  subtitle: string
  accordionSlides: Array<ISlide>
}

const slidesA: Array<ISlide> = [
  {
    src: surgicalGowns,
    thumbSrc: surgicalGownsThumb,
    alt: 'A person in a white gown and blue gloves holding a white cord.',
  },
  {
    src: drapes,
    thumbSrc: drapesThumb,
    alt: 'Hospital room with multiple beds and blue curtains.',
  },
  {
    src: mask,
    thumbSrc: maskThumb,
    alt: 'Image of a medical mask against a blue backdrop.',
  },
  {
    src: woundDressing,
    thumbSrc: woundDressingThumb,
    alt: "A doctor gently applies a bandage to a patient's hand, providing care and comfort.",
  },
  {
    src: medicalSwipes,
    thumbSrc: medicalSwipesThumb,
    alt: 'A person holding a medical swipe in their hand, ready to use it.',
  },
]

const slidesB: Array<ISlide> = [
  {
    src: diapers,
    thumbSrc: diapersThumb,
    alt: 'A stack of diapers on a blue background.',
  },
  {
    src: diapersPink,
    thumbSrc: diapersPinkThumb,
    alt: 'Pair of feminine hygiene products on pink background.',
  },
  {
    src: swipes,
    thumbSrc: swipesThumb,
    alt: "A baby's foot being gently cleaned with a soft cloth, ensuring proper hygiene and care.",
  },
]

const slidesC: Array<ISlide> = [
  {
    src: carPart,
    thumbSrc: carPartThumb,
    alt: 'Plastic car bumper cover with front end hole.',
  },
  {
    src: carBackSpace,
    thumbSrc: carBackSpaceThumb,
    alt: 'Interior of car trunk featuring black mat.',
  },
]

const slidesD: Array<ISlide> = [
  {
    src: covers,
    thumbSrc: coversThumb,
    alt: 'A row of white plastic covers on a field, protecting crops or plants.',
  },
]

const slidesE: Array<ISlide> = [
  {
    src: sheetRolled,
    thumbSrc: sheetRolledThumb,
    alt: 'A man rolling a large carpet on the ground.',
  },
  {
    src: sheetCutting,
    thumbSrc: sheetCuttingThumb,
    alt: 'A person skillfully cutting a piece of carpet with a tool, ensuring precise and clean edges.',
  },
  {
    src: sheet,
    thumbSrc: sheetThumb,
    alt: 'A man skillfully cuts a piece of carpet using a power tool, ensuring precise and efficient workmanship.',
  },
]

const slidesF: Array<ISlide> = [
  {
    src: filterClothes,
    thumbSrc: filterClothesThumb,
    alt: 'A trio of white and yellow filter cloths neatly arranged together.',
  },
  {
    src: filterBags,
    thumbSrc: filterbagsThumb,
    alt: 'Collection of filter bags.',
  },
  {
    src: filterCarMachinary,
    thumbSrc: filterCarThumb,
    alt: 'A car air filter, essential for clean air intake, is depicted in the image.',
  },
  {
    src: filterEnginePart,
    thumbSrc: filterEngineThumb,
    alt: 'A person holding a car filter in their hand, ready for use.',
  },
  {
    src: filterEngineDust,
    thumbSrc: filterDustThumb,
    alt: 'A person holding a car filter in their hand, ready for use.',
  },
]

export {
  slidesA,
  slidesB,
  slidesC,
  slidesD,
  slidesE,
  slidesF,
  type ISlide,
  type IAccordion,
}
