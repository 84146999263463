import React, { useEffect, useRef } from 'react'
import poster from 'assets/videos/home-banner.webp'
import bannerVideo from 'assets/videos/home-banner.mp4'

export default function Banner() {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.play()
      }
    }
  }, [])

  return (
    <div className="lg:min-h-[600px] lg:h-screen h-[450px] overflow-hidden bg-blueDark relative">
      <div className="absolute lg:min-h-[600px] lg:h-screen h-[450px] w-full inset-0 ">
        <div className="absolute inset-0">
          <video
            ref={videoRef}
            className="h-full w-full object-cover object-center"
            autoPlay
            muted
            playsInline
            loop
            poster={poster}
          >
            <source src={bannerVideo} type="video/mp4" />
          </video>
        </div>
        <div className="absolute inset-0 bg-black bg-opacity-40" />
      </div>

      <div className="absolute lg:min-h-[600px] lg:h-screen h-[450px] inset-0 flex flex-col pt-10 md:pt-0 items-center justify-center gap-2">
        <h4 className="sg-translate font-semibold text-white">
          Innovation at core
        </h4>
        <h2 className="sg-translate font-light text-center text-white px-3">
          Elevating industries with <br className="md:block hidden" />{' '}
          world-class <br className="sm:block hidden md:hidden" />{' '}
          <span className="font-semibold text-blue">nonwoven</span> solutions
        </h2>
      </div>

      <button
        type="button"
        className="absolute bottom-0 left-1/2 -translate-x-1/2 group outline-none focus-visible:scale-110 transition-transform duration-200 ease-linear "
        onClick={() => {
          window.scrollTo({
            behavior: 'smooth',
            top: videoRef?.current?.scrollHeight,
          })
        }}
      >
        <svg
          className="h-12 md:h-14 xl:h-16 2xl:h-20 group-hover:scale-110 cursor-pointer transition-transform duration-200 ease-linear "
          viewBox="0 0 241 117"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M239.372 115.378C239.219 100.697 236.154 86.1755 230.339 72.6023C224.371 58.6719 215.622 46.0118 204.591 35.346C193.559 24.6801 180.46 16.2174 166.041 10.443C151.622 4.66864 136.167 1.69614 120.558 1.69614C104.949 1.69614 89.4933 4.66864 75.0744 10.443C60.6556 16.2174 47.5568 24.6801 36.525 35.346C25.4932 46.0118 16.7445 58.6719 10.7764 72.6023C4.96131 86.1755 1.89651 100.697 1.7432 115.378L239.372 115.378ZM240.531 115.378C240.378 100.539 237.281 85.8626 231.404 72.1458C225.375 58.0721 216.537 45.2843 205.396 34.5127C194.255 23.7411 181.029 15.1966 166.472 9.36708C151.915 3.53754 136.314 0.537109 120.558 0.537109C104.802 0.537109 89.2001 3.53754 74.6435 9.36709C60.0869 15.1966 46.8605 23.7411 35.7193 34.5127C24.5782 45.2843 15.7406 58.0721 9.71103 72.1458C3.83445 85.8626 0.737462 100.539 0.584114 115.378C0.580122 115.764 0.578125 116.151 0.578125 116.537L240.537 116.537C240.537 116.151 240.535 115.764 240.531 115.378Z"
            fill="url(#paint0_linear_1674_10898)"
          />
          <path
            d="M107.578 59.8164L120.395 72.6334L133.212 59.8164"
            stroke="white"
            strokeWidth="3.09074"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1674_10898"
              x1="120.558"
              y1="0.537109"
              x2="120.558"
              y2="116.537"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00BFFF" />
              <stop offset="1" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </button>
    </div>
  )
}
