import React, { useEffect, useState } from 'react'
import './automotive.css'

import LogoMarquee from 'organisms/logoMarquee'
import { partnersLogos } from 'static/partners'

export default function Partners() {
  const [automotiveData, setAutomotive] = useState<any>([])

  useEffect(() => {
    const filteredData = partnersLogos.filter(
      (item: any) => item.category === 'automotive',
    )
    setAutomotive(filteredData)
  }, [])
  return (
    <div className="blade-top-padding w-container-lg">
      <h2 className="sg-translate px-3 text-center font-light">
        Our Customers
      </h2>
      <div className="2xl:pt-10 xl:pt-8 pt-4">
        <section className="flex justify-center items-center flex-wrap max-w-screen-xl mx-auto">
          <LogoMarquee
            logos={automotiveData}
            largeText
            gridStyle="lg:grid-cols-4 grid-cols-3 md:gap-6 gap-4"
          />
        </section>
      </div>
    </div>
  )
}

// const data = [
//   {
//     id: 1,
//     logo: logo18,
//     title: 'ashok leyland',
//     category: 'automotive',
//     alt: 'ashok-logo',
//   },
//   {
//     id: 2,
//     logo: logo2,
//     title: 'auto alliance',
//     category: 'automotive',
//     alt: 'auto-alliance-logo',
//   },
//   {
//     id: 3,
//     logo: logo3,
//     title: 'citroen',
//     category: 'automotive',
//     alt: 'citroen-logo',
//   },
//   {
//     id: 4,
//     logo: logo4,
//     title: 'daimler',
//     category: 'automotive',
//     alt: 'daimler-logo',
//   },
//   {
//     id: 5,
//     logo: logo5,
//     title: 'force',
//     category: 'automotive',
//     alt: 'force-logo',
//   },
//   {
//     id: 6,
//     logo: logo6,
//     title: 'ford',
//     category: 'automotive',
//     alt: 'ford-logo',
//   },
//   {
//     id: 7,
//     logo: logo7,
//     title: 'honda',
//     category: 'automotive',
//     alt: 'honda-logo',
//   },
//   {
//     id: 8,
//     logo: logo8,
//     title: 'hyundai',
//     category: 'automotive',
//     alt: 'hyundai-logo',
//   },
//   {
//     id: 9,
//     logo: logo10,
//     title: 'jeep',
//     category: 'automotive',
//     alt: 'jeep-logo',
//   },
//   {
//     id: 10,
//     logo: logo11,
//     title: 'john deere',
//     category: 'automotive',
//     alt: 'john-deere-logo',
//   },
//   {
//     id: 11,
//     logo: logo1,
//     title: 'kia',
//     category: 'automotive',
//     alt: 'kia-logo',
//   },
//   {
//     id: 12,
//     logo: logo12,
//     title: 'mahindra',
//     category: 'automotive',
//     alt: 'mahindra-logo',
//   },
//   {
//     id: 13,
//     logo: logo13,
//     title: 'maruti suzuki',
//     category: 'automotive',
//     alt: 'maruti-logo',
//   },
//   {
//     id: 14,
//     logo: logo9,
//     title: 'morris garages',
//     category: 'automotive',
//     alt: 'morris-garages-logo',
//   },
//   {
//     id: 15,
//     logo: logo14,
//     title: 'nissan',
//     category: 'automotive',
//     alt: 'nissan-logo',
//   },
//   {
//     id: 16,
//     logo: logo15,
//     title: 'renault',
//     category: 'automotive',
//     alt: 'renault-logo',
//   },
//   {
//     id: 17,
//     logo: logo16,
//     title: 'tata motors',
//     category: 'automotive',
//     alt: 'tata-logo',
//   },
//   {
//     id: 18,
//     logo: logo17,
//     title: 'volvo',
//     category: 'automotive',
//     alt: 'volvo-logo',
//   },
// ]
