import { HeroBtn } from 'atoms/buttons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Subfooter() {
  const navigate = useNavigate()
  return (
    <section
      className="h-72 lg:h-80 xl:h-[28rem] xlg:h-[30rem] 2xl:h-[34rem] grid place-content-center 
      text-center place-items-center gap-5 xlg:gap-8  xl:gap-10 2xl:gap-12 bg-cover
      bg-center bg-[url('assets/innovation/subfooter_bg.jpg')] relative
      before:absolute before:content-[''] before:h-full before:w-full before:bg-black before:bg-opacity-70
      "
    >
      <h2 className="sg-translate font-light z-10 leading-tight text-white px-3">
        <strong className="font-semibold">
          {' '}
          Your challenges, our solutions.
        </strong>{' '}
        <br className="hidden md:block" />
        Discover what’s possible with us.
      </h2>

      <HeroBtn
        size="small"
        text="Contact us"
        color="blueLight"
        onClick={() => navigate('/contact-us')}
        type="button"
      />
    </section>
  )
}
