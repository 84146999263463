import React, { useEffect, useRef } from 'react'
import bannerVideo from 'assets/videos/sustainability.mp4'
import bannerPoster from 'assets/videos/sustainability.png'
import PageBanner from 'organisms/pageBanner'
import { About } from './about'
import { GovernanceAndEthics } from './governance&Ethics'
import { CaseStudy } from './caseStudy'
import { SocialResponsibility } from './socialResponsibility'
import JoinUs from './joinUs'
import { Environmental } from './environmental'

export default function Sustainability() {
  const mainRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
    mainRef.current?.scrollIntoView()
  }, [])

  return (
    <div ref={mainRef}>
      <div>
        <PageBanner
          isVideo
          bgVideo={bannerVideo}
          videoPoster={bannerPoster}
          title="ESG at <span class='text-blue font-semibold'>Supreme Nonwovens</span> <br /> Commitment to sustainable excellence"
        />
      </div>
      <About />
      <Environmental />
      <CaseStudy />
      <SocialResponsibility />
      <GovernanceAndEthics />
      <JoinUs />
    </div>
  )
}
