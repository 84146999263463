import React from 'react'
import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'
import bg from 'assets/automotive/subfooter_bg.png'
import './automotive.css'

export default function Subfooter() {
  const navigate = useNavigate()

  return (
    <section
      className="h-[300px] md:h-[400px] lg:h-[22rem] xl:h-[28rem] xlg:h-[34rem] 2xl:h-[42rem] grid place-content-center text-center 
    place-items-center automotive-subfooter gap-5 xlg:gap-8 bg-black xl:gap-10 2xl:gap-12 relative overflow-hidden"
    >
      <div className="absolute md:h-full md:inset-0 bottom-0 mx-auto">
        <img
          src={bg}
          alt="background"
          className="automotive-subfooter-bg md:h-full w-full object-cover"
        />
      </div>
      <div className="relative grid px-4 place-content-center place-items-center gap-5 xlg:gap-8 xl:gap-10 2xl:gap-12">
        <div>
          <h4 className="sg-translate font-light text-sm lg:text-lg xl:text-xl 2xl:text-[22px] text-white">
            Let’s drive to a new future together
          </h4>
          <h2 className="sg-translate font-light mt-4 leading-tight text-white md:block hidden">
            Harness the power of <br />{' '}
            <strong className="font-semibold">
              innovation and performance
            </strong>
          </h2>
        </div>
        <h4 className="sg-translate font-light text-white leading-snug text-[22px] md:hidden block mb-2">
          Harness the power of <br className="hidden sm:block" />
          <strong className="font-semibold">innovation and performance</strong>
        </h4>

        <HeroBtn
          size="small"
          text="Contact us"
          color="blueLight"
          onClick={() => navigate('/contact-us')}
          type="button"
        />
      </div>
    </section>
  )
}
