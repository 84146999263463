import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Autoplay, FreeMode, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import LogoMarquee from 'organisms/logoMarquee'
import { partnersLogos } from 'static/partners'

import 'styles/partners.css'

import acc from 'assets/globals/partners-logo/acc.png'
import ambuja from 'assets/globals/partners-logo/ambuja.png'
import asics from 'assets/globals/partners-logo/asics.png'
import ban from 'assets/globals/partners-logo/BR.png'
import ca from 'assets/globals/partners-logo/C&A.png'
import cecil from 'assets/globals/partners-logo/cecil.png'
import chettinad from 'assets/globals/partners-logo/chettinad.png'
import clp from 'assets/globals/partners-logo/clp.png'
import dalmia from 'assets/globals/partners-logo/dalmia.png'
import decathlon from 'assets/globals/partners-logo/decathlon.png'
import eicher from 'assets/globals/partners-logo/eicher.png'
import force from 'assets/globals/partners-logo/force.png'
import ford from 'assets/globals/partners-logo/ford.png'
import gap from 'assets/globals/partners-logo/gap.png'
import george from 'assets/globals/partners-logo/george.png'
import renault from 'assets/globals/partners-logo/renault.png'

export default function Partners() {
  return (
    <div className="bg-white overflow-hidden blade-top-padding-lg blade-bottom-padding-lg">
      <h2 className="sg-translate px-3 text-center font-light">
        Trusted by <span className="text-blue font-semibold">brands</span>{' '}
        <br className="block md:hidden" />
        around the world
      </h2>
      {/* <h3 className="px-3 text-center font-light md:hidden block ">
        Trusted by <span className="text-blue font-semibold">brands</span>{' '}
        <br /> around the world
      </h3> */}
      <div className="2xl:pt-10 pt-4 ">
        <section>
          <LogoMarquee
            logos={partnersLogos}
            gridStyle="2xl:grid-cols-10 xl:grid-cols-8 md:grid-cols-5 grid-cols-3 gap-2 xl:px-10 px-4"
          />

          {/* <LogoMarquee isReverse logos={logos.slice(7, 15)} /> */}
        </section>
      </div>
    </div>
  )
}

const logos = [
  { id: 1, category: 'other', title: 'ACC', logo: acc, alt: 'ACC-logo' },
  {
    id: 2,
    category: 'other',
    title: 'Ambuja',
    logo: ambuja,
    alt: 'Ambuja-logo',
  },
  {
    id: 3,
    category: 'fashion',
    title: 'Banana Republic',
    logo: ban,
    alt: 'Banana Republic-logo',
  },
  { id: 4, category: 'fashion', title: 'A', logo: ca, alt: 'C&A-logo' },
  {
    id: 5,
    category: 'fashion',
    title: 'Cecil',
    logo: cecil,
    alt: 'Cecil-logo',
  },
  {
    id: 6,
    category: 'other',
    title: 'Chettinad',
    logo: chettinad,
    alt: 'Chettinad-logo',
  },
  { id: 7, category: 'other', title: 'CLP', logo: clp, alt: 'CLP-logo' },
  {
    id: 8,
    category: 'other',
    title: 'Dalmia',
    logo: dalmia,
    alt: 'Dalmia-logo',
  },
  {
    id: 9,
    category: 'sports',
    title: 'Decathlon',
    logo: decathlon,
    alt: 'Decathlon-logo',
  },
  {
    id: 10,
    category: 'automotive',
    title: 'Eicher',
    logo: eicher,
    alt: 'Eicher-logo',
  },
  {
    id: 11,
    category: 'automotive',
    title: 'Force',
    logo: force,
    alt: 'Force-logo',
  },
  {
    id: 12,
    category: 'automotive',
    title: 'Ford',
    logo: ford,
    alt: 'Ford-logo',
  },
  { id: 13, category: 'fashion', title: 'Gap', logo: gap, alt: 'Gap-logo' },
  {
    id: 14,
    category: 'fashion',
    title: 'George',
    logo: george,
    alt: 'George-logo',
  },
  {
    id: 15,
    category: 'automotive',
    title: 'Renault',
    logo: renault,
    alt: 'Renault-logo',
  },
]
