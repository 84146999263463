import React from 'react'
import bannerPoster from 'assets/videos/apparel.webp'
import bannerVideo from 'assets/videos/apparel.mp4'
import banner_icon from 'assets/globals/terca.svg'

export default function Banner() {
  return (
    <section className="relative lg:h-screen min-h-[480px] oveflow-hidden bg-blueDark">
      <div className="absolute inset-0 ">
        <video
          autoPlay
          muted
          poster={bannerPoster}
          playsInline
          loop
          className="h-full w-full object-cover object-center"
        >
          <source src={bannerVideo} type="video/mp4" />
        </video>
      </div>

      <div className="absolute inset-0 bg-black bg-opacity-60" />

      <div className="absolute  inset-0 grid place-content-center place-items-center pt-10 md:pt-5 lg:pt-10 gap-2 text-center w-11/12 mx-auto">
        <img
          src={banner_icon}
          className="h-14 md:h-16 lg:h-20 w-auto object-contain object-center"
          height={80}
          alt="terca apparel interlining"
        />
        <span className="sg-translate font-light pt-2 pb-3  text-lg  xl:text-xl 2xl:text-[1.375rem] text-white block leading-snug">
          Apparel Interlinings
        </span>
        <h2 className="sg-translate text-white font-light leading-tight pb-2 ">
          Precise performance, <br className="md:hidden block" />
          <span className="font-semibold text-blue">timeless form</span>
        </h2>
      </div>
      {/* <div className="absolute inset-0 bg-white z-[10000] animate-reveal-banner" /> */}
    </section>
  )
}
