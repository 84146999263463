import React from 'react'

interface FlexCardsData {
  title: string
  subtitle?: string
  paragraphList: string[]
  flexImageLarge?: string
}

export default function FlexCards({
  data,
  flexImageLarge,
  className,
  flexImageSmall,
  reverseFlex,
}: {
  data: FlexCardsData
  flexImageLarge: string
  flexImageSmall?: string
  className?: string
  reverseFlex?: boolean
}) {
  return (
    <section
      className={`${className} bg-white blade-top-padding-lg blade-bottom-padding-lg`}
    >
      <div className="lg:blade-top-padding-sm blade-bottom-padding-sm ">
        <div
          className={`${
            reverseFlex
              ? 'lg:flex-row-reverse flex-col'
              : 'lg:flex-row flex-col-reverse'
          } w-container 2xl:px-20 flex lg:gap-14 gap-5 items-start justify-start`}
        >
          <div className="flex-1 2xl:max-w-2xl max-w-lg  lg:block hidden ">
            <div className="grid place-content-center place-items-center h-[400px] md:h-[500px] lg:h-full">
              <img
                src={flexImageLarge}
                alt={data.title}
                className="w-full h-full object-contain object-center"
              />
            </div>
          </div>
          <div className="flex-1 lg:pt-6 xl:pt-10 grid 2xl:gap-5 gap-4 2xl:max-w-[720px] max-w-lg">
            <h2 className="sg-translate font-light text-black leading-snug mb-1">
              {data.title}
            </h2>
            {/* <h3 className=" md:hidden block font-light text-black leading-snug mb-1">
              {data.title}
            </h3> */}
            {data.subtitle ? (
              <h3 className="sg-translate font-light text-black">
                {data.subtitle}
              </h3>
            ) : (
              ''
            )}

            <div className="w-full">
              <img
                src={flexImageSmall}
                alt={data.title}
                className="w-full h-full max-w-md md:hidden block object-contain object-center"
              />
              <img
                src={flexImageLarge}
                alt={data.title}
                className="w-full h-full max-w-md object-contain object-center hidden md:block lg:hidden "
              />
            </div>

            {data.paragraphList.map((item: string, index: number) => {
              const keyVal = `flexCards-${index}`
              return (
                <span
                  key={keyVal}
                  className="sg-translate text-black font-normal max-w-md leading-normal text-base pr-6 md:pr-16 xl:text-xl 2xl:text-[1.375rem] 2xl:max-w-2xl md:max-w-lg block"
                >
                  {item}
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
