import React from 'react'
import grs from 'assets/apparel/certifications/grs.png'
import cardIon from 'assets/apparel/certifications/cardIcon.png'
// import iatf from 'assets/apparel/certifications/iatf.png'
import iso from 'assets/apparel/certifications/iso.png'

// Client change: Hide 2008 and 2004 card and order change
const pointers = [
  // {
  //   title: 'Quality Management System',
  //   subtitle: 'ISO 9001: 2008',
  //   icon: iatf,
  // },
  // {
  //   title: 'Environmental Management System',
  //   subtitle: 'ISO 14001: 2004',
  //   icon: iso,
  // },
  {
    title: 'Oeko - Tex Class 1 Certified',
    subtitle: '',
    icon: cardIon,
  },
  {
    title: 'Global Recycled Standard (GRS) Certified',
    subtitle: '',
    icon: grs,
  },
  // {
  //   title: 'Quality Management System',
  //   subtitle: 'TS 16949: 2009',
  //   icon: iso,
  // },
]

export default function Certifications() {
  return (
    <section className="bg-white blade-top-padding-xl blade-bottom-padding-xl certifications-wrapper">
      <div className="grid place-content-center place-items-center text-center gap-1">
        <h2 className="sg-translate font-light text-black px-4">
          Certified excellence meeting
          <br className="min-[400px]:block hidden" />
          <strong className="font-semibold"> global quality standards</strong>
        </h2>
      </div>

      <div
        // Enable the below classes if there are 5 cards and comment the current active class
        // className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xlg:grid-cols-4 2xl:grid-cols-5
        //  pointers-wrapper gap-4 md:gap-y-8 lg:gap-y-10 xl:gap-x-8 w-container xlg:wfull xlg:w-11/12 2xl:gap-x-5
        // max-w-2xl lg:max-w-5xl xlg:max-w-none px-4 xl:px-6 2xl:px-10 mx-auto pt-8 md:pt-10 2xl:pt-20 md:pb-4"
        className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 
        gap-4 md:gap-y-8 lg:gap-y-10 w-container xlg:wfull xlg:w-11/12
        max-w-2xl md:max-w-4xl 2xl:max-w-4xl px-4 xl:px-6 2xl:px-10 mx-auto pt-8 md:pt-10 2xl:pt-20 md:pb-4"
      >
        {pointers.map((elem, index: number) => {
          const key = `${index}`
          const { title, icon, subtitle } = elem
          return (
            <Card subtitle={subtitle} key={key} title={title} icon={icon} />
          )
        })}
      </div>
    </section>
  )
}

function Card({
  title,
  icon,
  subtitle,
}: {
  title: string
  icon: string
  subtitle: string
}) {
  return (
    <article className="group max-sm:odd:last:col-span-2 max-sm:odd:last:mx-auto max-sm:odd:last:w-1/2 gap-3 w-full md:w-full mx-auto relative max-w-xs flex flex-col rounded-lg border-1 flex-0 border-solid basis-16 border-[#D7D7D7]  bg-[url('assets/apparel/card-pattern.png')] bg-no-repeat bg-right-top bg-contain">
      <div className="grid place-content-center place-items-center flex-1 gap-3 xl:gap-4 pt-6 md:pt-12 pb-3 md:pb-10 lg:pb-12 xl:pb-14 2xl:pb-16">
        <div className="grid place-content-center place-items-center">
          <img
            src={icon}
            alt={title}
            className="w-auto h-20 md:h-full md:w-full object-contain object-center"
          />
        </div>
        <span className="sg-translate text-black text-base font-semibold xl:text-lg text-center w-9/12 mx-auto">
          {title}
        </span>
      </div>
      <div className="border-t-1 flex-0 border-solid basis-10 md:basis-16 border-[#D7D7D7] grid place-content-center">
        <span className="sg-translate text-sm xl:text-base font-bold text-center px-3">
          {subtitle}
        </span>
      </div>
    </article>
  )
}
