import React from 'react'
import quality from 'assets/home/stats/quality-service-from-decades.svg'
import clients from 'assets/home/stats/clients.svg'
import designCenter from 'assets/home/stats/designCenter.svg'
// import employee from 'assets/home/stats/employee.svg'
import manufacturing from 'assets/home/stats/manufacturing.svg'
import products from 'assets/home/stats/products.svg'
import rd_center from 'assets/home/stats/rd_center.svg'

const statsData = [
  {
    id: 'id',
    logoImg: quality,
    title: '4',
    unit: 'decades',
    description: 'Nonwoven expertise',
  },
  {
    id: 'id',
    logoImg: clients,
    title: '100+',
    description: 'Global clients',
  },
  {
    id: 'id',
    logoImg: products,
    title: '4000+',
    description: 'Products',
  },
  {
    id: 'id',
    logoImg: rd_center,
    title: '2',
    description: 'R&D centers',
  },
  {
    id: 'id',
    logoImg: designCenter,
    title: '3',
    description: 'Design centers',
  },
  {
    id: 'id',
    logoImg: manufacturing,
    title: '16',
    description: 'Manufacturing facilities',
  },
]

export default function LeadershipStats() {
  return (
    <div className="w-container-sm bg-white blade-top-padding-lg blade-bottom-padding-xl">
      <h2 className="sg-translate font-light text-center">
        Nonwoven leadership
      </h2>

      <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-2 2xl:gap-10 md:gap-x-10 lg:gap-8 gap-5 gap-y-12 2xl:mt-14 mt-8 2xl:px-16 2xl:max-w-screen-xl xl:max-w-[800px] md:max-w-screen-md max-w-md mx-auto">
        {statsData.map((item, i) => {
          return (
            <div
              key={item.id}
              className="mx-auto sm:border sm:border-blue 2xl:h-[350px] 2xl:w-[350px]  lg:h-60 lg:w-60 sm:h-52 sm:w-52 rounded-full flex gap-1 flex-col items-center justify-center"
            >
              <img
                className="h-16 xl:h-14 2xl:h-16"
                src={item.logoImg}
                alt={item.title}
              />
              <h2 className="sg-translate text-blue font-bold">
                {item.title} <h3 className="inline">{item.unit}</h3>
              </h2>
              <h5 className="sg-translate font-semibold sm:px-6 text-center">
                {item.description}
              </h5>
            </div>
          )
        })}
      </div>
    </div>
  )
}
