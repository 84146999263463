import React, { useState, useRef, useMemo, useEffect } from 'react'

import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
// import './stylesOrganism.css'
import { SliderBtn } from 'screens/filtration/applicationExpertise'

type Props = {
  data: { coverImage: string; title: string; description: string }[]
  height: string
}

type SetActiveSlideIndex = (prev: number) => void

export default function CarouselPopup({
  data,
  height,
  activeSlideIndex,
  setActiveSlideIndex,
}: {
  data: any
  height: string
  activeSlideIndex: number
  setActiveSlideIndex: SetActiveSlideIndex
}) {
  const [current, setCurrent] = useState(0)
  const swiperRef = useRef<any>(null)
  useEffect(() => {
    swiperRef.current?.swiper.slideTo(activeSlideIndex)
  }, [activeSlideIndex])

  const slideDesc = useRef<any>()

  const lastSlide = useMemo(() => data.length - 1, [data])

  return (
    <>
      <div>
        <div className="relative">
          <div className="absolute inset-0 mx-auto w-10/12 lg:w-[99%] xl:w-[98%] xlg:w-[91%] 2xl:w-[90.5%] flex items-center gap-10 justify-between">
            <button
              disabled={current === 0}
              type="button"
              className="md:block bg-blue 2xl:p-4 xl:p-2 p-2 z-10 hidden possibility-prev-btn  opacity-100 disabled:opacity-0 disabled:pointer-events-none aspect-square rounded-full transition-all duration-200 ease-in-out focus-visible:bg-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              disabled={current === lastSlide}
              type="button"
              className="md:block bg-blue 2xl:p-4 xl:p-2 p-2 z-10 hidden disabled:hidden possibility-next-btn opacity-100  disabled:pointer-events-none aspect-square rounded-full transition-all duration-200 ease-in-out focus-visible:bg-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <Swiper
            ref={swiperRef}
            modules={[Navigation]}
            navigation={{
              prevEl: '.possibility-prev-btn',
              nextEl: '.possibility-next-btn',
            }}
            slidesPerView={1.1}
            centeredSlides
            spaceBetween={10}
            loop={false}
            speed={1000}
            className="bg-white cursor-grab overflow-hidden"
            breakpoints={{
              768: {
                spaceBetween: 20,
                slidesPerView: 1.2,
              },
              1024: {
                spaceBetween: 20,
                slidesPerView: 1.05,
              },
              1280: {
                spaceBetween: 80,
                slidesPerView: 1.1,
              },
              1366: {
                spaceBetween: 80,
                slidesPerView: 1.2,
              },
              1536: {
                spaceBetween: 100,
                slidesPerView: 1.2,
              },
            }}
            onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
          >
            {data.map((elem: any, index: number) => {
              const key = `${index}`
              return (
                <SwiperSlide
                  key={key}
                  className="overflow-hidden 2xl:h-[70vh] xl:h-[60vh] lg:h-[55vh] min-h-[400px] "
                >
                  <div className="absolute h-full w-full inset-0 z-0">
                    <img
                      src={elem.coverImage}
                      alt={elem.title}
                      className="h-full w-full object-cover object-top grayscale"
                    />
                  </div>
                  <div className="flex items-end relative min-h-[400px] 2xl:p-10 p-7 bg-gradient-to-b from-[#00000050] to-blueDark h-full">
                    <div className="flex flex-col 2xl:gap-2 justify-end h-full w-full">
                      <h3 className="text-white font-medium text- w-fit leading-none">
                        {elem.timeRange[0]} to{' '}
                        {/* <span className="text-xl opacity-90">to</span>{' '} */}
                        {elem.timeRange[1]}
                      </h3>
                      <h2 className="text-42 text-white leading-tight mt-1">
                        {elem.slideTitle}
                      </h2>
                      <h4 className="text-42 text-white leading-tight">
                        {elem.slideSubtitle}
                      </h4>
                      <h5 className="text-white font-medium mt-1">
                        {elem.slideDescription}
                      </h5>
                      <h5 className="text-white ">{elem.slideDescription1}</h5>
                      <ul className="list-inside list-disc 2xl:grid gap-1 2xl:mt-2 mt-1">
                        {elem.list.map((listItem: string, ind: number) => {
                          const keyVal = `${ind}-${listItem}`
                          return (
                            <li className="text-white list-disc" key={keyVal}>
                              <h6 className="inline">{listItem}</h6>
                            </li>
                          )
                        })}
                      </ul>
                    </div>

                    <ul className="list-inside list-disc lg:grid hidden gap-1 2xl:mt-2 mt-1">
                      {elem.locations.map((listItem: string, ind: number) => {
                        const keyVal = `${ind}-${listItem}`
                        return (
                          <li
                            className="text-white list-disc whitespace-nowrap"
                            key={keyVal}
                          >
                            <h6 className="2xl:text-base text-sm inline whitespace-nowrap">
                              {listItem}
                            </h6>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        {/* w-10/12 lg:w-[99%] xl:w-[99%] xlg:w-[92%] 2xl:w-[90.5%] */}
        <div className="timeline lg:w-[95%] xl:w-[90.5%] xlg:w-[82.5%] 2xl:w-[82.5%] mx-auto">
          {/* Timeline progress bar  */}
          <div className="hidden md:flex justify-betwee 2xl:mt-4 mt-3">
            {data.map((item: any, i: number) => (
              <div key={item.id} className="flex- w-[22%] last-of-type:w-[12%]">
                <div className="flex">
                  <div
                    className={`centimeter w-[1px] h-4 bg-black rounded-full transition-all duration-300 ease-in-out ${
                      i === 0 ? 'block' : 'hidden'
                    }`}
                  />
                  <div className="scale flex justify-between w-full h-[1px] bg-black bg-opacity-40">
                    {data.map((millimeter: any, ind: number) => {
                      return (
                        <div
                          key={millimeter.id}
                          className={`millimeter w-[1px] h-3 last-of-type:opacity-0 first-of-type:opacity-0 bg-black bg-opacity-10 rounded-full transition-all duration-300 ease-in-out  ${
                            i === 4 ? ' even:hidden ' : ''
                          }`}
                        />
                      )
                    })}
                    {/* <div
                        className={`${
                          current >= item.id
                            ? 'w-[78px] lg:w-[100px] 2xl:w-[160px]'
                            : 'w-0'
                        } transition-all duration-500 ease-in-out h-[2.2px] bg-black`}
                      /> */}
                  </div>

                  <div
                    className={`w-[1px] h-4 bg-black rounded-full transition-all duration-300 ease-in-out ${
                      i === 4 && 'opacity-0'
                    }`}
                  />
                </div>

                <button
                  type="button"
                  onClick={() => {
                    setActiveSlideIndex(i)
                    console.log(activeSlideIndex)
                  }}
                  className={`${
                    item.id === current ? ' text-blueDark' : ' text-dark'
                  } leading-none font-semibold  py-2 transition-all cursor-pointer text-left`}
                >
                  <h3 className="">
                    {item.timeRange[0]} <br />
                    <p className="text-dark opacity-80">
                      to {item.timeRange[1]}
                    </p>
                  </h3>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        className="flex md:hidden z-10 justify-end w-container-sm 
                gap-5 pb-5 pl-5 md:pl-0 md:mb-6"
      >
        <SliderBtn
          disable={current === 0}
          direction="left"
          slideClass="possibility-prev-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
              fill="white"
            />
          </svg>
        </SliderBtn>
        <SliderBtn
          disable={current === lastSlide}
          direction="right"
          slideClass="possibility-next-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
              fill="white"
            />
          </svg>
        </SliderBtn>
      </div>
    </>
  )
}
