import { HeroBtn } from 'atoms/buttons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function MobileCards({
  id = 1,
  list,
  video = '',
  poster,
  image,
  title,
  description,
  logo,
  link = '',
  linkText = '',
}: {
  id: number
  list?: string[]
  video?: string
  poster?: string
  image?: string
  title?: string
  description?: string
  logo?: string
  link?: string
  linkText?: string
}) {
  const navigate = useNavigate()

  return (
    <div className="h-full webkit-rounded">
      <div className="h-full overflow-hidden flex flex-col">
        <div className="rounded-2xl overflow-hidden webkit-rounded">
          {video ? (
            <div className="h-56 relative">
              <video
                className="w-full h-full object-cover z-10 lg:rounded-3xl 2xl:rounded-[40px]"
                autoPlay
                muted
                loop
                playsInline
                poster={poster}
              >
                <source src={video} type="video/mp4" />
                <track kind="captions" srcLang="en" label="English" />
              </video>

              {logo ? (
                <div className="bg-gradient-to-t from-black to-transparent absolute bottom-0 left-0 right-0 w-full !z-[999] transition-all">
                  <img
                    className="h-14 w-auto object-contain object-bottom p-3 max-w-[100px] "
                    src={logo}
                    alt={title}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <img src={image} alt={title} className="w-full h-56 object-cover" />
          )}
        </div>
        <div className="flex flex-col justify-between h-auto flex-1">
          <div className="md:bg-gradient relative h-full flex md:p-8 py-2 flex-col justify-start items-start gap-2 md:gap-4">
            <h3 className="sg-translate text-black text-xl lg:text-2xl xlg:text-3xl text-center font-semibold py-1 pt-1.5">
              {title}
            </h3>
            {description ? (
              <h5 className="sg-translate text-black pr-6 font-light">
                {description}
              </h5>
            ) : (
              ''
            )}
            {list ? (
              <ul>
                {list.map((item, idx) => (
                  <li
                    className="list-outside list-disc ml-5 font-light"
                    key={id}
                  >
                    <h5 className="sg-translate">{item}</h5>
                  </li>
                ))}
              </ul>
            ) : (
              ''
            )}
          </div>
          {link ? (
            <div className="2xl:py-5 py-3.5">
              <HeroBtn
                size="small"
                text="Know more"
                color="blueLight"
                onClick={() => navigate(link)}
                type="button"
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}
