import React from 'react'
import bg from 'assets/filtration/bgs.png'
import filtrationImage from 'assets/filtration/cylidrical-machine.png'

export default function OurStrengths() {
  return (
    <div className="w-container px-4 2xl:px-10 blade-bottom-padding-xl md:pt-0 pt-8 md:text-center">
      <h2 className="sg-translate text-black">
        Our <span className="font-semibold">strengths</span>
      </h2>
      <h5 className="sg-translate mt-3 2xl:mt-4 opacity-60 blade-bottom-padding-sm">
        Why Supreme for nonwoven filtration
      </h5>

      <div className="grid xl:grid-cols-3 sm:grid-cols-2">
        <div className="2xl:px-16 col-span-1 flex flex-col ">
          {data.slice(0, 2).map((item) => {
            return (
              <Layout key={item.title} title={item.title} desc={item.desc} />
            )
          })}

          <div className="md:hidden block 2xl:px-0 col-span-1 md:mt-0 mt-10 max-w-sm pb-4">
            <div>
              <img src={filtrationImage} alt="process of filtration" />
            </div>
          </div>

          <div className="xl:hidden flex flex-col ">
            {data.slice(2, 4).map((item) => {
              return (
                <Layout key={item.title} title={item.title} desc={item.desc} />
              )
            })}
          </div>
        </div>

        <div className="md:block hidden 2xl:px-0 px-5 col-span-1 md:mt-0 mt-10">
          <div>
            <img src={filtrationImage} alt="process of filtration" />
          </div>
        </div>

        <div className="xl:flex hidden 2xl:px-16 px-5 col-span-1 flex-col ">
          {data.slice(2, 4).map((item) => {
            return (
              <Layout key={item.title} title={item.title} desc={item.desc} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

function Layout({ title, desc }: { title: string; desc: string }) {
  return (
    <div
      key={title}
      className="2xl:mt-20 xl:mt-6 mt-3 2xl:min-h-[200px] xl:min-h-[150px] max-w-md"
    >
      <h4 className="sg-translate text-left font-bold">{title}</h4>
      <h5 className="sg-translate text-left">{desc}</h5>
    </div>
  )
}

const data = [
  {
    title: 'Filtration tailored to plant conditions',
    desc: 'Commanding a wide spectrum of fibers and production technologies to customise solutions as per your plant conditions.',
  },

  {
    title: 'Automated manufacturing',
    desc: 'Home to the largest and most advanced facilities to ensure consistency in quality, scalability, and fast-paced production.',
  },
  {
    title: 'Bag house analysis',
    desc: 'A thorough report on filter bag quality and failure, along with expert assistance and maintenance services for product longevity.',
  },

  {
    title: 'Unyielding quality',
    desc: 'Fortified finishing and rigorous testing.',
  },
]
