import React, { useEffect } from 'react'
import useScrollTop from 'hooks/useScrollTop'
import DailyLife from './dailylife'
import Banner from './banner'
import LifeAtSupreme from './life_at_supreme'
import Subfooter from './subfooter'
import SubFooterMobile from './subfooter_mobile'
import './careers.css'

export default function Careers() {
  const scrollTop = useScrollTop()

  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <div className="careers-page bg-white">
      <Banner />
      <DailyLife />
      <LifeAtSupreme />
      <div className="md:block hidden">
        <Subfooter />
      </div>
      <div className="md:hidden block">
        <SubFooterMobile />
      </div>
    </div>
  )
}
