import React, {
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { timelineData } from 'static/our-journey'
import { CSSTransition } from 'react-transition-group'
import './ourjourney.css'
import gsap, { Power1 } from 'gsap'
import j_1996 from 'assets/who-we-are/journey/1996.jpg'
import j_2006 from 'assets/who-we-are/journey/2006.jpg'
import j_2007 from 'assets/who-we-are/journey/2007.jpg'
import j_2017 from 'assets/who-we-are/journey/2017.jpg'
import j_2019 from 'assets/who-we-are/journey/2019.jpg'

import CarouselWhoWeAre from './carouselPopup'

export default function OurJourney() {
  const [inProp, setInProp] = useState(false)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const nodeRef = useRef<any>(null)

  useLayoutEffect(() => {
    if (window.innerWidth >= 768) {
      const gsapCtx = gsap.context(() => {
        const wrapper = document.querySelector(
          '.wrapper-pinned',
        ) as HTMLDivElement
        const card = document.querySelectorAll('.card')
        const wide =
          card[0].clientWidth * Object.keys(timelineData).length -
          wrapper.clientWidth
        const wrapperWidth = wrapper.clientWidth - card[0].clientWidth

        console.log(wide)

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.wrapper-pinned',
            start: 'top top',
            end: 'bottom -100%',
            markers: false,
            scrub: 2,
            pin: true,
          },
        })

        // card.forEach((item, index) => {
        //   tl.fromTo(
        //     item,
        //     {
        //       x: wrapperWidth,
        //       stagger: 0.2,
        //       onStart: () => {
        //         gsap.fromTo(item, { opacity: 0 }, { opacity: 1, duration: 0.3 })
        //       },
        //     },
        //     {
        //       x: `-${wide}px`,
        //     },
        //   )
        // })

        tl.fromTo(
          '.card',
          { x: wrapperWidth },
          {
            x: `-${wide}px`,
          },
        ).addPause(0.6)
      })

      return () => gsapCtx.revert()
    }
  }, [])

  useEffect(() => {
    const handleEscapeKey = (event: any) => {
      if (event.keyCode === 27 && inProp) {
        setInProp(false)
      }
    }

    window.addEventListener('keydown', handleEscapeKey)

    return () => {
      window.removeEventListener('keydown', handleEscapeKey)
    }
  }, [inProp])

  const handleOpenModal = (id: number) => {
    setInProp(true)
    setActiveSlideIndex(id)
    document.body.style.overflow = 'hidden'
  }

  const handleCloseModal = () => {
    setInProp(false)
    document.body.style.overflow = 'auto'
  }

  return (
    <>
      <div className="wrapper-pinned h-screen blade-top-padding flex flex-col">
        <h2 className="text-center leading-tight font-normal">Our Journey</h2>
        <div className="cards-wrp flex flex-nowrap overflow-hidden mt-8 h-full">
          {timelineData.map((item, index) => {
            return (
              <button
                type="button"
                id="card"
                className="card group outline-none 2xl:min-w-[450px] xlg:min-w-[400px] min-w-[300px] bg-white relative inline-block h-full border-[#cecece] border-t border-l last-of-type:border-r"
                key={item.id}
                onClick={() =>
                  inProp ? handleCloseModal() : handleOpenModal(item.id)
                }
              >
                <div className="relative flex flex-col justify-between z-20 bg-white group-hover:bg-black group-hover:bg-opacity-50 h-full px-10 py-20 transition-all duration-300">
                  <h1 className="font-medium group-hover:text-white text-black transition-all duration-300 leading-none ">
                    {item.timeRange[0]} <br /> - <br /> {item.timeRange[1]}
                  </h1>

                  <div className="flex flex-col items-center">
                    <h4 className="group-hover:text-white text-black transition-all duration-300 leading-tight font-medium">
                      {item.slideTitle}
                    </h4>

                    <button
                      type="button"
                      className="w-9 p-2 mt-5 bg-blue hover:scale-125 rounded-full group-hover:-rotate-45 transition-all duration-300"
                    >
                      <svg
                        className="h-full w-full"
                        stroke="white"
                        fill="white"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0Z" />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="bg-blue absolute  inset-0 h-auto w-auto z-0 overflow-hidden">
                  <img
                    src={item.coverImage}
                    alt=""
                    className="w-full h-full object-cover group-hover:scale-125 transition-all duration-500"
                  />
                </div>
              </button>
            )
          })}
        </div>
      </div>

      <CSSTransition
        in={inProp}
        timeout={800}
        unmountOnExit
        onEnter={(node: any) =>
          gsap.fromTo(
            node,
            { opacity: 0 },
            { opacity: 1, duration: 0.4, ease: Power1.easeInOut },
          )
        }
        onExiting={(node: any) =>
          gsap.to(node, {
            opacity: 0,
            duration: 0.5,
            ease: Power1.easeInOut,
          })
        }
      >
        <div
          className="journey h-screen w-screen opacity- fixed inset-0 flex flex-col justify-center bg-white overflow-scroll z-[99999]"
          ref={nodeRef}
        >
          <section className="lg:min-h-[550px]  2xl:blade-top-padding-sm 2xl:pt-8 pt-6">
            <div className="flex justify-center">
              <button
                type="button"
                className=" text-white bg-blue p-1 w-7 h-7 2xl:scale-125 rounded-full"
                onClick={() => inProp && handleCloseModal()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  className="stroke-white opacity-80 w-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <article className="2xl:pt-8 pt-6">
              <CarouselWhoWeAre
                data={timelineData}
                activeSlideIndex={activeSlideIndex}
                setActiveSlideIndex={setActiveSlideIndex}
                height=""
              />
            </article>
          </section>
        </div>
      </CSSTransition>
    </>
  )
}
