import React from 'react'

export default function PageBanner({
  title,
  subtitle,
  isVideo = true,
  pageName = '',
  bgVideo,
  videoPoster,
  titleClassName,
  icon,
  iconAlt,
  iconClasses,
}: {
  pageName?: string
  isVideo?: boolean
  videoPoster: string
  bgVideo?: string
  title: string
  subtitle?: string
  titleClassName?: string
  icon?: string
  iconAlt?: string
  iconClasses?: string
}) {
  return (
    <section className="relative lg:h-screen min-h-[450px] oveflow-hidden bg-blueDark">
      {isVideo ? (
        <div className="absolute inset-0">
          <video
            autoPlay
            muted
            poster={videoPoster}
            playsInline
            loop
            className="h-full w-full object-cover object-center"
          >
            <source src={bgVideo} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div className="absolute inset-0">
          <img
            src={videoPoster}
            className="h-full w-full object-cover object-center"
            alt={title}
          />
        </div>
      )}

      <div className="absolute inset-0 bg-black bg-opacity-40" />
      <div className="absolute inset-0 grid place-content-center pt-8 md:pt-5 lg:pt-0 gap-2 text-center w-11/12 mx-auto">
        {icon && (
          <img
            src={icon}
            className={iconClasses}
            // height={80}
            alt={iconAlt}
          />
        )}

        <span
          className="sg-translate text-lg text-white block leading-snug font-extralight xl:text-[1.3rem] animate-fade opacity-0 pb-2 lg:pb-3"
          dangerouslySetInnerHTML={{ __html: pageName }}
        />

        <h2
          className={`sg-translate text-white font-light leading-tight pb-2 opacity-0 animate-fade ${titleClassName}`}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        {subtitle ? (
          <span
            className="sg-translate text-white block leading-snug font-extralight text-xl xl:text-2xl 2xl:text-[1.75rem] animate-fade-100 opacity-0"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        ) : (
          ''
        )}
      </div>
      {/* <div className="absolute inset-0 bg-white z-[10000] animate-reveal-banner" /> */}
    </section>
  )
}
