import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { useLanguage } from 'context/languageContext'
import { TextNavbar } from './links'

gsap.registerPlugin(ScrollTrigger)

interface Dropdown {
  title: string
  options: {
    text: string
    href: string
  }[]
}

export default function Dropdown({ title, options }: Dropdown) {
  const [mobileNav, toggleMobileNav] = useReducer((s) => !s, false)
  const [isNavFixed, setisNavFixed] = useState(false)
  const location = useLocation()
  const { dropdownText, language } = useLanguage()

  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const toggleDropdown = () => {
    setIsOpenMenu(!isOpenMenu)
  }
  const dropdownRef = useRef<any>()
  const solutionsRef = useRef<any>()

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [mobileNav])

  const handleScroll = () => {
    const scrollPos = window.scrollY
    if (scrollPos > 80) {
      setisNavFixed(true)
      setIsOpenMenu(false)
    } else {
      setisNavFixed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !solutionsRef.current.contains(e.target)
      ) {
        setIsOpenMenu(false)
        // console.log('Out')
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  return (
    <div>
      <li className="relative">
        <button
          ref={solutionsRef}
          onClick={toggleDropdown}
          type="button"
          className={`dropdown-toggle text-left text-black 2xl:text-base text-sm border-none  
            leading-none flex items-center font-medium transition-colors duration-200
            ${
              options.findIndex((elem) => elem.href === location.pathname) !==
                -1 && 'text-blue'
            }`}
        >
          <span className="sg-translate">{title}</span>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            className={`w-5 transition-all duration-500 ${
              isOpenMenu ? '-rotate-90' : ''
            } `}
          >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
          </svg>
        </button>

        <CSSTransition
          in={isOpenMenu}
          timeout={400}
          unmountOnExit
          onEnter={(node: any) =>
            gsap.fromTo(
              node,
              { opacity: 0, y: 10 },
              { opacity: 1, y: 0, duration: 0.3 },
            )
          }
          onExiting={(node: any) =>
            gsap.to(node, { opacity: 0, y: 10, duration: 0.3 })
          }
        >
          <div className="dropdown" ref={dropdownRef}>
            <button
              type="button"
              onClick={() => setIsOpenMenu(false)}
              className="dropdown-content flex flex-col cursor-default gap-3 mt-6 bg-white bg-footer shadow bg-no-repeat 
              bg-cover bg-right-bottom rounded-sm px-5 pt-4 pb-5 2xl:min-w-[350px] min-w-[250px]"
            >
              {options.map((item) => {
                return (
                  <TextNavbar
                    key={item.text}
                    color="dark"
                    href={item.href}
                    text={dropdownText[language][item.text]}
                    className="whitespace-nowrap"
                    size="small"
                  />
                )
              })}
            </button>
          </div>
        </CSSTransition>
      </li>
    </div>
  )
}
