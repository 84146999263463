import React, { useEffect, useState } from 'react'
import image1 from 'assets/sustainability/environmental/sustainable-operations.webp'
import image2 from 'assets/sustainability/environmental/resource-management.webp'
import image3 from 'assets/sustainability/environmental/biodiversity.webp'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

gsap.registerPlugin(ScrollTrigger, { debug: true })

const data = [
  {
    id: 1,
    image: image1,
    des: 'We’re steadfast in our mission to reduce our carbon footprint and enhance energy efficiency across our operations. Notably, our transition to renewable energy sources is underway, aiming for significant carbon neutrality milestones in the coming decade.',
    title: 'Sustainable operations',
  },
  {
    id: 2,
    image: image2,
    des: 'Our initiatives extend to meticulous water conservation, waste reduction, and a focus on sustainable material sourcing. Our embrace of circular economy principles is evidenced by our pioneering Circuline range of products across our entire portfolio. Circuline products are designed using a high percentage of raw materials that are either Reclaimed, Renewable or Recycled. Coupled with the elimination of harmful chemicals and binders, Circuline marked products are engineered with the security of our Kilometer Zero commitment, ensuring materials in our products find new life beyond their initial use.',
    title: 'Resource management',
  },
  {
    id: 3,
    image: image3,
    des: 'We are dedicated to protecting biodiversity and restoring natural habitats, ensuring our operations coexist harmoniously with nature.',
    title: 'Biodiversity and ecosystems',
  },
]

export function Environmental() {
  useEffect(() => {
    if (window.innerWidth >= 1024) {
      const gsapCtx = gsap.context(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.environmental-wrapper',
            start: 'top top',
            end: 'bottom top',
            markers: false,
            scrub: 1.2,
            pin: true,
          },
        })

        tl.addPause(1)
          .add('l1')
          .to(
            '.environmental-wrapper .content-01',
            {
              y: '-50%',
              opacity: 0,
              duration: 1.5,
              ease: 'ease',
            },
            'l1',
          )
          .to(
            '.environmental-wrapper .image-01',
            {
              y: '-100%',
              opacity: 0,
              duration: 1.5,
            },
            'l1',
          )
          .to(
            '.environmental-wrapper .content-02',
            {
              y: '-100%',
              opacity: 1,
              duration: 2.5,
              ease: 'ease',
            },
            'l1',
          )
          .to(
            '.environmental-wrapper .image-02',
            {
              y: '-100%',
              opacity: 1,
              duration: 2.5,
            },
            'l1',
          )
          .to(
            '.environmental-wrapper .content-03',
            {
              y: '-220%',
              opacity: 0.5,
              duration: 3,
            },
            'l1',
          )

        tl.add('l2')
          .to(
            '.environmental-wrapper .content-02',
            {
              y: '-100%',
              opacity: 0,
              duration: 1.5,
              ease: 'ease',
            },
            'l2',
          )
          .to(
            '.environmental-wrapper .image-02',
            {
              y: '-200%',
              opacity: 0,
              duration: 1.5,
            },
            'l2',
          )
          .to(
            '.environmental-wrapper .content-03',
            {
              y: window.innerWidth >= 1536 ? '-500%' : '-560%',
              opacity: 1,
              duration: 2.5,
            },
            'l2',
          )
          .to(
            '.environmental-wrapper .image-03',
            {
              y: '-200%',
              opacity: 1,
              duration: 2.5,
            },
            'l2',
          )
      })

      return () => gsapCtx.revert()
    }
  }, [])

  return (
    <section>
      <div
        className="blade-top-padding-xl blade-bottom-padding-xl overflow-hidden xl:h-full lg:min-h-[460px] xl:min-h-screen 
        environmental-wrapper bg-blueShade"
      >
        <div className="grid place-content-center gap-2 text-center md:w-11/12 mx-auto px-5 blade-bottom-padding-sm">
          <p className="sg-translate text-green text-lg md:text-xl xl:text-[22px] 2xl:text-2xl font-normal">
            Environmental stewardship
          </p>
          <h2 className="sg-translate font-normal leading-tight text-black">
            Our planet, our responsibility
          </h2>
        </div>

        <div className="w-container hidden pt-6 xlg:pt-12 gap-x-20 2xl:gap-x-48 lg:flex justify-center">
          <div className="relative h-full flex items-center">
            <div className="flex flex-col pt-20 2xl:pt-36 h-[100px] xl:h-[340px] xlg:h-[300px] 2xl:h-[300px] gap-y-20">
              {data.map((item) => (
                <div
                  key={item.id}
                  className={`content-0${item.id} ${
                    item.id === 1 ? 'opacity-100' : 'opacity-30'
                  } 
                
                `}
                >
                  <h3 className="sg-translate font-semibold">{item.title}</h3>
                  <p className="sg-translate font-normal max-w-[550px] mt-2 text-base 2xl:text-lg">
                    {item.des}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="overflow-hidden h-full flex items-center justify-end">
            <div className="w-[320px] h-[320px] 2xl:w-[450px] 2xl:h-[450px] overflow-hidden rounded-full">
              {data.map((item) => (
                <div
                  key={item.id}
                  className={`image-0${item.id} rounded-full w-full h-full`}
                >
                  <img className={image1} src={item.image} alt={item.title} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile View  */}

        <div className="pt-6 md:pt-12 flex flex-col gap-y-12 px-4 lg:hidden">
          {data.map((item) => (
            <div key={item.id} className="h-full flex flex-col items-center">
              <div className="w-[60%] sm:w-[40%]">
                <img
                  className="w-full h-full"
                  src={item.image}
                  alt={item.title}
                />
              </div>
              <div className="mt-4">
                <h3 className="sg-translate font-semibold text-center">
                  {item.title}
                </h3>
                <p className="sg-translate font-normal mt-2 max-w-xl text-sm text-center 2xl:text-lg">
                  {item.des}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
