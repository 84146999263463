import React, { useEffect } from 'react'
import SubFooter from 'organisms/subFooter'
import { timelineData } from 'static/our-journey'
import useScrollTop from 'hooks/useScrollTop'
import Banner from './banner'
// import Customers from './customers'
import Ecosystem from './ecosystem'
import Founders from './founders'
import Map from './map'
import Values from './values'
import './who-we-are.css'
import OurMission from './ourMission'
import OurJourneySoFar from './ourJourneySoFar'
import OurJourney from './ourJourney'
import OurJourneyMobile from './ourJourney/ourJourneyMobile'

export default function WhoWeAre() {
  const scrollTop = useScrollTop()

  useEffect(() => {
    scrollTop()
  }, [])
  return (
    <div className="who-we-are">
      <Banner />
      <Founders />
      <OurMission />
      <Values />
      {/* <OurJourneySoFar /> */}
      <div className="md:block hidden">
        <OurJourney />
      </div>{' '}
      <div className="md:hidden block">
        <OurJourneyMobile data={timelineData} />
      </div>{' '}
      {/* <Customers /> */}
      <Ecosystem />
      {/* <Map /> */}
      <SubFooter
        title="Let’s build together!"
        btn={{ text: 'Contact us', target: '/contact-us' }}
        className="bg-[url('assets/who-we-are/subfooter.webp')]"
      />
    </div>
  )
}
