import React, { useLayoutEffect } from 'react'
import NewAge from './newAge'
import Pointers from './pointers'
import SalientFeatures from './salientFeatures'
import Subfooter from './subFooter'
import Banner from './banner'
import './innovation.css'

export default function Innovation() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="innovation-page">
      <Banner />
      <SalientFeatures />
      <NewAge />
      <Pointers />
      <Subfooter />
    </div>
  )
}
