import React from 'react'

import Image1 from 'assets/automotive/evolution/image-01.jpg'
import Image2 from 'assets/automotive/evolution/image-02.jpg'
import './automotive.css'

export default function Evolution() {
  return (
    <div className="bg-[#EBF6FF] px-4 blade-top-padding blade-bottom-padding-lg">
      <div>
        <h2 className="sg-translate font-light leading-tight text-center">
          Driving<span className="font-semibold"> automotive </span>evolution
        </h2>
        <h5 className="sg-translate font-semibold text-center mt-6">
          As the automotive landscape transforms, we stand at the forefront,{' '}
          <br className="hidden sm:block" />
          engineering future-ready solutions that keep our OEMs ahead of the
          curve.
        </h5>
      </div>
      <div className="flex cards-wrapper flex-col gap-y-8 lg:flex-row mt-12 items-center gap-x-8 justify-center">
        <div
          className="w-full sm:w-[460px] xl:w-[500px] xlg:w-[560px] 2xl:w-[600px] overflow-hidden evolution-card 
        h-[300px] sm:h-[400px]"
        >
          <img
            src={Image1}
            alt="moving from linear to circular"
            className="w-full rounded-2xl h-full object-cover"
          />
          <div className="w-full rounded-2xl absolute bottom-0 gradient" />

          <div className="w-full px-6 xlg:px-10 h-fit content-wrapper z-10 absolute">
            <h4 className="sg-translate font-normal text-lg sm:text-xl 2xl:text-2xl m-0 leading-tight relative text-white">
              Moving from linear to circular{' '}
            </h4>
            <ul className="text-white mt-4 list-disc pl-8">
              <li className="sg-translate text-sm sm:text-base md:text-lg font-normal">
                Utilizing renewable, recyclable, and reclaimed materials for
                lower carbon emissions
              </li>
              <li className="sg-translate text-sm sm:text-base md:text-lg font-normal">
                Design and process optimization for minimum environmental impact
              </li>
            </ul>
          </div>
        </div>

        <div
          className="w-full sm:w-[460px] xl:w-[500px] xlg:w-[560px] 2xl:w-[600px] overflow-hidden evolution-card h-[300px] 
        sm:h-[400px]"
        >
          <img
            src={Image2}
            alt="shaping the future of mobility"
            className="w-full rounded-2xl h-full object-cover"
          />
          <div className="w-full rounded-2xl absolute bottom-0 gradient" />
          <div className="w-full px-6 xlg:px-10 h-fit content-wrapper z-10 absolute">
            <h4 className="sg-translate font-normal text-lg sm:text-xl 2xl:text-2xl m-0 leading-tight relative text-white">
              Shaping the future of mobility
            </h4>
            <ul className="text-white mt-4 list-disc pl-8">
              <li className="sg-translate text-sm sm:text-base md:text-lg font-normal">
                Engineering parts for shared mobility space
              </li>
              <li className="sg-translate text-sm sm:text-base md:text-lg font-normal">
                Engineering parts for new-age vehicles
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
