import React, { useEffect, useRef } from 'react'
import one from 'assets/globalCompetency/ourExpertise/01.jpg'
import two from 'assets/globalCompetency/ourExpertise/02.jpg'
import three from 'assets/globalCompetency/ourExpertise/03.jpg'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import bg from 'assets/globals/waves-bg.png'

gsap.registerPlugin(ScrollTrigger)

const data = [
  {
    image: one,
    id: 1,
    title:
      'Diverse <strong class="font-semibold">nonwoven</strong> solutions and innovating across industries',
    paragraph:
      'Leading with a diverse nonwoven portfolio, Supreme Group is renowned for delivering premium, customised solutions spanning various sectors - from Apparel and Automotive to Filtration and beyond. Our dedication to excellence and innovation positions us as the go-to provider for specialized nonwoven applications.',
  },
  {
    image: two,
    id: 2,
    title: 'Global collaborations driving customer success',
    paragraph:
      'Our foundation rests on advanced technologies, material innovation and a steadfast commitment to sustainability. Our global collaborations allow us to integrate the latest technologies into our products, ensuring they consistently remain ahead of the curve.',
  },
  {
    image: three,
    id: 3,
    title:
      'Expanding <strong class="font-semibold">global footprint</strong> with dynamic scalability',
    paragraph:
      'As a catalyst for transformation in the nonwoven industry, we continuously expand our reach and capabilities. Our growing network of state-of-the-art facilities, strategically located worldwide, empowers us to be a reliable and scalable partner for global brands, adapting swiftly to evolving market needs.',
  },
  // {
  //   image: one,
  //   id: 3,
  //   title: 'We have a strategic global presence with boundless scalability.',
  //   paragraph:
  //     'Having solidified our position within the nonwoven industry, we catalyze worldwide transformation with our cutting-edge nonwoven solutions. ',
  // },
]

export default function OurExpertise() {
  const wrapper = useRef<any>()

  useEffect(() => {
    const windowWidth = window.innerWidth

    if (windowWidth > 768) {
      const ctx = gsap.context(() => {
        const titleTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: '.title-wrapper',
            start: 'top top',
            end: 'bottom -100%',
            markers: false,
            scrub: 1,
            pin: true,
          },
        })
        titleTimeline
          .addPause(0.5)
          // .fromTo(
          //   '.title-wrapper h2',
          //   { scale: 2, transformOrigin: 'center' },
          //   {
          //     scale: 1,
          //   },
          // )
          .fromTo(
            '.title-wrapper span',
            { opacity: 0.1 },
            {
              opacity: 1,
              stagger: 0.1,
            },
          )
          .addPause(0.8)
          .fromTo(
            '.our-expertise-wrapper .timeline-wrapper',
            { opacity: 0 },
            {
              opacity: 1,
            },
          )
          .addPause(2)
      }, wrapper)
      return () => ctx.revert()
    }
  }, [])

  useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth) {
      const selector = gsap.utils.selector(wrapper)
      const ctx = gsap.context(() => {
        const allPanels = selector('.our-expertise-wrapper .panel-wrapper')
        allPanels.forEach((item, index) => {
          const time = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top 95%',
              end: '130% bottom',
              markers: false,
              scrub: 1,
            },
          })
          time
            .fromTo(
              item.querySelector('.circle'),
              { filter: 'grayscale(1)' },
              { filter: 'grayscale(0)' },
            )
            .fromTo(
              item.querySelector('.panel .img-wrapper'),
              { opacity: 0, x: -40, filter: 'grayscale(1)' },
              { opacity: 1, x: 0, filter: 'grayscale(0)', delay: 0.2 },
            )
            .fromTo(
              item.querySelector('.panel .content'),
              { opacity: 0, x: 0 },
              { opacity: 1, x: 0 },
              '<',
            )
            .fromTo(
              item.querySelector('.panel .content h3'),
              { opacity: 0, x: 40 },
              { opacity: 1, x: 0 },
              '<',
            )
            .fromTo(
              item.querySelector('.panel .content h5'),
              { opacity: 0, x: 60 },
              { opacity: 1, x: 0 },
              '<',
            )
            .fromTo(
              item.querySelector('.progress-bar'),
              { height: '0%' },
              { height: '100%' },
              '<',
            )
            .addPause(0.5)
        })
      }, wrapper)
      return () => ctx.revert()
    }
  }, [])

  return (
    <section
      ref={wrapper}
      className="bg-blueShade our-expertise-wrapper overflow-hidden"
    >
      <div className="title-wrapper lg:h-[60vh] lg:pt-0 blade-top-padding-lg blade-bottom-padding-lg flex justify-center items-end">
        <h2 className="md:block hidden title text-center font-light px-10">
          <span className="title-init inline-flex"> O</span>
          <span className="title-init inline-flex"> u</span>
          <span className="title-init inline-flex"> r</span>{' '}
          <span className="title-init inline-flex"> e</span>
          <span className="title-init inline-flex"> x</span>
          <span className="title-init inline-flex"> p</span>
          <span className="title-init inline-flex"> e</span>
          <span className="title-init inline-flex"> r</span>
          <span className="title-init inline-flex"> t</span>
          <span className="title-init inline-flex"> i</span>
          <span className="title-init inline-flex"> s</span>
          <span className="title-init inline-flex"> e</span>{' '}
          <span className="title-init inline-flex"> k</span>
          <span className="title-init inline-flex"> n</span>
          <span className="title-init inline-flex"> o</span>
          <span className="title-init inline-flex"> w</span>
          <span className="title-init inline-flex"> s</span>{' '}
          <br className="lg:hidden" />
          <span className="title-after font-semibold inline-flex">n</span>
          <span className="title-after font-semibold inline-flex">o</span>{' '}
          <span className="title-after font-semibold inline-flex">b</span>
          <span className="title-after font-semibold inline-flex">o</span>
          <span className="title-after font-semibold inline-flex">u</span>
          <span className="title-after font-semibold inline-flex">n</span>
          <span className="title-after font-semibold inline-flex">d</span>
          <span className="title-after font-semibold inline-flex">r</span>
          <span className="title-after font-semibold inline-flex">i</span>
          <span className="title-after font-semibold inline-flex">e</span>
          <span className="title-after font-semibold inline-flex">s</span>
        </h2>
        <h2 className="sg-translate md:hidden block text-center">
          Our expertise knows <br />{' '}
          <span className="font-semibold">no boundries</span>
        </h2>
      </div>
      <div className="timeline-wrapper  w-container-xl">
        <div className="lg:h-20 h-10 w-[2px] z-1 bg-blue lg:mx-auto ml-4" />
        {data.map((item: any) => {
          return (
            <div key={item.id} className="relative panel-wrapper">
              <div
                className={`panel panel-${item.id} lg:pl-0 md:pl-14 pl-10 flex lg:flex-row flex-col 2xl:gap-40 lg:gap-24 gap-3 blade-top-padding-lg blade-bottom-padding-lg relative`}
              >
                <div className="img-wrapper flex-1 h-[270px] lg:h-[300px] xl:h-[320px] 2xl:h-[400px] max-w-[530px] xl:max-w-[600px] 2xl:max-w-[680px] overflow-hidden rounded-xl xl:rounded-2xl 2xl:rounded-[10px]">
                  <img
                    className="w-full h-full object-cover object-center"
                    src={item.image}
                    alt={item.title}
                  />
                </div>
                <div className="flex-1 content lg:max-w-[700px] max-w-xl ml-auto pr-5 2xl:pr-20">
                  <h3
                    className="sg-translate leading-tight"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <h5 className="sg-translate lg:mt-5 mt-3 leading-normal">
                    {item.paragraph}
                  </h5>
                </div>
              </div>
              <div className="stage flex-shrink h-full w-fit absolute top-0 lg:right-0 left-0 mx-auto ">
                <div className=" h-full w-[1px] z-1 bg-slate-200 absolute top-0 right-0 left-0 mx-auto">
                  <span className="progress-bar h-full w-[2px] z-2 bg-blue absolute top-0 right-0 left-0 mx-auto" />
                </div>
                <div className="bg-blueShade">
                  <div className="circle relative border border-blue rounded-full lg:p-1 p-[2px] bg-white z-20">
                    <h3 className="sg-translate 2xl:h-12 lg:h-10 h-7 2xl:w-12 lg:w-10 w-7 z-20 bg-blue text-white rounded-full  hidden md:flex justify-center items-center mx-auto">
                      {item.id}
                    </h3>
                    <h4 className="sg-translate md:hidden 2xl:h-12 lg:h-10 h-7 2xl:w-12 lg:w-10 w-7 z-20 bg-blue text-white rounded-full flex justify-center items-center mx-auto">
                      {item.id}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
