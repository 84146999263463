import React from 'react'
import './ourMission.css'
import teamMobile from 'assets/who-we-are/teamMobile.webp'

export default function OurMission() {
  return (
    <section className="bg-no-repeat bg-contain md:bg-cover bg-top md:bg-center md:bg-[url(assets/who-we-are/team.webp)]">
      <div className="md:hidden block h-52">
        <img
          className="w-full h-full object-cover object-center"
          src={teamMobile}
          alt="Supreme team planning"
        />
      </div>
      <div
        className="bg-gradient-blue-dark flex justify-end items-center 2xl:h-[750px] h-fit lg:min-h-[450px] blade-top-padding-xl 
      blade-bottom-padding-xl "
      >
        <div className="flex items-center h-full flex-1 text-white ml-[6%] lg:ml-[10%] xl:ml-[9.5%] 2xl:ml-[10.5%]">
          <div className="max-w-[20rem] xl:max-w-[22rem] 2xl:max-w-[28rem]">
            {/* <div className="md:pr-12 min-[900px]:pr-20 xl:pr-16 xl:max-w-xl max-w-xl 2xl:pr-4"> */}
            <div className="">
              <h3 className="sg-translate font-semibold">Our vision</h3>
              <h5 className="sg-translate mt-3 font-light">
                We can change the world into a paradise; <br /> all it takes is
                a few with faith and courage to seek this change.
              </h5>
            </div>
            <div className="lg:mt-14 mt-4">
              <h3 className="sg-translate font-semibold">Our mission</h3>
              <h5 className="sg-translate mt-3 font-light">
                Maintain a leadership position in the industry via personnel and
                product development through investments in research,
                infrastructure and technology, while striving to continuously
                adopt the best practices in social responsibility and
                environmental sustainability.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
