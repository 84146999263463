import React from 'react'
import locationIcon from 'assets/contact/location.svg'
import nonwoven_vigil from 'assets/contact/nonwoven/SNIPL-Vigil Mechanism-Version 1.04 wef 12.09.2023 (WORD Format).pdf'
import nonwoven_POSH_policy from 'assets/contact/nonwoven/SNIPL-POSH Policy Operational Manual for End User Version 1.05 dt 26.03.2021 (Update).pdf'
import nonwoven_code_of_conduct from 'assets/contact/nonwoven/SNIPL -Code of Conduct Version 1.03 wef 12.09.2023 (WORD Format).pdf'
import treon_vigil from 'assets/contact/treon/STPL-Vigil Mechanism-Version 1.04 wef 12.09.2023 (WORD Format).pdf'
import treon_POSH_policy from 'assets/contact/treon/STPL-POSH Policy Operational Manual for End User Version 1.05 dt 26.03.2021 (Update).pdf'
import treon_code_of_conduct from 'assets/contact/treon/STPL -Code of Conduct Version 1.03 wef 12.09.2023 (WORD Format).pdf'

export default function Offices() {
  return (
    <div className=" w-container-lg  2xl:w-container blade-top-padding-sm blade-bottom-padding-lg">
      <div className="blade-bottom-padding-sm">
        <center className="sg-translate text-2xl lg:text-2xl xlg:text-[1.75rem] 2xl:text-[2rem] md:leading-snug lg:leading-snug 2xl:leading-snug font-medium">
          Our offices
        </center>
        <div className="flex flex-wrap lg:flex-nowrap md:flex-row justify-center pt-5 md:pt-7 xl:pt-10 flex-col mx-auto gap-y-7 md:gap-4 xl:gap-5 2xl:gap-8">
          <Card
            title="Supreme Nonwoven Industries <br /> Private Limited"
            links={[
              { text: 'Vigil Mechanism', href: nonwoven_vigil },
              { text: 'POSH Policy', href: nonwoven_POSH_policy },
              { text: 'Code of Conduct', href: nonwoven_code_of_conduct },
            ]}
            cinNumber="U17120MH1984PTC033072"
          />
          <Card
            title="Supreme Treon <br /> Private Limited"
            links={[
              { text: 'Vigil Mechanism', href: treon_vigil },
              { text: 'POSH Policy', href: treon_POSH_policy },
              { text: 'Code of Conduct', href: treon_code_of_conduct },
            ]}
            cinNumber="U34200MH1996PTC103764"
          />
          <Card
            title="Supreme Nonwovens <br /> Private Limited"
            links={[]}
            cinNumber="U51900MH1981PTC025149"
          />
        </div>
      </div>
    </div>
  )
}

type ICard = {
  title: string
  links: Array<{ text: string; href: string }>
  cinNumber: string
}

function Card({ title, links, cinNumber }: ICard) {
  return (
    <article className="border-[#DADADA] flex flex-col border-1 border-solid rounded-lg overflow-hidden w-full mx-auto lg:ml-0 lg:mr-0  max-w-[25rem]">
      <div className=" overflow-hidden h-[15rem] w-full">
        <iframe
          title="location for supreme office"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471.3874652245262!2d72.9011763315628!3d19.059352660103063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8a0848f9843%3A0x37f45f3ec3dcf250!2sSupreme%20Group!5e0!3m2!1sen!2sin!4v1701156457218!5m2!1sen!2sin"
          width="450"
          height="240"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
      <div className="py-4 bg-white 2xl:py-6 px-3 flex flex-col  items-start flex-1 gap-[2px]">
        <span
          className="text-xl font-semibold block"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {/*  check font weight for this */}
        <span className="text-sm  font-semibold pt-2 md:pt-1  pb-1 md:pb-px">
          CIN: {cinNumber}
        </span>
        <div className="flex  flex-wrap  py-1 md:py-0 gap-x-1.5 items-center flex-1">
          {links.map((elem, index: number) => {
            const { text, href } = elem
            const key = `${index}`
            return (
              <>
                <a
                  key={key}
                  rel="noreferrer"
                  target="_blank"
                  href={href}
                  className="font-normal text-blueDark  text-sm hover:underline focus-visible:underline underline-offset-4 decoration-from-font outline-none focus-visible:outline-none"
                >
                  {text}
                </a>
                <div className="w-[2px] bg-opacity-60 bg-blueDark h-4 last-of-type:hidden" />
              </>
            )
          })}
        </div>
        <a
          href="https://www.google.com/maps/place/Supreme+Group/@19.0593527,72.9011763,20z/data=!4m6!3m5!1s0x3be7c8a0848f9843:0x37f45f3ec3dcf250!8m2!3d19.0592912!4d72.9012796!16s%2Fg%2F1w8w8g_m?entry=ttu"
          rel="noreferrer"
          target="_blank"
          className=" text-sm md:text-base  pt-3 xl:pt-4 2xl:pt-6 pb-1.5 flex items-center gap-[3px] font-medium  hover:underline focus-visible:underline underline-offset-4 decoration-from-font outline-none focus-visible:outline-none text-blueDark"
        >
          <img
            src={locationIcon}
            alt="location pin icon"
            className="h-5 xl:h-6 w-5 xl:w-6 object-contain"
            height={24}
            width={24}
            aria-hidden
          />
          <span className="sg-translate">View location</span>
        </a>
      </div>
    </article>
  )
}
