import React, { useRef, useEffect } from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import ring1 from 'assets/careers/circle1.svg'
// import ring2 from 'assets/careers/circle2.svg'
import brain from 'assets/careers/mind.png'

gsap.registerPlugin(ScrollTrigger)

export default function Subfooter() {
  const wrapper = useRef<any>()
  useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth > 768) {
      const selector = gsap.utils.selector(wrapper)
      const ctx = gsap.context(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            scrub: 3,
            pin: true,
            trigger: wrapper.current,
            start: 'top top',
            end: '3000px bottom',
            markers: false,
          },
        })
        tl.to(selector('.title'), {
          opacity: 0,
          translateY: '-50%',
          delay: 0.1,
        })
        tl.to(
          selector('.scroll-down'),
          {
            opacity: 0,
            delay: 0.1,
          },
          0,
        )
        tl.to(
          selector('.ring1'),
          {
            rotateZ: 10,
          },
          '<',
        )
        tl.to(
          selector('.ring2'),
          {
            rotateZ: -10,
          },
          '<',
        )
        tl.to(selector('.subtitle'), {
          opacity: 1,
          translateY: '0',
        })

        const pointers = selector('.pointers-events .pointer-event')
        const pointersChild = selector('.pointers-events .pointer-event div')

        pointers.forEach((elem, index: number) => {
          tl.fromTo(
            elem,
            {
              opacity: -1,
              width: 0,
              transform: `rotateZ(${(320 / 7) * index}deg)`,
            },
            {
              opacity: 3,
              width: '100%',
              transform: `rotateZ(${(360 / 7) * index}deg)`,
            },
            0.7,
          )
        })
        pointersChild.forEach((elem, index: number) => {
          tl.fromTo(
            elem,
            {
              transform: `rotateZ(0)`,
            },
            {
              transform: `rotateZ(${(-360 / 7) * index}deg)`,
            },
            '<',
          )
        })
        tl.to(
          selector('.ring1'),
          {
            opacity: 0.2,
            rotateZ: 20,
          },
          '<',
        )
        tl.to(
          selector('.ring2'),
          {
            rotateZ: -20,
          },
          '>',
        )

        pointersChild.forEach((elem, index: number) => {
          tl.fromTo(
            elem,
            {
              background: '#006ABC',
              color: '#fff',
              borderColor: '#006ABC',
            },
            {
              color: '#000',
              borderColor: '#00BFFF',
              background: '#fff',
            },
            '<',
          )
        })

        tl.to(
          selector('.ring1'),
          {
            rotateZ: 10,
          },
          '>',
        )
        tl.to(
          selector('.ring2'),
          {
            rotateZ: -10,
          },
          '<',
        )

        pointers.forEach((elem, index: number) => {
          tl.fromTo(
            elem,
            {
              opacity: 1,
              width: '100%',
            },
            {
              opacity: -1,
              width: '150%',
            },
            '<',
          )
        })

        tl.to(selector('.subtitle'), {
          opacity: 0,
          translateY: '-80%',
        })

        tl.to(
          selector('.cta'),
          {
            zIndex: 999,
            opacity: 1,
            translateY: '0',
          },
          '>',
        )
        // .then(() => {
        //   selector('.linkedin-anchor-wrapper')[0].classList.remove(
        //     'pointer-events-none',
        //   )
        // })
      }, wrapper)
      return () => ctx.revert()
    }
  }, [])

  return (
    <div className="overflow-hidden ">
      <div
        ref={wrapper}
        className="min-h-screen grid place-content-center place-items-center overflow-hidden gap-2 bg-[#EBF6FF] relative"
      >
        <img
          src={brain}
          alt="ring wrapper"
          aria-hidden
          className="  h-[16rem] 2xl:h-[20rem] w-auto object-contain object-center absolute inset-0 m-auto"
        />
        <img
          src={ring1}
          alt="ring wrapper"
          aria-hidden
          className=" opacity-50 ring1  h-[36rem] 2xl:h-[42rem] w-auto object-contain object-center absolute inset-0 m-auto"
        />
        <img
          src={ring1}
          alt="ring wrapper"
          aria-hidden
          className="ring2  h-[30rem] 2xl:h-[33rem] w-auto object-contain object-center absolute inset-0 m-auto"
        />

        <div className="relative h-64 w-80 2xl:h-72 2xl:w-96 pointers-events ">
          <div className="w-full  h-[8rem] 2xl:h-[9rem] absolute left-1/2 right-auto top-0 bottom-0 flex justify-end my-auto origin-left pointer-event opacity-0 overflow-hidden">
            <div className="bg-blueDark text-white border-1 border-blueDark border-solid font-semibold text-sm 2xl:text-base text-center leading-tight grid place-content-center aspect-square h-full rounded-full">
              <span className="sg-translate mx-auto block">
                AI-enabled production
              </span>
            </div>
          </div>
          <div className="w-full  h-[8rem] 2xl:h-[9rem] absolute left-1/2 right-auto top-0 bottom-0 flex justify-end my-auto origin-left pointer-event opacity-0 overflow-hidden">
            <div className="bg-blueDark text-white border-1 border-blueDark border-solid font-semibold text-sm 2xl:text-base text-center leading-tight grid place-content-center aspect-square h-full rounded-full">
              <span className="sg-translate mx-auto block">
                Manufacturing Mechanization
              </span>
            </div>
          </div>
          <div className="w-full  h-[8rem] 2xl:h-[9rem] absolute left-1/2 right-auto top-0 bottom-0 flex justify-end my-auto origin-left pointer-event opacity-0 overflow-hidden">
            <div className="bg-blueDark text-white border-1 border-blueDark border-solid font-semibold text-sm 2xl:text-base text-center leading-tight grid place-content-center aspect-square h-full rounded-full">
              <span className="sg-translate mx-auto block">
                Automation Ecosystem
              </span>
            </div>
          </div>
          <div className="w-full  h-[8rem] 2xl:h-[9rem] absolute left-1/2 right-auto top-0 bottom-0 flex justify-end my-auto origin-left pointer-event opacity-0 overflow-hidden">
            <div className="bg-blueDark text-white border-1 border-blueDark border-solid font-semibold text-sm 2xl:text-base text-center leading-tight grid place-content-center aspect-square h-full rounded-full">
              <span className="sg-translate mx-auto block">Digitalization</span>
            </div>
          </div>
          <div className="w-full  h-[8rem] 2xl:h-[9rem] absolute left-1/2 right-auto top-0 bottom-0 flex justify-end my-auto origin-left pointer-event opacity-0 overflow-hidden">
            <div className="bg-blueDark text-white border-1 border-blueDark border-solid font-semibold text-sm 2xl:text-base text-center leading-tight grid place-content-center aspect-square h-full rounded-full">
              <span className="sg-translate mx-auto block">Industry 4.0</span>
            </div>
          </div>
          <div className="w-full  h-[8rem] 2xl:h-[9rem] absolute left-1/2 right-auto top-0 bottom-0 flex justify-end my-auto origin-left pointer-event opacity-0 overflow-hidden">
            <div className="bg-blueDark text-white border-1 border-blueDark border-solid font-semibold text-sm 2xl:text-base text-center leading-tight grid place-content-center aspect-square h-full rounded-full">
              <span className="sg-translate mx-auto block">Sustainability</span>
            </div>
          </div>
          <div className="w-full  h-[8rem] 2xl:h-[9rem] absolute left-1/2 right-auto top-0 bottom-0 flex justify-end my-auto origin-left pointer-event opacity-0 overflow-hidden">
            <div className="bg-blueDark text-white border-1 border-blueDark border-solid font-semibold text-sm 2xl:text-base text-center leading-tight grid place-content-center aspect-square h-full rounded-full">
              <span className="sg-translate mx-auto block">
                Material Innovation
              </span>
            </div>
          </div>

          <div className="absolute inset-0 grid place-content-center ">
            <h2 className="sg-translate font-semibold title text-black text-center leading-snug">
              We welcome <br />
              <span className="text-blue"> unique minds </span>
              <br />
              like yours
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="scroll-down opacity-60 h-10 absolute -bottom-10 left-1/2 -translate-x-1/2"
              viewBox="0 0 15 15"
            >
              <path
                fill="#00BFFF"
                d="M4.854 6.146L4.5 5.793l-.707.707l.353.354zM7.5 9.5l-.354.354l.354.353l.354-.353zm3.354-2.646l.353-.354l-.707-.707l-.354.353zm-6.708 0l3 3l.708-.708l-3-3zm3.708 3l3-3l-.708-.708l-3 3zM14 7.5A6.5 6.5 0 0 1 7.5 14v1A7.5 7.5 0 0 0 15 7.5zM7.5 1A6.5 6.5 0 0 1 14 7.5h1A7.5 7.5 0 0 0 7.5 0zM1 7.5A6.5 6.5 0 0 1 7.5 1V0A7.5 7.5 0 0 0 0 7.5zm-1 0A7.5 7.5 0 0 0 7.5 15v-1A6.5 6.5 0 0 1 1 7.5z"
              />
            </svg>
          </div>
          <div className="absolute inset-0 grid place-content-center ">
            <div className=" subtitle translate-y-[120%]  opacity-0">
              <span className="sg-translate block leading-snug  text-lg 2xl:text-xl w-11/12 mx-auto text-center max-w-sm font-medium text-black">
                Diversity of perspectives breeds fresh ideas. Be yourself,
                showcase what sets you apart, and join us in our pursuit of
                innovation
              </span>
            </div>
          </div>
          <div className="absolute inset-0 linkedin-anchor-wrapper place-content-center">
            <div className="cta translate-y-[120%] grid place-content-center place-items-center gap-4 opacity-0">
              <span className="sg-translate text-black font-semibold block mx-auto text-center text-xl xl:text-2xl">
                Curious to learn more <br /> about life at Supreme?
              </span>
              <a
                href="https://www.linkedin.com/company/supreme-group-company/"
                target="_blank"
                rel="noreferrer noopener"
                className="rounded-full border-2 border-solid border-[#00AEEF] py-2.5 bg-white px-7 flex items-center gap-3 justify-center font-semibold text-lg"
              >
                <span className="sg-translate">Let&apos;s talk</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.0943 0.689941H1.95789C1.32955 0.689941 0.795898 1.18689 0.795898 1.79908V14.995C0.795898 15.6079 1.14604 16.1049 1.77438 16.1049H14.9108C15.5399 16.1049 16.2108 15.6079 16.2108 14.995V1.79908C16.2108 1.18689 15.7234 0.689941 15.0943 0.689941ZM6.66825 6.5623H8.7434V7.62005H8.76615C9.08253 7.0497 10.017 6.47054 11.1723 6.47054C13.3899 6.47054 14.0087 7.64795 14.0087 9.82879V13.9027H11.8066V10.2303C11.8066 9.25404 11.4168 8.39741 10.5051 8.39741C9.39816 8.39741 8.87039 9.14686 8.87039 10.3771V13.9027H6.66825V6.5623ZM2.99803 13.9027H5.20016V6.5623H2.99803V13.9027ZM5.47543 3.99314C5.47543 4.75361 4.85957 5.36947 4.0991 5.36947C3.33863 5.36947 2.72276 4.75361 2.72276 3.99314C2.72276 3.23267 3.33863 2.61681 4.0991 2.61681C4.85957 2.61681 5.47543 3.23267 5.47543 3.99314Z"
                    fill="#00AEEF"
                  />
                </svg>
              </a>
              <div className=" text-black text-base 2xl:text-lg relative font-normal block leading-snug text-center pt-1">
                <span className="sg-translate">
                  Want to explore opportunities? <br /> Write to us at{' '}
                </span>
                <a
                  href="mailto:hr@supremegroup.co.in"
                  className="font-semibold"
                >
                  <span className="sg-translate">hr@supremegroup.co.in</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
