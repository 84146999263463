import React, { useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css/navigation'

import process from 'assets/apparel/whyChooseUs/01.webp'
import Unmatched from 'assets/apparel/whyChooseUs/02.webp'
import Delivery from 'assets/apparel/whyChooseUs/03.webp'
import Support from 'assets/apparel/whyChooseUs/04.webp'

import MobileCards from 'molecules/mobileCards'

gsap.registerPlugin(ScrollTrigger, { debug: true })

export default function WhyChooseUs() {
  const cmpRef = useRef<any>(null)
  const timelineRef = useRef<gsap.core.Timeline | null>(null)

  useLayoutEffect(() => {
    if (window.innerWidth >= 768) {
      const gsapCtx = gsap.context(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.solutions-wrapper',
            start: 'top top',
            end: 'bottom -200%',
            snap: 'labelsDirectional',
            markers: false,
            scrub: 1.5,
            pin: true,
          },
        })
        timelineRef.current = tl

        tl.addPause(1)
          .add('l1')
          // Apparel
          .to(
            '.solutions-wrapper .tab-01 .dots',
            {
              width: 64,
            },
            'l1',
          )
          .to(
            '.solutions-wrapper .tab-01',
            { color: 'white', opacity: 1 },
            'l1',
          )
          .to('.solutions-wrapper .tab-02', { opacity: 1 }, 'l1')
          .to(
            '.solutions-wrapper .tab-02 .dots',
            {
              opacity: 1,
            },
            'l1',
          )
          .to('.slide-01 .content', { opacity: 1 }, 'l1')
          .to('.slide-01', { opacity: 0 }, 'l1')
          .to('.slide-02', { opacity: 1 }, 'l1')
          .fromTo(
            '.slide-02 .content',
            { opacity: 0 },
            { opacity: 1, zIndex: '20', delay: 0.2 },
            'l1',
          )

        // Automotive
        tl.add('l2')
          .to('.solutions-wrapper .tab-02', { color: '#fff' }, 'l2')
          .to(
            '.solutions-wrapper .tab-02 .dots',
            {
              width: 64,
            },
            'l2',
          )
          .to('.solutions-wrapper .tab-03', { opacity: 1 }, 'l2')
          .to('.solutions-wrapper .tab-03 .dots', { opacity: 1 }, 'l2')
          .to('.slide-02 .content', { opacity: 1 }, 'l2')
          .to('.slide-02', { opacity: 0 }, 'l2')
          .to('.slide-03', { opacity: 1 }, 'l2')
          .fromTo(
            '.slide-03 .content',
            { opacity: 0 },
            { opacity: 1, zIndex: '20', delay: 0.2 },
            'l2',
          )
        // Filtration
        tl.add('l3')
          .to('.solutions-wrapper .tab-03', { color: '#fff' }, 'l3')
          .to(
            '.solutions-wrapper .tab-03 .dots',
            {
              width: 64,
            },
            'l3',
          )
          .to('.solutions-wrapper .tab-04', { opacity: 1 }, 'l3')
          .to('.solutions-wrapper .tab-04 .dots', { opacity: 1 }, 'l3')
          .to('.slide-03', { opacity: 0 }, 'l3')
          .to('.slide-03 .content', { opacity: 1 }, 'l3')

          .to('.slide-04', { opacity: 1 }, 'l3')
          .fromTo(
            '.slide-04 .content',
            { opacity: 0 },
            { opacity: 1, zIndex: '20', delay: 0.2 },
            'l3',
          )
        // Cutomised Solutions
        tl.add('l4')
          .to('.solutions-wrapper .tab-04', { opacity: 1 }, 'l4')
          .to(
            '.solutions-wrapper .tab-04 .dots',
            { width: 64, opacity: 1 },
            'l4',
          )
      })

      return () => gsapCtx.revert()
    }
  }, [])

  const data = [
    {
      id: 1,
      title: 'Streamlined processes',
      points: [
        'In-depth recommendation reports',
        'Rigorously monitored supply chain',
        'Dedicated factory floor support',
      ],
      image: process,
    },
    {
      id: 2,
      title: 'Unmatched products',
      points: [
        'State-of-the-art manufacturing facilities',
        'Experienced R&D team',
        'Comprehensive product range',
      ],
      image: Unmatched,
    },
    {
      id: 3,
      title: 'Streamlined delivery ',
      points: [
        'Efficient production planning',
        'Ready inventories for immediate dispatch',
        'Quick response to customer needs',
      ],
      image: Delivery,
    },
    {
      id: 4,
      title: 'Expert support',
      points: ['Technical seminars', 'Production insights', 'On-site training'],
      image: Support,
    },
  ]

  function navigateToLabel(label: string) {
    if (timelineRef.current) {
      timelineRef.current.seek(label)
    }
    console.log('clicked')
  }

  return (
    <section>
      <div ref={cmpRef} className="relative md:block hidden">
        <section className="solutions-wrapper h-screen bg-black relative overflow-hidden">
          {data.map((item, i) => {
            return (
              <div
                className={`slide-0${item.id} ${
                  i === 0 ? 'opacity-100' : 'opacity-0'
                }  h-screen absolute inset-0`}
                key={item.id}
              >
                <div className="absolute inset-0 ">
                  <img
                    className="h-full w-full object-cover"
                    src={item.image}
                    alt=""
                    aria-hidden
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50" />
                </div>

                <section className="relative h-full">
                  <div className="h-full w-container blade-bottom-padding-lg blade-top-padding-xl flex flex-col justify-between ">
                    <h2 className="sg-translate font-light text-white">
                      Why <span className="font-semibold">top brands</span>{' '}
                      choose us
                    </h2>
                    <div
                      className={`content z-10 content-${item.id} 2xl:mb-14 mb-10`}
                    >
                      <h3 className="sg-translate text-white font-semibold mt-3 2xl:mb-5 mb-3">
                        {item.title}
                      </h3>
                      <ul>
                        {item.points.map((point) => {
                          return (
                            <h4
                              key={item.id}
                              className="text-white font-normal mt-2 2xl:max-w-lg max-w-sm list-inside list-disc"
                            >
                              <li className="sg-translate">{point}</li>
                            </h4>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            )
          })}
          {/*  NOTE:  Uncomment this to revertback client changes and delete other client changes below it
          <div className="w-container mx-auto h-screen absolute left-0 right-0 bottom-0 flex gap-4 items-end blade-bottom-padding-lg">
            {data.map((ele, tabIndex) => {
              return (
                <button
                  key={ele.id}
                  type="button"
                  className={`${
                    tabIndex === 0 ? 'opacity-100' : ' opacity-50'
                  } tab tab-0${
                    ele.id
                  } text-white flex items-center gap-4 leading-none font-medium cursor-default`}
                >
                  <span className="sg-translate">0{ele.id}</span>
                  <span className="dot bg-white bg-opacity-20 h-[2px] w-16 inline-block relative">
                    <span className="bg-white dots w-0 inline-block absolute inset-0 h-[2px]" />
                  </span>
                </button>
              )
            })}
          </div> */}

          {/* Client change */}
          <div className="w-fit h-fit 2xl:top-[40%] top-1/2 -right-20 xl:right-0 -translate-y-1/2 mx-auto absolute flex rotate-90 z-[999]">
            {data.map((ele, tabIndex) => {
              const lbl = `l${ele.id}`
              return (
                <button
                  key={ele.id}
                  type="button"
                  onClick={() => navigateToLabel(lbl)}
                  className={`${
                    tabIndex === 0 ? 'opacity-100' : ' opacity-50'
                  } tab tab-0${
                    ele.id
                  } text-white text-xl 2xl:text-2xl flex items-center leading-none font-medium cursor-pointer `}
                >
                  <span className="sg-translate w-10 h-10 2xl:w-12 2xl:h-12 flex justify-center items-center -rotate-90 bg-white rounded-full text-black">
                    0{ele.id}
                  </span>
                  {ele.id === 4 ? (
                    ''
                  ) : (
                    <span className="dot bg-white bg-opacity-20 h-[2px] w-16 inline-block relative">
                      <span className="bg-white dots w-0 inline-block absolute inset-0 h-[2px]" />
                    </span>
                  )}
                </button>
              )
            })}
          </div>
          {/* Client change end */}
        </section>
      </div>

      {/* Mobile view */}
      <div className="md:hidden block bg-blueShade blade-top-padding blade-bottom-padding">
        <h2 className="sg-translate title font-light text-center mb-4">
          Why <span className="font-semibold">top brands</span>
          <br /> choose us
        </h2>
        <Swiper
          centeredSlides
          slidesPerView={1.1}
          slidesPerGroup={1}
          loop
          pagination
          breakpoints={{
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            1024: {
              centeredSlides: true,
              slidesPerView: 2.1,
            },
            1280: {
              slidesPerGroup: 2,
              centeredSlides: true,
              slidesPerView: 3.5,
            },
            1440: {
              centeredSlides: true,
              slidesPerView: 3.8,
            },
          }}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={1500}
          modules={[Pagination]}
          spaceBetween={10}
          className="pb-8"
        >
          {data.map((item, i) => {
            return (
              <SwiperSlide key={item.id} className="h-full mb-3 mt-2">
                <MobileCards
                  image={item.image}
                  title={item.title}
                  list={item.points}
                  id={item.id}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </section>
  )
}
