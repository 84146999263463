import React from 'react'

export function About() {
  return (
    <div className="bg-white px-4  gap-y-8 md:gap-y-12 flex flex-col items-center">
      <span className="w-[1px] h-[100px] md:h-[150px] lg:h-[200px] bg-green" />

      <h3 className="sg-translate font-normal hidden md:block text-black max-w-3xl lg:max-w-4xl 2xl:max-w-6xl text-center leading-normal">
        At Supreme Nonwovens, we intertwine our corporate strategy with{' '}
        <span className="font-semibold">
          Environmental, Social, and Governance (ESG){' '}
        </span>{' '}
        principles, committing ourselves to sustainable development{' '}
        <span className="">
          {' '}
          that transcends economic success. Our journey towards integrating ESG
          into the heart of our business reflects a holistic approach to
          innovation, responsibility, and community welfare, marking our
          commitment to building a sustainable future together.
        </span>
      </h3>

      <h4 className="sg-translate font-normal text-lg md:hidden text-black max-w-3xl lg:max-w-4xl 2xl:max-w-6xl text-center leading-normal">
        At Supreme Nonwovens, we intertwine our corporate strategy with{' '}
        <span className="font-semibold">
          Environmental, Social, and Governance (ESG){' '}
        </span>{' '}
        principles, committing ourselves to sustainable development{' '}
        <span className="">
          {' '}
          that transcends economic success. Our journey towards integrating ESG
          into the heart of our business reflects a holistic approach to
          innovation, responsibility, and community welfare, marking our
          commitment to building a sustainable future together.
        </span>
      </h4>

      <span className="w-[1px] h-[100px] md:h-[150px] lg:h-[200px] bg-green" />
    </div>
  )
}
