import React, { useState, useRef, useMemo, useEffect } from 'react'

import { A11y, Controller, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
// import './stylesOrganism.css'
import { SliderBtn } from 'screens/filtration/applicationExpertise'

type Props = {
  data: { coverImage: string; title: string; description: string }[]
  height?: string
}

type SetActiveSlideIndex = (prev: number) => void

export default function OurJourneyMobile({ data }: { data: any }) {
  const [current, setCurrent] = useState(0)

  const swiperRef = useRef<any>()
  const swiperButtonRef = useRef<any>()

  const [swiperCardInstance, setSwiperCardInstance] = useState<any>(null)
  const [swiperButtonInstance, setSwiperButtonInstance] = useState<any>(null)

  useEffect(() => {
    if (swiperButtonRef?.current && swiperRef?.current) {
      setSwiperButtonInstance(swiperButtonRef.current.swiper)
      setSwiperCardInstance(swiperRef.current.swiper)
    }
  }, [current])
  // useEffect(() => {
  //   swiperRef.current?.swiper.slideTo(activeSlideIndex)
  // }, [activeSlideIndex])

  const slideDesc = useRef<any>()

  const lastSlide = useMemo(() => data.length - 1, [data])

  return (
    <>
      <div className="blade-top-padding-lg">
        <h2 className="text-center leading-tight font-normal">Our Journey</h2>

        <div className="relative blade-top-padding-sm">
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Controller, A11y]}
            navigation={{
              prevEl: '.possibility-prev-btn',
              nextEl: '.possibility-next-btn',
            }}
            onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
            onSwiper={swiperCardInstance}
            controller={{
              control: swiperButtonInstance,
            }}
            slidesPerView={1.05}
            centeredSlides
            spaceBetween={10}
            loop={false}
            speed={1000}
            className="bg-white cursor-grab overflow-hidden pb-2"
            breakpoints={{
              768: {
                spaceBetween: 20,
                slidesPerView: 1.1,
              },
              1024: {
                spaceBetween: 20,
                slidesPerView: 1.05,
              },
              1280: {
                spaceBetween: 80,
                slidesPerView: 1.1,
              },
              1366: {
                spaceBetween: 80,
                slidesPerView: 1.2,
              },
              1536: {
                spaceBetween: 100,
                slidesPerView: 1.2,
              },
            }}
          >
            {data.map((elem: any, index: number) => {
              const key = `${index}`
              return (
                <SwiperSlide
                  key={key}
                  className="overflow-hidden h-auto shadow-sm shadow-slate-500"
                >
                  <div className="w-full inset-0 z-0 relative h-48 overflow-hidden">
                    <img
                      src={elem.coverImage}
                      alt={elem.title}
                      className="h-full w-full object-cover object-top grayscale"
                    />
                    <div className="absolute inset-0 bg-gradient-to-b from-[#00000050] to-blueDark" />
                  </div>
                  <div className="relative mt-1 h-full w-full p-3 flex flex-col">
                    <h4 className="text-dark font-medium text-42 w-fit leading-none">
                      {elem.timeRange[0]} to{' '}
                      {/* <span className="text-xl opacity-90">to</span>{' '} */}
                      {elem.timeRange[1]}
                    </h4>
                    <h5 className="text-dark text-xl mt-1 leading-tight font-medium">
                      {elem.slideTitle}
                    </h5>
                    <h5 className="text-dark text-base">
                      {elem.slideSubtitle}
                    </h5>
                    <h5 className="text-dark mt-1 font-medium">
                      {elem.slideDescription}: {elem.slideDescription1}
                    </h5>
                    {/* <h5 className="text-dark">{elem.slideDescription1}</h5> */}
                    <ul className="list-outside list-disc ml-4 mt-1">
                      {elem.list.map((listItem: string, ind: number) => {
                        const keyVal = `${ind}-${listItem}`
                        return (
                          <li
                            className="text-dark list-disc leading-none"
                            key={keyVal}
                          >
                            <h5 className="text-sm">{listItem}</h5>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className="timeline w-full">
          {/* Timeline progress bar carousel */}
          <div className="relative pt-1">
            <Swiper
              centeredSlides
              initialSlide={0}
              slidesPerView={2}
              modules={[Navigation, Controller]}
              onSlideChange={(swiper) => setCurrent(swiper.realIndex)}
              speed={500}
              ref={swiperButtonRef}
              onSwiper={swiperButtonInstance}
              controller={{
                control: swiperCardInstance,
              }}
              slideToClickedSlide
              allowTouchMove
              slideActiveClass="font-medium"
              className="divide-x-4 swiper-image-mask px-3 select-none cursor-grab mx-auto  text-white "
            >
              {data.map((item: any, i: number) => (
                <SwiperSlide key={item.id}>
                  <div key={item.id}>
                    <div className="flex justify-start">
                      <div
                        className={`centimeter w-[1px] h-2 bg-black rounded-full transition-all duration-300 ease-in-out ${
                          i === 0 ? 'block' : 'hidden'
                        }`}
                      />
                      <div className="scale flex justify-between w-full h-[1px] bg-black bg-opacity-80">
                        {data.map((millimeter: any, ind: number) => {
                          return (
                            <div
                              key={millimeter.id}
                              className="millimeter w-[1px] h-1 last-of-type:opacity-0 first-of-type:opacity-0 bg-black bg-opacity-30 rounded-full transition-all duration-300 ease-in-out"
                            />
                          )
                        })}
                      </div>

                      <div
                        className={`w-[1px] h-2 bg-black rounded-full transition-all duration-300 ease-in-out ${
                          i === 4 && 'opacity-0'
                        }`}
                      />
                    </div>

                    <button
                      type="button"
                      className={`${
                        item.id === current ? ' text-blueDark' : ' text-dark'
                      } leading-none font-semibold py-2 transition-all cursor-pointer text-left w-full p-0`}
                    >
                      <h4 className="text-base">
                        {item.timeRange[0]}{' '}
                        <span className="text-sm text-dark opacity-80">
                          to {item.timeRange[1]}
                        </span>
                      </h4>
                    </button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div className="flex md:hidden z-10 justify-end w-container-sm gap-3 blade-bottom-padding-xl">
        <SliderBtn
          disable={current === 0}
          direction="left"
          slideClass="possibility-prev-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M21 11.5H6.414L11.707 6.207L10.293 4.793L2.586 12.5L10.293 20.207L11.707 18.793L6.414 13.5H21V11.5Z"
              fill="white"
            />
          </svg>
        </SliderBtn>
        <SliderBtn
          disable={current === lastSlide}
          direction="right"
          slideClass="possibility-next-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M3 11.5H17.586L12.293 6.207L13.707 4.793L21.414 12.5L13.707 20.207L12.293 18.793L17.586 13.5H3V11.5Z"
              fill="white"
            />
          </svg>
        </SliderBtn>
      </div>
    </>
  )
}
